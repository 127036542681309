@import '../partials/variables';
@import '../partials/mixins';

.pdp {
  .cart-product-edit {
    padding: 2.75rem 0;

    &__wrapper {
      padding: 0 1.5rem;
    }

    &__update {
      grid-column: span 2;
    }

    .product-title {
      padding-bottom: 1rem;
    }

    .price-wrapper {
      @include gta-burrow;
      @include body-large;

      padding-top: 1rem;
    }

    .pdp-images-container {
      .pdp-image-position {
        .badge-container {
          top: 1rem;
          left: 1rem;
        }
      }
    }
  }

  &-details-info {
    min-height: 2rem;
  }
}
