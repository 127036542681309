.home {
  text-align: center;
}
.landing, .sitemap {
  max-width: 60rem;
  position: relative;
  margin: auto;
  padding-bottom: 3rem;
  width: 90%;
  color: $charcoal;
  ul {
    li {
      list-style-type: disc;
      margin-left: 3rem;
      line-height: 1.5rem;
    }
  }
  p {
    margin: 1rem 0;
  }
  a {
    text-decoration: underline;
  }
}

.rich-text {
  h1, h2, h3, h4, h5 {
    line-height: 2;
  }

  h1 {
    @include h1;
  }

  h2 {
    @include h2;
  }

  h3 {
    @include h3;
  }

  h4 {
    @include h4;
  }

  h5 {
    @include h5;
  }

  ul {
    padding: 1rem 0;
  }

  em {
    font-style: italic;
  }
}

.sitemap {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto;
  grid-column-gap: 3%;
  grid-row-gap: 1rem;
  @include respond($break-phone) {
    grid-template-columns: 30% 30% 30%;
  }
  &-group {
    grid-column: span 1;
    grid-row: auto;
    ul {
      li {
        list-style: none;
        margin-left: 0;
        cursor: pointer;
        &:hover {
          font-weight: 600;
        }
      }
    }
    a {
      text-decoration: none;;
    }
  }
}
.referral-page {
  line-height: 0;
}
.popup {
  background: hsla(0,0%,86.3%,.6);
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  &-message {
    max-width: 37.5rem;
    width: 75%;
    background: $sandstone50;
    padding: 1rem 2rem;
    h2 {
      margin-top: 2rem;
    }
    button {
      max-width: 10rem;
    }
    .message {
      padding: 2rem 0;
      &:not(:first-of-type) {
        padding-top: 0;
      }
    }
  }
}
.notFound {
  height: 90vh;
  display: flex;
  flex-direction: column;
  width: 75%;
  margin: auto;
  max-width: 45rem;
  align-items: center;
  justify-content: center;
  text-align: center;
  h2 {
    font-size: 4.5rem;
    line-height: 3.25rem;
  }
  p {
    letter-spacing: -0.3px;
     margin-top: 1.375rem;
  }
  a {
    text-decoration: underline;
  }
  @keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
  }
}
.error-container {
  text-align: center;
  max-width: $full-width;
  grid-column: 2/-2;
  padding: 2rem 0;
}

.offline {
  height: 90vh;
  display: flex;
  flex-direction: column;
  width: 75%;
  margin: auto;
  max-width: 45rem;
  align-items: center;
  justify-content: flex-start;
  text-align: center;

  &__message {
    letter-spacing: -0.3px;
    padding-top: 15vh;
  }
}

.fullbleed-wrapper {
  margin: auto;
  @include respond($break-gigantic) {
    @include transition(all .25s);
    max-width: unset;
    width: 100%;
    padding: 5rem 0;
  }
}
