@import './partials/variables';
@import './partials/mixins';
@import './partials/reset';

@font-face {
  font-family: "GT America Burrow";
  src:
    url("../fonts/gt-america-burrow/GT-America-Burrow.woff2") format("woff2"),
    url("../fonts/gt-america-burrow/GT-America-Burrow.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Neuzeit S LT W01 Book";
  src:
    url("../fonts/burrow/d741af6b-26f4-4e34-b55d-432ccade3280.woff2") format("woff2"),
    url("../fonts/burrow/77aaeb7b-86f4-47a1-8196-74a660dd0919.woff") format("woff"),
    url("../fonts/burrow/be8d3917-a4cf-4d8f-b767-4e84bcbe300c.svg?xlsj2l#be8d3917-a4cf-4d8f-b767-4e84bcbe300c") format("svg");
  font-display: swap;
}

@font-face {
  font-family: "NeuzeitSLTW01-BookHeavy";
  font-display: swap;
  src:
    url("../fonts/burrow/3da0a3e3-3dff-405c-a0b2-0b0050b21771.woff2") format("woff2"),
    url("../fonts/burrow/a1476bae-b118-4e9d-b711-c84091d9bc16.woff") format("woff"),
    url("../fonts/burrow/74a8e701-bffc-464e-a60b-63169debd492.svg?xlsj2l#74a8e701-bffc-464e-a60b-63169debd492") format("svg");
}

@font-face {
  font-family: 'ui-icons';
  src:
    url("../fonts/ui-icons/ui-icons.woff?lelqna") format('woff'),
    url("../fonts/ui-icons/ui-icons.svg?lelqna#ui-icons") format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@import 'partials/buttons';
@import 'partials/theme-classes';
@import 'partials/icons';

// TYPOGRAPHY
body,
html {
  font-size: 1rem;
  color: $charcoal;
  text-align: left;
  scroll-behavior: smooth !important;

  @include book;
}

h1,
.h1 {
  @include h1;
}

h2,
.h2 {
  @include h2;
}

h3,
.h3 {
  @include h3;
}

h4,
.h4 {
  @include h4;
}

h5,
.h5 {
  @include h5;
}

li {
  list-style: none;

  @include p;
}

p {
  @include p;
}

input {
  @include book;
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
.react-select__single-value,
.react-select__input,
textarea {
  font-size: 1rem;
}

label,
.body-large,
.react-select__placeholder {
  @include body-large;
}

.small-text,
.body-medium {
  @include body-medium;
}

.tiny-text,
.body-small {
  @include body-small;
}

.body-extra-large {
  @include body-extra-large;
}

a {
  text-decoration: none;
  color: $charcoal75;
  &:hover {
    cursor: pointer;
  }
}

strong,
.heavy {
  @include heavy;
}

.small-caps {
  @include small-uppercase-header;
}

.tiny-caps {
  @include tiny-caps;
}

[id*="fbt-overlay-"] {
  background-color: rgba(178, 178, 178, 0.8) !important;
}

// FIXME: replace forms styles
@import 'legacy/forms';
