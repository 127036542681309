@import '../partials/variables';
@import '../partials/mixins';

.order-lookup {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $sandstone;

  .auth {
    @include respond-below($break-phone) {
      min-height: unset;
      padding-bottom: 3.75rem;
    }
  }

  &__form {
    @include respond-below($break-phone) {
      min-height: unset;
    }
  }

  &__header {
    margin-bottom: 0.25rem;
  }

  &__subheader {
    margin-bottom: 1.5rem;
  }

  &__button {
    height: 3.125rem;

    @include respond-below($break-phone) {
      height: 2.5rem;
    }

    padding: 0;
  }

  .form-fancy-wrapper {
    margin: 0;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    .form-group {
      margin: 0;
    }

    $inputSelectors:
      "input[type=\"text\"]",
      "input[type=\"email\"]",
      "input[type=\"tel\"]",
      "input[type=\"password\"]",
      "input[type=\"number\"]",
      "input[type=\"date\"]";
  }
}

.order {
  grid-gap: 0;
  width: 100vw;
  align-items: center;
  justify-content: center;

  &-hero {
    @include flex-column;

    justify-content: center;
    min-height: 40vh;
    grid-column: col-start 1 / span 16;
    grid-row: 1;
    text-align: center;
    padding: 2rem;

    @include respond($break-large) {
      max-height: 29.375rem;
      grid-column: col-start 5 / span 8;
    }

    &__icon {
      font-size: 5rem;
      color: $charcoal;
    }

    &__header {
      @include h2;

      margin-top: 2rem;
      line-height: 1.2;
    }

    &__subheader {
      margin: 1rem auto 0;
      max-width: 37.5rem;
    }

    &__button {
      margin: 1.75rem auto 0;
      width: auto;
    }
  }
}

.order-details {
  &-grid {
    display: grid;
    grid-template-columns: 5.5rem 1fr auto auto;
    grid-gap: 1rem;

    @include respond($break-phone) {
      grid-template-columns: 5.5rem 1fr minmax(auto, 5.5rem) minmax(auto, 7rem);
    }
  }

  grid-column: col-start 1 / span 16;
  grid-row: 2;

  @include flex-row;

  justify-content: space-between;
  min-height: 65vh;

  &-map {
    display: none;

    @include respond($break-large) {
      display: initial;
      width: 50vw;
    }
  }

  .shipping-details {
    &-header {
      border-bottom: 1px solid $charcoal25;
      text-transform: uppercase;
      padding-bottom: 3px;
      margin: 5px 0;
    }

    &-address {
      padding: 5px 0;
    }
  }

  &-list {
    @include flex-column;

    justify-content: flex-start;
    padding: 1.25rem 1.5rem 3.375rem;
    width: 100%;
    min-height: 50vh;

    @include respond($break-large) {
      width: 50vw;
      padding: 2rem;
    }

    .order-copy {
      padding-bottom: 0.75rem;
    }

    .order-no {
      display: initial;
      grid-column: span 2;
      white-space: nowrap;
    }

    .quantity,
    .price {
      text-align: right;
      margin-top: auto;
      margin-bottom: 2px; // fixing elevation difference caused by a different font family used in .order-no
    }

    .config {
      flex-grow: 1;
      color: $charcoal50;
    }

    .item-name {
      color: $charcoal;
      margin-bottom: 0.25rem;
    }

    .tracking {
      &__status {
        margin-bottom: 0.25rem;
      }

      &__status-label {
        color: $charcoal;
        display: inline-block;
        white-space: pre;

        &::after {
          content: ': ';
          display: inline;
        }
      }

      &__status-value {
        color: $charcoal50;
      }

      &__link {
        display: inline-block;
        border-bottom: 1px solid;
      }

      &__estimate {
        color: $charcoal50;
      }
    }

    &-item {
      @include flex-column;

      border-bottom: 1px solid $charcoal25;
      padding: 1rem 0;
      min-height: 5.75rem;

      &:not(:last-of-type) {
        border-bottom: solid 1px #d1d0d0;
      }

      &-img {
        width: 100%;

        img {
          width: 100%;
        }
      }

      &-details {
        display: flex;
        flex-direction: column;
        width: 100%;
        color: $charcoal;
        min-height: 6rem;
      }

      &-quantity {
        text-align: right;

        @include respond($break-phone) {
          display: initial;
        }
      }

      &-price {
        text-align: right;
      }
    }

    &-addon {
      @include flex-column;

      align-items: center;
      margin-top: 1.375rem;

      h3,
      p,
      a {
        max-width: 20.75rem;
        text-align: center;
      }

      h3 {
        margin-bottom: 0.1875rem;
      }

      p {
        margin-bottom: 0.75rem;
      }

      a {
        @include heavy;
        @include body-large;

        color: $terracotta;
      }

      @include respond($break-phone) {
        margin-top: 1.625rem;

        h3 {
          @include h4;
        }
      }
    }

    &-costs {
      grid-column: col-start 1 / span 16;
      grid-row: 3;
      padding: 20px 0;

      @include respond($break-large) {
        padding: 30px 0;
      }

      .list-costs-line {
        @include flex-row;

        justify-content: space-between;

        &:not(:last-child) {
          margin-bottom: 0.25rem;
        }
      }
    }

    &-shipping {
      display: initial;

      .shipping-header {
        padding-bottom: 0.5rem;
        border-bottom: 1px solid $charcoal25;
      }

      .shipping-address {
        margin: 1rem 0;
        line-height: 1.3;
      }
    }

    &-resend-details {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
      max-width: 20rem;

      .resend-header {
        margin-bottom: 0.25rem;
      }

      .resend-button {
        @include body-medium;

        color: inherit;
        text-decoration: underline;
        cursor: pointer;
        padding: 0;
        background: none;

        &:disabled {
          cursor: not-allowed;
        }
      }
    }

    .underline {
      height: 1px;
      background: $charcoal25;
      grid-column: span 4;
    }
  }

  &__button {
    $border-width: 0.125rem;
    $mobile-height: 2.5rem;
    $desktop-height: 3.1875rem;
    width: 100%;
    margin: 0.5rem 0;

    cursor: pointer;
    padding: 0;
    height: $mobile-height;
    border: $border-width solid $charcoal25;
    border-radius: 0.125rem;
    line-height: $mobile-height - (2 * $border-width);
    @include respond($break-phone) {
      height: $desktop-height;
      line-height: $desktop-height - (2 * $border-width);
    }
  }
}
