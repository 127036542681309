@import '../partials/variables';
@import '../partials/mixins';

input[type="checkbox"]:focus ~ span,
input[type="checkbox"]:active ~ span,
input[type="checkbox"]:checked ~ span,
input[type="checkbox"]:hover ~ span {
  &::before {
    border-color: $charcoal;
    box-shadow: 0 2px 4px 0 rgba(56, 54, 51, 0.16);
  }
}

.charcoal {
  a {
    color: $charcoal;
    border-bottom: 1px solid $charcoal;
  }
}

.auth-form-col {
  .btn.create-account,
  .btn.log-in {
    @include respond-below($break-phone) {
      letter-spacing: 0.3px;
    }
  }
}

.look-up-order.btn.btn-latte {
  @include gta-burrow;

  margin-top: 1rem;
  width: 60%;

  @include respond-below($break-large) {
    font-size: 0.6875rem;
    line-height: 1rem;
    letter-spacing: 0.3px;
  }

  @include respond($break-large) {
    font-size: 0.875rem;
    letter-spacing: 0.7px;
  }

  &:hover {
    transform: translateY(-0.225rem);
  }

  .icon-order-lookup {
    margin-right: 0.5rem;
  }
}

.auth {
  .auth-box {
    .auth-form.signin {
      @include respond($break-large) {
        margin-top: -0.125rem;
      }
    }
  }
}

.auth-form-col.charcoal a {
  color: $charcoal;
  border-bottom: 1px solid $charcoal;

  &:hover {
    border-bottom: none;
  }

  @include body-medium;
}

.form-fancy {
  position: relative;
  height: 100%;

  .auth-form-col.charcoal {
    .checkbox {
      label {
        @include body-medium;

        color: $charcoal;
      }
    }
  }

  input,
  select {
    padding: 1.625rem 0.875rem 1.125rem;
    border: none;
  }

  input,
  textarea {
    &[type="text"],
    &[type="email"],
    &[type="tel"],
    &[type="password"],
    &[type="number"],
    &[type="date"] {
      width: 100%;
      border-radius: 0;

      ~ label {
        pointer-events: none;
      }
    }
  }

  &-center {
    text-align: center;
  }

  label {
    @include transition(all 0.25s);

    color: $charcoal;
    opacity: 1;

    &.block {
      display: block;
    }
  }

  .radio {
    label.selected {
      color: $charcoal;
      opacity: 1;
    }

    input[type="radio"] {
      border: 1px solid $charcoal;
      border-radius: 100%;
      padding: 0.7rem;
      margin: 0.3125rem;
      position: relative;
      vertical-align: middle;

      &:checked {
        &::before {
          background: $charcoal;
          border-radius: 100%;
          padding: 0;
          content: '';
          width: 1.1rem;
          height: 1.1rem;
          display: inline-block;
          position: absolute;
          top: 0.15rem;
          left: 0.15rem;
        }
      }
    }

    label.heavy {
      font-size: 0.9rem;
      letter-spacing: 0;
      font-weight: bold;

      span {
        margin-left: 0.625rem;

        span {
          margin-left: 0;
        }
      }

      img {
        margin-left: 0.625rem;
      }
    }
  }

  .checked {
    label {
      opacity: 1;

      span {
        font-weight: bold;
      }
    }
  }

  .checkbox {
    position: relative;
    display: flex;
    background: transparent;
    margin: 0.5rem 0;

    input:checked + label::after {
      opacity: 1;
    }

    input {
      position: absolute;
      padding: 0;

      span {
        &::before {
          border: 1px solid $charcoal25;
        }
      }

      &:checked + span {
        position: relative;
        color: $charcoal;

        &::before {
          border: 1px solid $charcoal;
        }

        svg {
          display: block;
        }
      }
    }

    span {
      min-width: 1.125rem;
      min-height: 1.125rem;
      max-width: 1.125rem;
      max-height: 1.125rem;
      display: flex;
      position: relative;
      font-size: 1rem;

      &::before {
        content: '';
        display: flex;
        min-width: 1rem;
        min-height: 1rem;
        max-width: 1rem;
        max-height: 1rem;
        border: 1px solid $concrete;
        border-radius: 2px;
      }

      svg {
        position: absolute;
        width: 0.9375rem;
        margin: auto;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: none;
      }
    }

    label {
      display: flex;
      align-items: center;
      position: relative;
      top: auto;
      left: auto;

      @include h5;
      @include book;

      span {
        margin-right: 0.4375rem;
        background-color: $white;
      }
    }
  }

  .select {
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: 0;
    height: 2.5rem;
    border: none;
    width: 100%;
    padding: 0.75rem 0.875rem 0;
    box-shadow: none;
    font-size: 1rem;

    @include respond($break-phone) {
      height: 3.625rem;
    }

    &:focus {
      outline: none;

      ~ label {
        z-index: 4;
        font-size: 0.75rem;
      }
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

fieldset {
  .checkbox {
    label {
      color: $charcoal50;
    }

    span {
      background-color: $white;

      &::before {
        opacity: 1;
      }
    }
  }
}

.form-fancy-wrapper {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: 1.25rem;
  row-gap: 1.25rem;

  @include respond($break-phone) {
    grid-template-columns: repeat(6, minmax(0, 1fr));
    column-gap: 1.875rem;
    row-gap: 1.875rem;
  }

  .form-fancy {
    grid-column: 1/-1;
    position: relative;

    input.has-label {
      padding-top: 0.9rem;

      @include respond($break-phone) {
        padding-top: 1rem;
      }
    }

    textarea.has-label {
      padding-top: 1.5rem;
    }

    input,
    select,
    textarea,
    .react-select__control {
      color: $charcoal;
      height: 3.125rem;
      border: 1px solid $charcoal25;
      border-radius: 0.125rem;
      padding: 0 1.25rem;
      width: 100%;

      &,
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        @include book;
      }

      &:hover {
        background-color: $charcoal15;
      }

      &:focus,
      &:active {
        border-color: $charcoal;
      }
    }

    .react-select {
      &-container.has-label {
        .react-select__single-value {
          padding-top: 1rem;
          margin: 0;
        }

        .react-select__input {
          padding-top: 1rem;
          padding-bottom: 0;
          margin: 0;
        }
      }

      &__placeholder {
        position: absolute;
        display: flex;
        align-items: center;
        bottom: 0;
        margin: auto;
        height: min-content;
        transform: null;
        transition: all 0.25s;
      }

      &__control {
        input {
          height: auto;
        }

        &--is-focused,
        &--menu-is-open {
          box-shadow: none;
          border-color: $charcoal;

          .react-select__value-container {
            .react-select__placeholder {
              @include label-small;

              top: -50%;
              color: $charcoal50;
            }
          }
        }
      }

      &__indicators {
        pointer-events: none;
      }

      &__indicator {
        padding: 0;

        svg {
          color: $charcoal;
        }
      }

      &__value-container {
        height: 100%;
        padding: 0;

        .react-select__placeholder {
          top: 0;
          color: $charcoal;
        }
      }

      &__menu {
        box-shadow: 0 2px 4px 0 rgba($charcoal, 0.1);
        border: 1px solid $charcoal25;
        border-radius: 0.125rem;
        font-size: 0.875rem;
        z-index: 5;
      }

      &__option {
        padding: 0.75rem;

        @include respond($break-phone) {
          padding: 1.125rem;
        }

        &--is-focused {
          background-color: $charcoal15;
        }

        &--is-selected {
          background-color: $charcoal15;
          color: $charcoal;

          .react-select__placeholder {
            top: 0;
          }
        }
      }
    }

    textarea {
      min-height: 6.25rem;
      padding: 0.625rem 1.25rem;
      resize: vertical;
    }

    .input-btn-container {
      position: absolute;
      right: 1rem;
      top: 0;
      height: 100%;
      display: flex;

      .icon {
        align-self: center;
      }
    }
  }

  &.divider {
    padding-top: 1.875rem;
  }

  .col-half {
    grid-column: 1/-1;

    @include respond($break-phone) {
      grid-column: span 3;
    }

    &-mobile {
      grid-column: span 1;

      @include respond($break-phone) {
        grid-column: span 3;
      }
    }
  }

  .col-two-third {
    grid-column: 1/-1;

    @include respond($break-phone) {
      grid-column: span 4;
    }
  }

  .col-one-third {
    grid-column: 1/-1;

    @include respond($break-phone) {
      grid-column: span 2;
    }
  }

  .col-even-third {
    grid-column: 1/-1;

    @include respond($break-phone) {
      grid-column: span 2;
    }
  }

  label {
    @include transition(all 0.25s);

    position: absolute;
    display: flex;
    align-items: center;
    z-index: 4;
    user-select: none;
    top: 0;
    bottom: 0;
    height: min-content;
    margin: auto;
    left: 1.3rem;
  }

  textarea ~ label {
    display: flex;
    align-items: flex-start;
    top: 0.375rem;
    left: 1.3rem;
    margin: 0;
  }

  textarea:disabled,
  input[type="text"]:disabled {
    background-color: $charcoal15;
    color: $charcoal50;
  }

  &.select-options {
    label {
      font-size: 0.75rem;
    }
  }

  .checkbox {
    z-index: 1;
    backface-visibility: visible;

    label {
      span {
        background-color: $white;
      }
    }
  }

  input[type="text"]:focus,
  input[type="email"]:focus,
  input[type="tel"]:focus,
  input[type="password"]:focus,
  input[type="number"]:focus,
  input[type="date"]:focus {
    outline: none;

    ~ label {
      @include label-small;

      z-index: 4;
      top: -50% !important;
      color: $charcoal50;
    }
  }

  .error,
  .required {
    .react-select {
      &__control {
        border-color: red;
      }

      &__placeholder {
        color: red !important;
      }
    }

    textarea,
    input[type="text"],
    input[type="email"],
    input[type="tel"],
    input[type="password"],
    input[type="number"],
    input[type="date"] {
      ~ label {
        z-index: 4;
        top: 0;
        color: red;
      }

      border: 1px solid red;
    }

    textarea {
      ~ label {
        top: 1.5rem;
      }
    }
  }

  .error.invalid {
    textarea,
    input[type="text"],
    input[type="email"],
    input[type="tel"],
    input[type="password"],
    input[type="number"],
    input[type="date"] {
      ~ label {
        @include label-small;

        z-index: 4;
        top: -50%;
        color: red;
      }

      border-width: 1px;
      border-style: solid;
      border-color: red;
    }

    textarea {
      ~ label {
        top: 0.5rem;
      }
    }
  }

  .valid {
    textarea,
    select,
    input[type="text"],
    input[type="email"],
    input[type="tel"],
    input[type="password"],
    input[type="number"],
    input[type="date"] {
      ~ label {
        @include label-small;

        z-index: 4;
        top: -50%;
        color: $charcoal50;
      }
    }

    textarea {
      ~ label {
        top: 0.5rem;
      }
    }

    .react-select__placeholder {
      @include label-small;
    }
  }

  textarea {
    ~ label {
      z-index: 4;
      top: 1.5rem;
      color: $charcoal;
    }
  }

  textarea:focus {
    ~ label {
      @include label-small;

      z-index: 4;
      top: 0.5rem;
      color: $charcoal50;
    }
  }
}
