@import '../partials/variables';
@import '../partials/mixins';

.pill-picker-radio {
  position: relative;
  cursor: pointer;

  span {
    @include label-small;
    @include gta-burrow;

    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border-radius: 999rem;
    padding: 0.125rem 0.5rem;
  }

  input {
    position: absolute;
    width: 0;
    height: 0;
    padding: 0;
  }

  input:checked ~ span,
  input:hover ~ span {
    background: $sandstone200;
  }
}
