@mixin dimensions-img {
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  min-width: 100%;
}
.pdp-dimensions {
  &-subhead {
    @include gta-burrow;
    @include h3;
    grid-row: 1;
    grid-column: 1 / -1;
    font-size: 1rem;
    @include respond($break-phone) {
      grid-column: 2 / span 6;
    }
    @include respond($break-large) {
      grid-column: 2 / span 10;
      font-size: 1.25rem;
    }
  }
  &-images {
    grid-column: 1 / -1;
    grid-row: 3;
    @include respond($break-phone) {
      grid-column: 2 / -2;
    }
    @include respond($break-larger) {
      grid-column: 7 / span 6;
      grid-row: 2;
    }
    &-item {
      height: 100%;
      .video-component {
        width: 100%;
        height: 100%;
        position: relative;
        video {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: top;
        }
      }
    }
  }
  &-measurements {
    grid-column: 1 / -1;
    display: table;
    height: min-content;
    width: 100%;
    font-size: 0.75rem;
    border-collapse: collapse;
    @include respond($break-phone) {
      grid-column: 2 / -2;
      grid-row: 2;
    }
    @include respond($break-larger) {
      grid-column: 2 / span 4;
    }
    &-item {
      display: table-row;
      border-bottom: 1px solid $charcoal50;
      @include respond($break-phone) {
        padding: 5px;
      }
      @include respond($break-phone) {
        padding: 8px;
      }
      &-title, &-copy {
        display: table-cell;
        width: 50%;
        padding: 0.375rem 0;
        line-height: 24px;
        @include respond($break-phone) {
          padding: 1.1875rem 0;
        }
      }
      &-title {
        width: 50%;
      }
      &-copy {
        color: $charcoal75;
      }
    }
  }
}
