@import '../partials/variables';
@import '../partials/mixins';

.content-break {
  @include page-padding;
  padding: 1.875rem 1.5rem 0;
  @include respond($break-phone) {
    padding: 2.5rem 1.5rem 0;
  }
  @include respond($break-large) {
    padding: 3.75rem 2rem 0;
  }
  @include flex-row;
  &-line {
    background-color: $charcoal25;
    height: 1px;
    width: 100%;
    margin: auto;
  }
  &-image {
    .images {
      height: 5.5rem;
      width: 5.5rem;
      @include respond($break-large) {
        height: 7.5rem;
        width: 7.5rem;
      }
    }
  }
}
