@import '../partials/variables';
@import '../partials/mixins';

.welcome {
  @include grid-container;
  @include custom-breakpoint-styles(
    padding,
    1.875rem $horizontal-page-padding-phone 2.5rem,
    2.5rem 6.75rem 3.125rem,
    2.5rem $horizontal-page-padding-desktop 3.125rem,
  );

  text-align: center;

  &__content {
    @include custom-breakpoint-styles(
      grid-column,
      1 / span 4,
      1 / span 8,
      3 / span 8
    );

    &__copy {
      white-space: pre-line;
      color: $charcoal;

      @include respond($break-large) {
        line-height: 1.33;
      }
    }

    &__points {
      &__point {
        @include body-medium;
        @include custom-breakpoint-styles(
          margin-bottom,
          0.625rem,
          0.8125rem,
          1rem,
        );

        color: $charcoal50;

        &:first-child {
          color: $terracotta;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &__rich-copy,
    &__points__point:first-child {
      @include custom-breakpoint-styles(
        margin,
        1.875rem 0 0.625rem,
        2.5rem 0 0.8125rem,
        2.5rem 0 1rem,
      );
    }
  }
}
