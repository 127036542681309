@import '../partials/variables';
@import '../partials/mixins';


.lifestyle-card {
  &__wrapper {
    position: relative;
  }

  &.span2 {
    display: none;
    @include respond($break-large) {
      display: block;
      grid-column: span 8;
    }
  }
  &.span1 {
    display: none;
    @include respond($break-large) {
      display: block;
      grid-column: span 4;
    }
  }

  &.lifestyle-card__carousel-wrapper {
    display: block;
  }

  &__carousel {
    position: relative;
    display: flex;
    flex-direction: column-reverse;

    .lifestyle-card {
      &__text-wrapper {
        position: relative;
        width: 100%;
        padding: 0;
        margin-top: .5rem;
      }

      &__text {
        padding: 0;
      }

      &__link {
        @include body-medium;
        color: $charcoal;
      }
    }
  }

  &__text-wrapper {
    position: absolute;
    z-index: 1;
    top: 0;
    height: 100%;
    color: $charcoal;
    padding: 2rem;
    width: 75%;
    @include respond($break-xl) {
      width: 47.5%;
    }
    &_left {
      left: 0;
    }
    &_right {
      right: 0;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 1rem;
    height: 100%;
    @include respond($break-large) {
      padding: 0 2rem;
    }
    &-icon {
      .graphcms-image-wrapper {
        div:first-of-type {
          padding-bottom: 15% !important; // override inline styles
          @include respond($break-xl) {
            padding-bottom: 25% !important; // override inline styles
          }
        }
        img:last-child {
          width: auto !important; // override inline styles
        }
      }
    }

    &--small-text {
      @include body-medium;
    }

    &--heavy {
      @include heavy;
    }
  }

  .video-component {
    position: static;
    height: 0;
    padding-bottom: 62.645%;

    @include respond($break-phone) {
      padding-bottom: 30.1562%;
    }

    &__media-container {
      position: static;
    }
  }
}
