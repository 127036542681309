@import '../partials/variables';
@import '../partials/mixins';

.assembly,
.homepage {
  .dropdown-container {
    @include page-padding;
    text-align: center;
    justify-items: center;
  
    &__selection {
      grid-column: 1 / span 6 !important;
      font-size: 14px;
  
      @include respond($break-phone) {
        grid-column: 1 / span 4 !important;
      }
  
      @include respond($break-larger) {
        grid-column: 2 / span 3 !important;
      }
    }
    &__download {
      line-height: 1.8rem;
      display: block;
      grid-column: 1 / span 6 !important;
      padding-top: .375rem;
      padding-bottom: .375rem;
  
      @include respond($break-phone) {
        display: inline-block;
        grid-column: 5 / span 2 !important;
        border: solid 1px #DCA18A;
        padding-top: .625rem;
        padding-bottom: .625rem;
      }
  
      @include respond($break-larger) {
        grid-column: 5 / span 1 !important;
      }
    }
  }  
}
