@import '../partials/variables';
@import '../partials/mixins';

.search-dropdown-container {
  .search-dropdown {
    background: $white;
    padding: 0 $horizontal-page-padding-phone 2.25rem;
    max-height: 100vh;
    overflow: scroll;

    @include respond($break-tablet) {
      padding: 0 $horizontal-page-padding-phone 2.25rem;
    }

    @include respond($break-large) {
      padding: 0 $horizontal-page-padding-desktop 2.25rem;
      max-height: 60vh;
    }
  }
}

.search-dropdown-popular-links-container {
  display: flex;
  flex-direction: column;

  & > p {
    font-size: 0.813rem;
    margin-bottom: 1rem;
  }
}

.search-dropdown-popular-link {
  font-weight: 800;
  font-size: 0.813rem;
  margin-bottom: 1rem;
}

.search-dropdown-no-results-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  p {
    min-height: 11.0625rem;
  }
}

.search-dropdown-products-skeleton-grid-container {
  display: grid;
  position: relative;
  margin-bottom: 3rem;
  grid-gap: 1.3125rem;
  grid-template-columns: repeat(2, minmax(0, 1fr));

  & .product-card-container-skeleton {
    grid-column: span 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  & .product-card-image-skeleton {
    max-height: 6.188rem;
    height: 6.188rem;
    background: linear-gradient(270deg, #F5F3F2 30.35%, #FFF 48.79%, #F7F6F5 67.63%);
  }

  & .product-card-description-skeleton {
    gap: 0.5rem;
    display: flex;
    flex-direction: column;

    & div {
      max-height: 1rem;
      height: 1rem;
      background: linear-gradient(270deg, #F5F3F2 30.35%, #FFF 48.79%, #F7F6F5 67.63%);
    }
  }

  @include respond($break-phone) {
    grid-gap: 1.6rem;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @include respond($break-tablet) {
    grid-gap: 1.6rem;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @include respond($break-large) {
    grid-gap: 1.6rem;
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  @include respond($break-larger) {
    grid-gap: 1.6rem;
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  @include respond($break-xl) {
    grid-gap: 1.6rem;
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
}

.search-dropdown-products-grid-container {
  display: grid;
  position: relative;
  margin-bottom: 3rem;
  grid-gap: 1.3125rem;
  grid-template-columns: repeat(2, minmax(0, 1fr));

  & > .product-card-dropdown {
    grid-column: span 1;
  }

  & > .search-dropdown-products-view-all {
    top: -1rem;
    position: relative;
    align-self: center;
    justify-self: center;
    font-weight: 800;
    font-size: 0.813rem;
  }

  @include respond($break-phone) {
    grid-gap: 1.6rem;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @include respond($break-tablet) {
    grid-gap: 1.6rem;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @include respond($break-large) {
    grid-gap: 1.6rem;
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  @include respond($break-larger) {
    grid-gap: 1.6rem;
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  @include respond($break-xl) {
    grid-gap: 1.6rem;
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
}
