@import '../partials/variables';
@import '../partials/mixins';

.save-cart-modal {
  &__container {
    max-width: 20.4375rem;
    background-color: $sandstone;
  }
  &__grid {
    display: grid;
    padding: 2.625rem 1.5rem;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(5, auto);
    row-gap: 1rem;
  }

  &__header {
    text-align: center;
    margin-right: 1rem;
  }

  &__input-wrapper,
  &__input-wrapper.form-fancy-wrapper {
    display: block;
    margin: 0;

    input {
      border-radius: .125rem;
      background-color: #ffffff;

      & + label {
        @include body-large;
      }
    }

    .checkbox {
      input {
        height: auto;
      }
      label {
        @include body-medium;
        @include book;
        display: flex;
        align-items: flex-start;
        span {
          position: relative;
          &::before {
            top: 0;
            border: solid 1px $charcoal;
            border-radius: 2px;
            background: $white;
            opacity: 1;
          }
        }
      }
    }
  }

  &__submit-button {
    display: block;
    width: auto;
    margin: .75rem auto .25rem;
    @include respond($break-phone) {
      font-size: .875rem;
    }
  }

  &__footer {
    text-align: center;
    color: $crushedgravel;
  }

  &__continue-shopping {
    text-align: center;
    text-decoration: underline;
    color: $terracotta;
    background: none;
    cursor: pointer;
  }
}
