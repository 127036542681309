@import '../partials/variables';
@import '../partials/mixins';

@mixin body-large-important {
  font-size: 0.8125rem !important; //override inline styles
  line-height: 1.125rem;
  letter-spacing: 0;

  @include respond($break-large) {
    font-size: 0.875rem !important; //override inline styles
    line-height: 1.25rem;
    letter-spacing: 0.3px;
  }
}

.reviews {
  @include page-padding;

  padding-top: 0;

  @include respond($break-large) {
    padding-top: 0;
  }

  @include respond($break-large) {
    padding-top: 0;
  }

  h2 {
    text-align: center;
    margin-bottom: 2.5rem;
  }

  & div[class^="subheader__group subheader__group--tabs"] {
    display: none;
  }

  & div[class^="item__review"] div[class^="R-TextHeading"],
  & div[class^="item__review"] div[class^="R-TextBody"],
  & div[class^="item__meta"] div[class^="cssVar-authorName"] {
    @include book;
    @include body-large;
    @include body-extra-large;
  }
}
