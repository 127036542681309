@import "../partials/variables";
@import "../partials/mixins";

.pdp-options {
  &.tufted,
  &.door {
    grid-column: 2 / 2;
  }

  &.arms,
  &.leg-style,
  &.one-col {
    grid-column: span 1;
    .pdp-details-info {
      margin-left: 0;
    }
  }

  &__header,
  &-header,
  &-group__header {
    font-size: 0.875rem;
    line-height: 1.75;
    &-title {
      &:not(:last-child) {
        font-weight: normal;
        &::after {
          content: " - ";
          display: inline-block;
          padding: 0 0.25rem;
        }
      }
    }
  }

  &__header-value,
  &-header-value,
  &-group__header-value,
  &-group__header, {
    font-size: 0.875rem;
    line-height: 1.75;
    color: $charcoal;
    letter-spacing: 0.4px;
    font-weight: 900;
  }

  &-group {
    .pdp-options-header,
    .pdp-options__header {
      display: none;
    }
  }

  &__description,
  &-description {
    color: $concrete;
    text-transform: uppercase;
    font-size: 0.5625rem;
    margin: 0.5rem 0;
  }

  &.canon-shelves {
    .pdp-options-wrapper {
      display: grid;
      column-gap: 0.5em;
      width: 70%;
    }

    .pdp-options-ul {
      grid-column: 1;
    }

    .pdp-details-info {
      grid-column: 2;
      grid-row: 2;
    }
  }

  &.cover-configuration {
    .pdp-options-ul {
      width: 7.5rem;
      height: 3rem;
    }
    .pdp-details-info {
      margin-left: 0.375rem;
    }
  }

  &.chair-quantity {
    .pdp-options-ul {
      width: 6.75rem;
      height: 3rem;
    }
  }

  &-wrapper {
    @include flex-row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 0.0625rem;

    &__column,
    &.icon-wrap {
      flex-direction: column;
      align-items: flex-start;
    }

    &.addOns {
      @include flex-column;
      justify-content: flex-start;
      align-items: flex-start;
    }
    &.addon-selector {
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
      grid-column-gap: 0.25rem;
      @include respond-between($break-phone, $break-large) {
        max-width: 20rem;
      }
    }
    .tooltip {
      padding-left: 0.25rem;
      color: $charcoal;
      font-weight: 600;
      cursor: pointer;
      &:hover,
      .active {
        color: $charcoal;
      }
    }

    .pdp-details-info {
      display: flex;
      flex-grow: 1;
      margin: 0;
      align-items: center;
    }
  }


  &-group-name {
    margin-top: 0.1875rem;
    font-size: 0.5625rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: $charcoal75;
    text-transform: uppercase;
  }

  &-icon {
    cursor: pointer;
    height: fit-content;
    opacity: 1;
    border-radius: 0.125rem;
    border: 1px solid $white;
    margin: 0.0625rem;

    &.active,
    &:hover {
      border: solid 0.0625rem $charcoal;
      box-shadow: 0 0.25rem 0.3125rem -0.125rem rgba(71, 69, 67, 0.3);
    }

    .pdp-options-icon__picture {
      display: flex;
      align-self: center;
      justify-content: center;

      img {
        border: 1px solid $white;
        border-radius: 2px;
        width: 100%;
      }
    }
  }

  &.icon-underline {
    .pdp-options-icon {
      text-align: center;
      border-radius: 0;
      margin-right: 0;
      border: 0;
      border-bottom: solid 0.0625rem $charcoal25;
      width: 100%;

      &.active,
      &:hover {
        border: 0;
        border-bottom: solid 0.0625rem $charcoal;
        box-shadow: none;
      }

      &__picture {
        opacity: 1;
        margin: 0.5rem auto 1rem;

        img {
          width: unset;
          border: none;
        }
      }
    }
  }

  &-ul {
    display: inline-flex;
    width: 100%;

    &--wrapped {
      flex-wrap: wrap;
      grid-gap: 0.6875rem;
    }

    &--wrapped-no-gap {
      flex-wrap: wrap;
    }

    .icon-wrap & {
      justify-content: flex-start;
    }

    .pdp-quantity {
      height: 2.5rem;
      width: 2.5rem;
      background-color: transparent;
      color: $charcoal;
      margin-right: 0;
      &:hover {
        background-color: transparent;
        color: $charcoal;
        font-size: 1.25rem;
        @include transition;
      }
      &-num {
        margin-right: 0;
        height: 2.5rem;
        width: 2.5rem;
        background-color: $charcoal;
        color: $white;
      }
    }
    &.dresser {
      max-width: 20rem;
    }
    &.headboard-add-on,
    &.size,
    &.sizes,
    &.dresser,
    &.material {
      flex-wrap: wrap;
      grid-gap: 0.6875rem;

      .pdp-options-icon:nth-child(3) {
        flex-basis: 50%;
        @include respond-between($break-large, $break-xl) {
          flex-basis: 40%;
        }
      }
    }
  }
  &-headboard-add-on,
  &-material,
  &-size {
    @include flex-row;
    @include book;

    font-size: 0.6875rem;
    color: $black;
    border: 0.0625rem solid $charcoal25;
    background-color: $white;
    align-items: center;
    padding: 0.31rem;
    cursor: pointer;
    height: 2rem;
    width: 5rem;
    margin-right: 0;
    border-radius: 2px;
    &:hover,
    &.active {
      box-shadow: 0 2px 4px 0 rgba(56, 54, 51, 0.25);
      border: 0.0625rem solid $charcoal;
    }
  }
  &-circle {
    $activeBorderOffset: -0.3125rem;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    cursor: pointer;
    width: 1.125rem;
    height: 1.125rem;
    margin: 0.4375rem 1rem 0.1875rem 0;
    &:after {
      position: absolute;
      content: "";
      border-radius: 50%;
      left: -0.125rem;
      top: -0.125rem;
      width: 1.25rem;
      height: 1.25rem;
    }
    &.active,
    &:hover {
      width: 1.125rem;
      height: 1.125rem;
      @include transition(all 0.25s);
      &:after {
        content: "";
        position: absolute;
        left: $activeBorderOffset;
        top: $activeBorderOffset;
        width: 1.625rem;
        height: 1.625rem;
        border: 0.0625rem solid $charcoal;
        border-radius: 50%;
        box-shadow: 0 0.25rem 0.3125rem -0.125rem rgba(71, 69, 67, 0.3);
        @include transition(all 0.25s);
      }
    }

    &.has-border,
    &.IV,
    &.WT {
      border: 0.0625rem solid $charcoal25;
      &:hover,
      &.active {
        &:after {
          top: $activeBorderOffset - 0.0625rem;
          left: $activeBorderOffset - 0.0625rem;
        }
      }
    }

    @each $pdpcolor, $color in $colors {
      &.#{$pdpcolor} {
        background: $color;
      }
    }
  }

  &-arms {
    cursor: pointer;
    height: fit-content;
    margin-top: 0.125rem;
    margin-left: 0.0625rem;
    opacity: 1;
    margin-right: 0.5rem;
    padding: 0.3125rem 0.0625rem 0;
    border-radius: 0.125rem;
    border: 1px solid $white;
    .pdp-options-icon__picture {
      display: flex;
      align-self: center;
      justify-content: center;
      img {
        border: 1px solid $white;
        border-radius: 2px;
        width: 100%;

        @include respond-below($break-xs) {
          width: 90%;
        }

        @include respond-between($break-large, $full-width) {
          width: 90%;
        }
      }
    }

    &.active,
    &:hover {
      border: solid 0.0625rem $charcoal;
      box-shadow: 0 0.25rem 0.3125rem -0.125rem rgba(71, 69, 67, 0.3);
    }

    span {
      display: none;
    }
  }

  &-shelves,
  &-dresser {
    cursor: pointer;
    height: fit-content;
    margin-right: 0.2rem;
    .pdp-options-icon__picture {
      img {
        border: 1px solid $white;
        border-radius: 2px;
        width: 100%;
        @include respond-below($break-xs) {
          width: 90%;
        }
        @include respond-between($break-large, $full-width) {
          width: 90%;
        }
      }
    }
    &.active,
    &:hover {
      .pdp-options-icon__picture {
        img {
          border: 1px solid $charcoal;
        }
      }
    }
  }

  &-tufted {
    width: 3.75rem;
    text-align: center;
    border-radius: 0;
    margin-right: 0;
    border: 0;
    border-bottom: solid 0.0625rem $charcoal25;
    .pdp-options-icon__picture {
      opacity: 1;
      margin: 0.5rem auto 1rem;
    }

    &.active,
    &:hover {
      border: 0;
      border-bottom: solid 0.0625rem $charcoal;
    }
  }

  &-leg-style {
    width: 1.6875rem;
    height: 2.5rem;
    margin-right: 0.375rem;
    border-radius: 2px;
    border: solid 0.0625rem transparent;
    &.active,
    &:hover {
      border-color: $charcoal;
      box-shadow: 0 0.125rem 0.25rem 0 rgba(71, 69, 67, 0.3);
    }
    .pdp-options-icon__picture img {
      width: 100%;
    }
  }

  &-door {
    width: 3.5625rem;
    margin-right: 0;
    border-bottom: solid 0.0625rem $charcoal25;
    border-radius: 0;
    &.active,
    &:hover {
      border-bottom-color: $charcoal;
    }
    .pdp-options-icon__picture {
      display: block;
      img {
        display: block;
        width: 2.3125rem;
        margin: auto;
      }
    }
  }

  &-button {
    @include flex-row;
    @include book;

    font-size: 0.6875rem;
    color: $black;
    border: 0.0625rem solid $charcoal25;
    background-color: $white;
    align-items: center;
    padding: 0.313rem 1rem;
    cursor: pointer;
    min-height: 2rem;
    min-width: 5rem;
    margin-right: 0;
    border-radius: 2px;

    &:hover,
    &.active {
      box-shadow: 0 2px 4px 0 rgba(56, 54, 51, 0.25);
      border: 0.0625rem solid $charcoal;
    }

    &__left-aligned {
      flex-grow: 1;
      justify-content: flex-start;
      padding: 0.25rem;
    }

    &__color {
      display: inline-block;
      border-radius: 0.125rem;
      height: 2rem;
      width: 1rem;
      margin-right: 0.25rem;
    }
  }

  &-tab {
    flex: 1 0;

    > div {
      @include book;

      font-size: 0.875rem;
      text-align: center;
      color: $charcoal50;
      border-bottom: 1px solid $charcoal20;
      cursor: pointer;
      padding: 0.313rem 1rem;
      min-height: 2rem;
      min-width: 5rem;
    }

    > div:hover,
    &.active > div {
      color: $charcoal;
      border-bottom-color: $charcoal;
    }
  }

  &-checkbox {
    @include flex-row;

    align-items: center;
    position: relative;
    cursor: pointer;
    font-size: 0.8125rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.85;
    letter-spacing: normal;

    &-wrapper {
      @include flex-row;

      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      white-space: nowrap;
      .modal-text {
        margin-left: 0.25rem;
      }
    }

    &-input {
      position: relative;
      z-index: 1;
      cursor: pointer;
      border: 0.0625rem solid $charcoal25;
      border-radius: 2px;
      width: 1.5rem;
      height: 1.5rem;
      margin: 0.3125rem 0.625rem 0.1875rem 0;
    }

    &-label {
      color: $charcoal;
    }

    &-price {
      color: $charcoal75;
    }

    &.active {
      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0.75rem;
        left: 0.25rem;
        width: 0.8125rem;
        height: 0.275rem;
        border-left: solid 0.1rem $black;
        border-bottom: solid 0.1rem $black;
        transform: rotate(-40deg) skew(-15deg, -15deg);
      }

      .pdp-options-checkbox-input {
        border: 0.0625rem solid $black;
        box-shadow: 0 2px 4px 0 rgba(56, 54, 51, 0.25);
      }
    }
  }

  &-addon-selector {

    @include body-medium;
    border: 1px solid $charcoal75;
    border-radius: 0.125rem;
    padding: 0.25rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    @each $pdpcolor, $color in $colors {
      .color-#{$pdpcolor} {
        display: inline-block;
        border-radius: 0.125rem;
        background: $color;
        height: 2rem;
        width: 1rem;
        margin-right: 0.25rem;
      }
    }
    &.active,
    &:hover {
      border: 1px solid $charcoal;
      box-shadow: 0 0.25rem 0.3125rem -0.125rem rgba(71, 69, 67, 0.3);
    }
  }

  &__counter-modifier {
    &-wrapper {
      display: flex;
      align-items: center;
      font-size: 0.8125rem;
      line-height: 1.75;
      color: $charcoal;

      .modal-icon {
        flex-shrink: 0;
      }
    }

    &-container {
      display: flex;
      align-items: flex-start;
    }

    &-label {
      flex-shrink: 1;
      display: block;
      margin-top: 0.3125rem;
    }

    &-price {
      flex-shrink: 0;
      color: $charcoal75;
    }

    &-modal-wrapper {
      position: relative;
      display: inline;
      height: 1rem;

      .modal-icon {
        position: absolute;
        bottom: 0.0625rem;
      }
    }
  }

  &__counter {
    box-sizing: border-box;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    width: 1.5rem;
    height: 1.5rem;
    margin: 0.3125rem 0.625rem 0.1875rem 0;
    border: 1px solid $black;
    border-radius: 0.75rem;
    transition: width ease-in-out 300ms;

    &:hover,
    &--expanded {
      box-shadow: 0 0.125rem 0.25px rgba(0, 0, 0, 0.0625);
    }

    &--expanded {
      justify-content: flex-start;
      width: 5rem;
      padding: 0 0.625rem;
    }

    &--max-count-reached {
      width: 3.8125rem;
    }

    &-open-button,
    &-subtract-button,
    &-add-button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      background-color: $white;
      border-radius: 0.75rem;
      border: none;
      box-shadow: none;
      font-size: 0.625rem;
      cursor: pointer;

      i::before {
        display: block;
      }
    }

    &-open-button {
      width: 1.375rem;
      font-size: 0.8125rem;

      i::before {
        width: 0.8125rem;
        height: 0.8125rem;
      }
    }

    &-subtract-button {
      padding-right: 0.5625rem;

      i::before {
        width: 0.625rem;
        height: 0.625rem;
      }
    }

    &-add-button {
      padding-left: 0.5625rem;

      i::before {
        width: 0.625rem;
        height: 0.625rem;
      }
    }

    &-count {
      flex: 0 0 1.25rem;
      background: $white;
      text-align: center;
      font-weight: 850;
    }

    &-subtract-button,
    &-add-button,
    &-count {
      display: none;
      opacity: 0;
      transition: opacity ease-out 150ms 300ms;
    }

    &--expanded &-open-button {
      display: none;
      opacity: 0;
    }

    &--expanded &-subtract-button,
    &--expanded &-add-button {
      display: flex;
      opacity: 1;
    }

    &--expanded &-count {
      display: block;
      opacity: 1;
    }
  }

  &.full-width {
    grid-column: 1 / -1;
  }
}
