@import "../partials/variables";
@import "../partials/mixins";

.recommended__cart-item__button {
  color: #383633 !important;
  background-color: #fcee71 !important;
  height: 1.5rem;
  width: 3.5625rem;
  min-width: 3rem;
  align-self: flex-end;
  grid-column: 3;
  grid-row: 3;
  padding: 0;
  font-family: "GT America Burrow";
  transition: all 0.25s;
  font-size: 0.6875rem;
  line-height: 1rem;
  border-radius: 0.125rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  cursor: pointer;
  letter-spacing: 0.5px;
  letter-spacing: 1px;

  @include respond($break-phone) {
    font-size: 0.6875rem;
  }
}
