/* stylelint-disable scss/double-slash-comment-empty-line-before */
/* stylelint-disable no-eol-whitespace */
@import "../partials/variables";
@import "../partials/mixins";

.header-navigation {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 9;
}

.links-section-item {
  padding-right: 0;
  display: flex;
  color: rgba($charcoal, 0.75);
  background: none;
  position: relative;
  margin: auto 0.35rem;
  white-space: nowrap;
  align-items: center;
  font: inherit;

  .header {
    color: $charcoal;
  }

  @media (hover: hover) {
    &:hover {
      font-weight: 400;
      color: $burgundy;
    }
  }

  @media (hover: none) {
    &:hover {
      color: inherit;
    }
  }

  &.active {
    span {
      display: block;
      padding-bottom: 1px;
    }
  }

  &.mobile-cart-link {
    margin-right: 0;
    background: none;
  }

  .icon-plus {
    font-weight: 800;
    padding-right: 0.2rem;
    cursor: pointer;
    font-size: 0.8rem;
    color: $charcoal;
  }

  .header-menu-desktop & {
    .icon-nav-arrow,
    .icon-plus {
      display: none;
    }

    .header {
      @include h5;
    }

    &.mobile-cart-link {
      padding: 0;
    }
  }

  .header-menu-mobile & {
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: inline-flex;
    margin: 0;
    width: 100%;
    justify-content: space-between;
    font-size: 0.9rem;
  }

  .header-utility-navigation * {
    padding-right: 1.25rem;
    display: flex;
    border-bottom: 1px solid $sandstone;

    &:last-of-type {
      border-bottom: none;
    }
  }

  .navigation-dropdown__content {
    color: rgba($charcoal, 0.75);
  }
}

.navigation-position-button {
  justify-content: space-between;
  padding: 0.75rem 1.5rem 0.75rem 1.25rem;
  cursor: pointer;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  background: none;
  color: inherit;
  font: inherit;

  @media (hover: hover) {
    &:hover {
      color: inherit;
      cursor: pointer;
    }
  }

  @media (hover: none) {
    &:hover {
      color: inherit;
    }
  }

  .header-menu-desktop & {
    margin: 0 1rem;
    padding: 1rem 0;

    @include h6;

    &.active {
      border-bottom: 1px solid $charcoal;
      display: inline-block;
    }
  }

  .header-menu-mobile & {
    width: 100%;

    .icon-nav-arrow {
      font-size: 0.44rem;
    }
  }

  @include respond($full-width) {
    .accounts-section & {
      margin: 0 1rem;
    }
  }
}

.navigation-position-button::after {
  @include respond($break-xl) {
    content: "";
    position: relative;
    z-index: 10;
    height: 1px;
    background-color: $charcoal;
    bottom: -1.05rem;
    width: 100%;
    display: block;
  }
}

.header-utility-navigation {
  .dropdown-section {
    position: relative;

    .links-section-item {
      padding: 1.25rem;
    }
  }

  .images {
    height: 4rem;
    width: 4rem;
    margin-right: 1rem;
    background-size: contain;
  }

  @include flex-row;
  @include body-medium;

  transition: all 0.25s;
  background-color: $sandstone5;
  position: relative;
  justify-content: space-between;
  width: 100%;
  padding: 0 2.2rem 0 2rem;
  align-items: center;

  &.pinned {
    z-index: 3;
    padding-right: 0;
    height: $header-utility-navigation-mobile;

    @include respond($break-phone) {
      height: $header-utility-navigation-desktop;
    }

    @include respond-between($break-phone, $break-xl) {
      padding-left: 1rem;
    }
  }

  &.with-popout {
    z-index: 2;
  }

  &.unpinned {
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }

  a {
    color: inherit;
  }

  &__banner {
    display: flex;
    height: inherit;
    align-items: center;

    .free-shipping-icon {
      @include responsive-image("../../assets/nav/Free-Shipping.svg");

      position: relative;
      width: 24px;
      height: 19.2px;
      margin: 0 0.6em 0 0;
    }

    p,
    .free-shipping-icon {
      @include body-medium;
    }

    @include respond-below($break-phone) {
      width: 100%;
      justify-content: center;
    }
  }

  .header-utility-menu {
    display: flex;
    height: inherit;
    align-items: center;
    justify-content: flex-end;

    .links-section-item {
      padding-left: 0;
      padding-right: 0;
      margin: auto 0.75rem;

      @include respond-below($break-phone) {
        display: none;

        &:last-child {
          display: block;
        }
      }

      @include respond($break-xl) {
        margin: auto 1rem;
      }
    }

    a[href*="accessibility"] {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap;
      border: 0;
    }

    .utility-dropdown {
      display: none;
      position: absolute;
      overflow: hidden;
      max-height: 0;
      right: 0;
      z-index: 11;
      top: $header-utility-desktop + 0.75rem;
      background-color: $white;
      transition: max-height 0.8 ease-in;

      @include respond($break-phone) {
        display: block;
      }

      &.active {
        max-height: 600px;
        transition: max-height 0.3s ease-out;
      }

      .header-menu-link-container {
        @include flex-row;

        justify-content: left;
        align-items: center;
        color: $charcoal;
        position: relative;
        width: 100%;
        text-align: center;
        margin: auto 0;
        font-size: 0.875rem;

        .link-img {
          max-height: 9.25rem;
          max-width: 14.25rem;

          img {
            object-fit: contain !important;
          }
        }

        &:hover {
          color: $burgundy;
          cursor: pointer;
        }
      }
    }
  }
}

.header-logo {
  @include respond-below($break-xl) {
    position: absolute;
    margin: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
    left: 50%;

    a {
      position: relative;
      display: block;
      left: -50%;
    }
  }

  @include respond($break-xl) {
    grid-column-start: inherit;
    justify-self: start;

    .icon-burrow {
      font-size: 1.15rem;
    }
  }
}

.header-menu-desktop {
  // TODO: check for unused styles
  @include gta-burrow;

  display: flex;
  justify-content: space-between;
  width: 100%;
  z-index: 11;
  height: $header-height-mobile;
  padding: 0 1rem;
  align-items: center;
  background-color: $white;
  background-image: none;
  color: $charcoal;

  &:has(> .navigation .navigation-position--hover-navigation) {
    position: relative;
  }

  @include respond($break-xl) {
    z-index: 5;
  }

  @include respond($break-medium) {
    height: $header-height-desktop;
  }

  @include respond($break-xl) {
    grid-template-columns: minmax(0, 1fr) 4fr;
    padding: 0.25rem 2rem;

    .badge {
      top: 50%;
      margin-top: 1rem;
      width: 100%;
    }
  }

  .icon-close,
  .icon-bars {
    width: 1.25rem;

    @include respond($break-xl) {
      display: none;
    }
  }

  .accounts-section {
    display: inherit;

    i {
      font-size: 1.07rem;
    }

    .mobile-login-link {
      @include respond-below($break-medium) {
        display: none;
      }
    }

    .mobile-link-span {
      display: none;
    }

    .mobile-cart-link,
    .mobile-login-link {
      @include respond-between($break-large, $break-larger) {
        margin: auto 0.5rem;
      }

      @include respond-between($break-larger, $full-width) {
        margin: auto 0.75rem;
      }
    }

    .mobile-cart-link {
      @include respond-between($break-large, $break-larger) {
        margin-right: 0;
      }

      @include respond-between($break-larger, $full-width) {
        margin-right: 0;
      }
    }
  }

  .navigation {
    @include respond-below($break-xl) {
      display: none;
    }

    display: flex;
  }

  .navigation-dropdown__featured {
    display: none;
  }

  .navigation-position-drawer {
    position: absolute;
    overflow: hidden;
    max-height: 0;
    right: 0;
    left: 0;
    z-index: 11;
    top: $header-height-desktop;
    background-color: white;

    &.active {
      max-height: 600px;
      transition: max-height 0.3s ease-out;
    }
  }

  .navigation-position-drawer__content {
    margin: 0 0 0 1.75rem;
    padding: 2rem 0 1.375rem;
    display: flex;
    justify-content: space-between;

    .navigation-position-drawer__content__styled-columns {
      display: flex;

      .links-section-item {
        @include h6;

        margin: 0 1.25rem 0 0;
        white-space: normal;

        &button:hover {
          text-decoration: underline;
        }
      }
    }

    .navigation-position-drawer__content__featured {
      display: contents;
    }

    .images {
      width: 100%;

      &.has-neighbors {
        width: auto;
      }
    }

    .images__points {
      display: grid;
      grid-auto-columns: 1fr;
      grid-template-rows: repeat(3, auto);
      grid-auto-flow: column;
      row-gap: 0;

      .images__point-asset {
        padding-bottom: 70% !important;

        .graphcms-image-outer-wrapper {
          height: 9.25rem;
        }
      }
    }

    .images__point {
      display: contents;
      height: 9.25rem;

      &-link:hover {
        text-decoration: none;
      }
    }

    .images__point-title {
      margin-bottom: 0;

      h2 {
        @include h5;
      }
    }

    .images__point-description {
      margin-top: 0;
    }

    .images__point-header {
      @include h6;

      width: 13.75rem;

      &:hover {
        text-decoration: underline;
        color: $burgundy;
        cursor: pointer;
      }
    }

    .images__header {
      font-size: 1rem;
      text-align: left;
    }

    .images__header-wrapper {
      padding: 0 1.75rem;

      .images__header {
        @include h5;
        @include custom-breakpoint-styles(
          grid-column,
          span 4,
          span 8,
          span 12,
        );

        padding: 0.5rem 0;
      }
    }
  }

  .navigation-position-drawer__featured {
    width: 30%;

    .collection-title {
      padding-top: 0;

      h3 {
        font-size: 1rem;
      }
    }

    .collection-carousel {
      overflow-x: hidden;
    }
  }

  .navigation-position-drawer__content__styled-columns .navigation-dropdown {
    @extend .navigation-position-drawer__children--column;

    > .links-section-item {
      margin: 0 0 0.75rem;
      padding: 0;
    }
  }
}

.navigation-position--hover-navigation {
  .navigation-position-drawer {
    max-height: none !important;
    top: 100% !important;
    background-color: $white;
    visibility: hidden;
    border-top: 1px solid #d1d0d0;
    // overlay alternative
    box-shadow: 0 75em 0 75em rgb(178 178 180 / 80%);

    &.active {
      opacity: 0;
      transition: opacity 0.3s ease-out;
    }
  }

  .navigation-position-button {
    padding: 1rem 0;
  }

  &.open,
  &:hover {
    .navigation-position-button {
      position: relative;

      &::after {
        @include respond($break-xl) {
          content: "";
          position: absolute;
          z-index: 12;
          height: 1px;
          background-color: $charcoal;
          bottom: -1px;
          width: 100%;
          display: block;
        }
      }
    }

    .navigation-position-drawer {
      visibility: visible;

      &__content {
        opacity: 1;
      }
    }

    .icon-nav-arrow {
      transform: rotate(180deg);
      transition: transform 0.25s;
    }
  }
}

.header-cart-wrapper {
  position: relative;

  .header-cart-num {
    display: flex;
    font-weight: 600;
    left: 0.375rem;
    top: -0.125rem;
    justify-content: center;
    align-items: center;
    font-size: 0.5rem;
    width: 0.8rem;
    height: 0.8rem;
    position: absolute;
    border-radius: 50%;
    color: $white;
    background-color: $charcoal;
  }
}

.header-menu-mobile .navigation {
  @include respond-below($break-xl) {
    display: block;
  }

  @include gta-burrow;

  display: none;

  .icon-nav-arrow.expandedMobileSection {
    display: none;
  }

  .navigation-position__break {
    height: 1px;
    background-color: $charcoal25;
    margin: 0 1.25rem;
  }

  .navigation-position-drawer {
    padding: 0 1.25rem;
    padding-right: 0;

    .navigation-position-drawer__content {
      overflow: hidden;
      max-height: 0;
      transition: max-height 0.3s ease-out;

      .navigation-position-drawer__content__featured {
        display: grid;
        grid-auto-rows: 1fr;
        grid-auto-flow: column;
        grid-column-gap: 1rem;
        grid-auto-columns: 13rem;
        overflow: auto;
      }

      .images {
        padding: 0.75rem 0;
        overflow: auto;

        &.has-neighbors {
          padding: 1rem 1.25rem;
        }
      }

      .images__points {
        padding: 0;
        row-gap: 0;
        width: auto;
        display: grid;
        grid-auto-columns: 13rem;
        grid-template-rows: repeat(3, auto);
        grid-template-columns: unset;
        grid-auto-flow: column;

        &-wrapper {
          overflow: unset;
        }

        .images__point {
          display: contents;

          &-title h2 {
            @include h4;
          }

          &-description,
          &-title {
            width: 110%;
          }
        }
      }

      .images__header-wrapper {
        display: none;

        .images__header {
          text-align: left;
        }
      }

      .images__inner-wrapper {
        padding-bottom: 1rem;
      }

      .navigation-position-drawer__content__break {
        height: 1px;
        background-color: $charcoal25;
      }
    }

    &.active,
    &.expandedMobileSection {
      .navigation-position-drawer__content {
        max-height: 9999px;
      }
    }
  }

  .navigation-dropdown {
    padding: 0 0 1.25;
  }

  .navigation-dropdown__content {
    overflow: hidden;
    max-height: 0;
    padding: 0 1.25rem;
    padding-right: 0;
    transition: max-height 0.3s ease-out;

    .links-section-item {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }

    &.active {
      max-height: 600px;
    }
  }
}

.header-underlay {
  position: sticky;
  height: $header-total-mobile;

  @include respond($break-phone) {
    height: $header-total-desktop;
  }
}

.header-underlay-with-banner {
  height: $header-total-mobile + $header-digioh-top-banner;

  @include respond($break-phone) {
    height: $header-total-desktop + $header-digioh-top-banner;
  }
}

.header-navigation-content {
  display: block;
  width: 100%;

  a {
    color: inherit;
  }

  .call-to-action {
    @include badge-text;
  }
}

.header-nav-bx-utility-wrapper {
  max-height: $header-utility-navigation-mobile;
  position: relative;
  z-index: 3;

  @include respond($break-phone) {
    max-height: $header-utility-navigation-mobile + $bx-banner-height;
  }

  .header-nav__bx {
    position: relative;
    z-index: 1;
    width: 100%;
  }
}

.header-navigation-container {
  position: relative;

  &.open {
    z-index: 3;
  }

  &.with-popout {
    z-index: 2;
  }

  &.search-open {
    z-index: 3;
  }

  @include transition(all 0.15s);
  @include transform(translate(0, 0));

  &--unpinned {
    @include transform(translateY(-2rem));
  }

  .shopping-container {
    @include respond($break-xl) {
      @include transition(all 0.15s);

      left: 0;
      position: absolute;
      display: none;
      width: 100%;
      background-color: white;
      max-height: 0;
      z-index: 8;

      &.active {
        height: 15rem;
        display: block;
        max-height: 15rem;
        border-top: 1px solid $charcoal25;
      }
    }
  }
}

.header-navigation-portal {
  position: relative;
  z-index: -1;

  &--hidden {
    @include respond-below($break-xl) {
      display: none;
    }
  }
}

.header-menu-mobile {
  padding: 0;
  width: 90%;
  z-index: 100;
  height: calc(100vh - #{$header-height-mobile});
  left: 0;
  position: fixed;
  transition: all 0.25s;
  background-color: $white;
  transform: translate(-100%, 0);
  font-size: 1.1rem;

  @include respond($break-phone) {
    width: 50%;
  }

  @include respond($break-xl) {
    display: none;
  }

  .call-to-action {
    @include badge-text;

    margin-left: 1rem;
  }

  &.is-open {
    transform: translate(0, 0);
  }

  &--unpinned {
    @include transform(translate(-100%, -2rem));

    &.is-open {
      transform: translate(0, -2rem);

      &.bx-active {
        transform: translate(0, 0);
      }
    }
  }

  .icon-close {
    top: 1.5rem;
    right: 0;
    color: $charcoal;
    font-weight: 800;
    cursor: pointer;
    transition: all 0.25s;
    font-size: 0.5rem;
    padding-right: 0.4rem;

    .header-menu-desktop & {
      display: none;

      @include respond-below($break-xl) {
        display: initial;
      }
    }
  }

  &-scroll {
    overflow: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .accounts-section {
    display: flex;
    justify-self: flex-end;
    background-color: $sandstone;
    margin-top: auto;
    padding-bottom: 20rem;
    padding-top: 1rem;

    .mobile-cart-link {
      display: none;
    }

    .mobile-login-link,
    .mobile-link-span {
      transition: 0.4s;
      display: inherit;
    }

    i {
      display: none;
    }

    @include respond-below($break-xl) {
      flex-direction: column;
    }

    @include gta-burrow;
  }

  .account {
    i {
      display: none;
    }
  }

  .utility-navigation-items {
    display: none;

    @include respond-below($break-phone) {
      display: block;

      .links-section-item {
        color: $charcoal;
      }

      .graphcms-image-outer-wrapper {
        display: none;
      }

      i {
        display: block;
      }
    }
  }

  .navigation-position-drawer__featured {
    .collection-title {
      h3 {
        font-size: 0.9rem;
        color: rgba($charcoal, 0.75);
      }
    }
  }
}

// Fix for rendering issues with banner
@mixin header-menu-mobile-height($mobile, $tablet, $desktop) {
  @if $mobile {
    height: calc(100vh - #{$mobile});
    top: $mobile;
  }

  @include respond($break-phone) {
    height: calc(100vh - #{$tablet});
    top: $tablet;
  }

  @include respond($break-medium) {
    height: calc(100vh - #{$desktop});
    top: $desktop;
  }
}

/* stylelint-disable-next-line no-duplicate-selectors */
.header-menu-mobile {
  top: $header-height-mobile;

  @include header-menu-mobile-height(
    null,
    $header-height-mobile,
    $header-height-desktop
  );
}

@function add-banner-height($height) {
  @return $height + $header-unbounce-top-banner;
}

.header-navigation-bar--has-banner {
  + .header-menu-mobile {
    @include header-menu-mobile-height(
      add-banner-height($header-height-mobile),
      add-banner-height($header-height-mobile),
      add-banner-height($header-height-desktop)
    );
  }
}

.header-navigation-bar--pinned {
  $header-total-with-utility-mobile: $header-height-mobile + $header-utility-navigation-mobile;
  $header-total-with-utility-tablet: $header-height-mobile + $header-utility-navigation-desktop;
  $header-total-with-utility-desktop: $header-height-desktop + $header-utility-navigation-desktop;

  + .header-menu-mobile {
    @include header-menu-mobile-height(
      $header-total-with-utility-mobile,
      $header-total-with-utility-tablet,
      $header-total-with-utility-desktop
    );
  }

  &.header-navigation-bar--has-banner {
    + .header-menu-mobile {
      @include header-menu-mobile-height(
        add-banner-height($header-total-with-utility-mobile),
        add-banner-height($header-total-with-utility-tablet),
        add-banner-height($header-total-with-utility-desktop)
      );
    }
  }
}

:global(.ub-emb-bar) {
  .ub-emb-bar-frame {
    position: relative;
  }
}

.header-menu-desktop,
.header-utility-navigation {
  .links-section {
    @include respond-below($break-xl) {
      display: none;
    }

    .utility-nav {
      font-weight: 400;

      @include respond($break-xl) {
        display: none;
      }
    }

    display: inherit;
  }

  .links-section-title {
    @include respond($break-xl) {
      display: none;
    }

    &.categories {
      @include book;

      font-size: 0.8125rem;
      color: $charcoal50;
      display: block;
      padding: 0.625rem 1.25rem;

      &.mobile {
        @include respond($break-xl) {
          display: none;
        }
      }
    }
  }
}

.navigation-position-drawer__children {
  display: contents;

  &--column {
    display: flex;
    flex-direction: column;
    width: 16rem;
    margin-top: -0.5rem;
  }

  @include respond-below($break-xl) {
    .navigation-dropdown {
      .links-section-item {
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        padding-right: 1.25rem;
      }

      &__content {
        .links-section-item {
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
        }
      }
    }
  }
}

.locale-dropdown {
  position: relative;

  &__button {
    display: flex;
    align-items: center;
    min-height: 0;
    height: 2rem;
    padding-right: 0.9375rem;
    background: none;
    color: inherit;
    font: inherit;
    white-space: nowrap;
    cursor: pointer;

    @include h5;

    @media (hover: hover) {
      &:hover {
        color: $burgundy;
        cursor: pointer;
      }
    }

    @include respond($break-xl) {
      margin-right: 1rem;
      padding-right: 1rem;
    }
  }

  &__section {
    display: none;
    position: absolute;
    z-index: 11;
    top: 0;
    right: 0;
    overflow: hidden;
    flex-direction: column;
    align-items: flex-end;
    width: 8rem;
    background-color: $sandstone;
    text-align: center;

    @include respond($break-xl) {
      width: 10rem;
    }

    &.active {
      display: flex;
    }
  }

  &__option {
    display: flex;
    align-items: center;
    height: 2rem;
    padding: 0.25rem;
    padding-right: 0.9375rem;

    &:not(:last-child) {
      &:hover {
        color: $black;
      }
    }

    &:last-child {
      padding-bottom: 0.5rem;
    }

    &:hover {
      color: $burgundy;
      cursor: pointer;
    }

    @include respond($break-xl) {
      padding-right: 2rem;
    }

    &__name-spacer {
      transform: translateX(-0.5em);
    }
  }

  &__countryLogo {
    width: 14.6px;
    height: 14.6px;
    margin: 0 0 0 0.3em;
    padding: 0.1px 0.2px 0.6px 0.3px;

    &__US {
      @include responsive-image("../../assets/nav/US.png");
      @include object-fill-image(100%);

      width: 14.6px;
      height: 14.6px;
    }

    &__CA {
      @include responsive-image("../../assets/nav/CA.png");
      @include object-fill-image(100%);

      width: 14.6px;
      height: 14.6px;
    }
  }
}
