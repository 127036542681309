@import '../partials/variables';
@import '../partials/mixins';

$horizontal-padding-mobile: 1.5625rem;
$horizontal-padding-desktop: 2.5rem;

.filters {
  @include transform(translateX(100%));
  @include transition(all 0.25s);
  @include transparent-scrollbar;

  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: scroll;
  background: $white;
  margin-left: auto;
  max-width: 20.4375rem;
  pointer-events: none;
  position: fixed;
  top: $header-height-mobile;
  bottom: 0;
  right: 0;
  z-index: 100;

  @include respond($break-medium) {
    top: $header-height-desktop;
  }

  @include respond($break-xl) {
    top: 0;
  }

  &.with-utility-nav {
    top: calc(#{$header-height-mobile} + #{$header-utility-navigation-mobile});

    @include respond($break-medium) {
      top: calc(#{$header-height-desktop} + #{$header-utility-navigation-desktop});
    }

    @include respond($break-xl) {
      top: 0;
    }
  }

  &.is-open {
    @include transform(translateX(0));

    pointer-events: all;
  }

  &__form {
    width: 100%;
    max-width: 20.4375rem;
  }

  &__header {
    @include flex-column;

    padding-bottom: 0.25rem;
    border-bottom: 1px solid $charcoal25;
    margin: 0;

    &__inner-wrapper {
      display: flex;
      justify-content: space-between;
      padding: 1.125rem $horizontal-padding-mobile;

      span {
        display: flex;
        align-items: baseline;

        h4 {
          margin-right: 0.375rem;
        }

        span {
          @include body-large;
        }
      }
    }

    .icon.icon-close {
      cursor: pointer;
      background: none;
      padding: 0;

      &::before {
        font-size: 1.0625rem;
        color: $charcoal;
      }
    }
  }

  &__wrapper {
    min-height: 25rem;
    position: relative;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 1fr auto;
    height: 100%;

    /* mobile viewport bug fix */
    padding-top: 0;

    @include respond-vertical-below(500px) {
      height: auto;
    }

    &-three-rows {
      grid-template-rows: auto 1fr auto;
    }

    &-spread {
      justify-content: space-between;
    }

    &-top {
      justify-content: start;
    }

    &__inner {
      .checkbox {
        margin: 0;

        label {
          @include body-large;

          white-space: nowrap;
        }
      }

      .filter-section-wrapper {
        border-bottom: 1px solid $charcoal25;
        padding: 1.5625rem;

        &__subheader {
          @include label-small;

          margin-top: 1rem;
          margin-bottom: 1rem;
          color: $charcoal75;
        }

        &.collection {
          padding-left: 0;
          padding-right: 0;

          .filter-section-wrapper__header {
            padding-left: $horizontal-padding-mobile;
            padding-right: $horizontal-padding-mobile;
          }

          .collection-pickers {
            display: grid;

            .collection-picker {
              padding-right: $horizontal-padding-mobile;
              padding-bottom: 1rem;
              padding-top: 1rem;
              border-bottom: 1px solid $charcoal25;

              &-wrapper-inner {
                &__label {
                  @include body-large;
                }
              }

              &:last-child {
                border-bottom: 0;
                padding-bottom: 0;
              }
            }
          }
        }

        .color-option {
          display: flex;
          align-items: center;

          label > span {
            @include body-large;
          }
        }

        .material-options {
          display: grid;
          grid-auto-flow: column;
          grid-gap: 0.75rem;
          justify-content: flex-start;
          padding-bottom: 0.8125rem;
          border-bottom: 1px solid $charcoal25;
        }

        &:last-child {
          border-bottom: 0;
        }
      }

      @include respond($break-xl) {
        .filter-section-wrapper__subheader {
          font-size: 0.563rem;
        }

        .checkbox {
          label {
            font-size: 0.688rem;
            line-height: 1.455rem;
            letter-spacing: 0;
          }

          span {
            min-width: 1rem;
            min-height: 1rem;
            max-width: 1rem;
            max-height: 1rem;

            svg {
              width: 0.875rem;
              left: 2px;
              top: 2px;
            }
          }
        }

        .pill-picker-radio span {
          font-size: 0.563rem;
          line-height: 1.125rem;
        }

        .filter-section-wrapper {
          .color-option {
            label > span {
              font-size: 0.688rem;
              padding-left: 0.313rem;
            }

            .color-picker-radio {
              .color-picker-color {
                margin-right: 0.313rem;
              }
            }
          }

          &.collection {
            .collection-pickers {
              .collection-picker-wrapper-inner__label {
                font-size: 0.688rem;
              }

              .collection-picker-wrapper-inner__description {
                font-size: 0.563rem;
              }
            }
          }
        }
      }
    }
  }

  &__items {
    @include transparent-scrollbar;

    height: auto;
    overflow-y: scroll;
  }

  &-footer {
    &-shadow {
      box-shadow: 0 -8px 24px 0 rgba(178, 178, 180, 0.25);
    }

    &-inner-wrapper {
      padding: 1rem $horizontal-padding-mobile;

      .filter-apply {
        @include btn-hover-translate;

        padding-top: 0.375rem;
        padding-bottom: 0.375rem;

        &__text {
          @include h5;
          @include gta-burrow;

          line-height: 1.45;
          display: grid;
          grid-template-columns: 1fr;

          &__results {
            @include label-small;
            @include book;

            line-height: 1.45;
            text-transform: none;
          }
        }

        &.disabled {
          transform: translateY(-0.225rem);
          cursor: not-allowed;
          opacity: 1;
          color: rgba($charcoal, 0.25);
        }
      }
    }
  }
}

.collections-with-filter {
  display: flex;
  justify-content: center;
  max-width: $full-width;
  margin: 0 auto;

  .column-collections {
    flex: 1 0;
  }

  .filters {
    &__items {
      overflow-y: auto;
    }

    &__wrapper__inner {
      .filter-section-wrapper {
        padding: 1.5625rem 1.25rem;
      }
    }
  }
}
