@import '../partials/variables';
@import '../partials/mixins';

.plain-text-wrapper {
  &.full-width {
    max-width: $full-width;
  }

  &.swatch-description {
    max-width: $full-width;
    margin: 0 auto 1rem auto;

    @include respond($break-tablet) {
      margin-bottom: 2rem;
    }
  }

  a {
    color: $charcoal;

    :hover {
      text-decoration: underline;
    }
  }

  .card {
    &__point {
      &-copy {
        @include body-extra-large;

        text-align: left;
      }
    }

    &__body {
      @include custom-breakpoint-styles(
        margin-left,
        $horizontal-page-padding-phone,
        $horizontal-page-padding-tablet,
        $horizontal-page-padding-desktop,
      );
      @include custom-breakpoint-styles(
        margin-right,
        $horizontal-page-padding-phone,
        $horizontal-page-padding-tablet,
        $horizontal-page-padding-desktop,
      );

      max-width: 100%;

      &.left {
        text-align: left;
      }

      &.center {
        text-align: center;
      }

      &.right {
        text-align: right;
      }

      &.top {
        margin-top: 5rem;
        margin-bottom: 5rem;
      }

      &.bottom {
        margin-top: auto;
        margin-bottom: 5rem;
      }
    }

    &__copy {
      margin-top: 0;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin-bottom: revert;
      }

      p {
        margin-bottom: 1.25rem;
      }

      blockquote {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        margin: 3.125rem auto;
        text-align: center;

        p {
          @include h3;

          grid-column: 1 / span 12;

          @include respond($break-phone) {
            grid-column: 2 / span 10;
          }

          @include respond($break-tablet) {
            grid-column: 3 / span 8;
          }
        }

        ::before,
        ::after {
          @include h1;

          color: $terracotta;
        }

        ::before {
          content: "“";
          margin-right: 0.5rem;
        }

        ::after {
          content: "„";
          margin-left: 0.5rem;
          vertical-align: sub;
        }
      }

      p:last-of-type {
        margin-bottom: 0;
      }
    }

    &__header + div {
      margin-top: 1rem;
    }
  }
}
