@import '../partials/variables';
@import '../partials/mixins';

@keyframes bounce-in {
  0% {
    transform: scale(0.2);
  }

  80% {
    transform: scale(1.15);
  }

  100% {
    transform: scale(1);
  }
}

.shopping-space {
  $this: &;

  position: relative;

  @include respond($break-large) {
    margin-right: 2.0625rem;
  }

  &__cover {
    @include cover-asset-container;

    padding-bottom: 125%;

    @include respond($break-phone) {
      padding-bottom: 50%;
    }
  }

  &__pins-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  &__pin-anchor {
    position: absolute;
    z-index: 0;
    display: none;
    width: 0;
    height: 0;
    overflow: visible;
    border: 1.5rem solid transparent;
    border-radius: 1.5rem;
    cursor: pointer;
    transform: translate(-50%, -50%);

    &--visible {
      display: block;
      animation: bounce-in 0.25s ease forwards;
    }

    &--open,
    &:hover {
      --pin-circle-background-color: #{$charcoal};
    }

    &--open {
      --pin-triangle-display: block;
      --pin-content-anchor-display: block;

      z-index: 1;
    }

    &:not(#{&}--open) {
      --pin-circle-background-color: transparent;
    }
  }

  --circle-radius: 0.5rem;
  --inner-circle-radius: 0.375rem;
  --inner-circle-border-width: 0.0625rem;
  --content-shift: 0.75rem;

  @include respond($break-phone) {
    --circle-radius: 0.5625rem;
    --inner-circle-radius: 0.4375rem;
  }

  &__pin-circle {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
    width: calc(var(--circle-radius) * 2);
    height: calc(var(--circle-radius) * 2);
    border-radius: var(--circle-radius);
    background: $white;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: calc(var(--circle-radius) - var(--inner-circle-radius));
      left: calc(var(--circle-radius) - var(--inner-circle-radius));
      width: calc((var(--inner-circle-radius) - var(--inner-circle-border-width)) * 2);
      height: calc((var(--inner-circle-radius) - var(--inner-circle-border-width)) * 2);
      border: var(--inner-circle-border-width) solid $charcoal;
      border-radius: var(--inner-circle-radius);
      background-color: var(--pin-circle-background-color, transparent);
    }
  }

  &__pin-triangle {
    position: absolute;
    display: var(--pin-triangle-display, none);
    top: 0;
    left: 0;
    width: 0.75rem;
    height: 0.75rem;
    background: $white;
    transform: translate(-50%, calc(-50% - calc(var(--circle-radius) + var(--content-shift)))) rotate(45deg);

    &--left {
      transform: translate(calc(-50% - calc(var(--circle-radius) + var(--content-shift))), -50%) rotate(-45deg);
    }

    &--right {
      transform: translate(calc(-50% + calc(var(--circle-radius) + var(--content-shift))), -50%) rotate(135deg);
    }
  }

  &__pin-content-anchor {
    display: var(--pin-content-anchor-display, none);
    position: absolute;
    top: calc(-1 * calc(var(--circle-radius) + var(--content-shift)));
    left: 0;
    width: 0;
    height: 0;

    &--left {
      top: 0;
      left: calc(-1 * calc(var(--circle-radius) + var(--content-shift)));
    }

    &--right {
      top: 0;
      left: calc(calc(var(--circle-radius) + var(--content-shift)));
    }
  }

  &__pin-content {
    width: 8rem;
    padding: 0.5rem;
    background: $white;
    transform: translate(-50%, -100%);

    @include respond($break-phone) {
      width: 10rem;
      padding: 0.75rem;

      .product-card__details-copy {
        font-size: 0.75rem;
      }
    }

    &--left {
      transform: translate(-100%, -50%);
    }

    &--right {
      transform: translate(0, -50%);
    }
  }

  &__error-message {
    text-align: center;
  }

  .product-card__details-copy {
    &:not(:last-child) {
      margin-bottom: 0.125rem;
    }

    &--heavy {
      @include heavy;
    }

    &-sale {
      display: block;
    }
  }
}
