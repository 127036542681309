@import "../partials/mixins";
@import "../partials/variables";

.login-create {
  &-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    @include respond($break-medium) {
      flex-direction: row;
    }
  }

  &-component {
    width: 100%;
    padding: 10% 0;

    @include transition(ease-in 0.5s);

    @include respond-below($break-medium) {
      padding: 1rem 0 2rem;
    }

    @include respond($break-medium) {
      width: 50%;
    }

    .auth {
      &-box {
        &-options {
          padding-top: 1.625rem;
        }
      }
    }

    &.active-component {
      background-color: $sandstone5;

      @include respond-below($break-medium) {
        .auth {
          min-height: auto;
          height: auto;

          &-form {
            opacity: 1;
          }
        }
      }
    }

    &.inactive-component {
      height: 7.6875rem;
      overflow: hidden;

      @include respond($break-medium) {
        height: auto;
      }

      h2 {
        @include respond-below($break-medium) {
          text-decoration: underline;
          text-underline-offset: 0.125rem;
          text-decoration-color: $charcoal;
        }
      }

      .auth {
        &-box {
          @include respond-below($break-medium) {
            margin: 0 auto;
          }
        }

        form {
          opacity: 0;
          overflow: hidden;

          @include respond($break-medium) {
            overflow: initial;
            opacity: 1;
          }
        }
      }
    }
  }
}
