@import '../partials/mixins';
@import '../partials/variables';

.swatch-favorites {
  position: sticky;
  width: 100%;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  background: $white;
  box-shadow: 0 -0.563rem 1rem 0 rgba(56, 54, 51, 0.16);
  padding: 0.75rem 1.5rem 2rem 1.5rem;
  z-index: 2;

  @include respond($break-phone) {
    padding-top: 1rem;
    padding-bottom: 3rem;
  }

  @include respond($break-large) {
    padding-bottom: 3rem;
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    @include respond($break-tablet) {
      padding-bottom: 3rem;
      flex-direction: row;
      align-items: center;
      gap: 1rem;
    }

    > span {
      color: #000;
      display: inline-block;

      @include h5;
      @include gta-burrow;
    }
  }

  &__items-list-wrapper {
    display: flex;
    gap: 0.5rem;

    @include respond($break-tablet) {
      gap: 2rem;
    }
  }

  &__items-list {
    height: 2rem;
    display: flex;
    gap: 0.5rem;

    @include respond($break-phone) {
      height: 2.5rem;
    }

    @include respond($break-tablet) {
      gap: 1rem;
    }

    @include respond($break-large) {
      height: 3.125rem;
    }
  }

  &__item,
  &__item--empty {
    @include square(2rem);

    position: relative;
    display: inline-block;
    border: solid 1px $light-grayish-blue;
    background-color: $white;
    padding: 1px;

    @include respond($break-phone) {
      @include square(2.5rem);

      padding: 0.125rem;
    }

    @include respond($break-large) {
      @include square(3rem);

      padding: 3px;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__item:hover {
    border-color: $charcoal;
  }

  &__item-remove-btn {
    position: absolute;
    top: -25%;
    right: -13.333333%;
    padding: 0;
    font-size: 0.75rem;
    color: $charcoal;
    background-color: $white;
    border-radius: 50%;
    box-shadow: 0 0.125rem 0.125rem 0 rgba(0, 0, 0, 0.25);
    cursor: pointer;

    @include respond($break-phone) {
      top: -0.5rem;
      right: -0.5rem;
      font-size: 0.9375rem;
      box-shadow: none;
      border: solid 1px $light-grayish-blue;
    }
  }

  &__item-details {
    @include book;

    white-space: nowrap;
    position: relative;
    left: -0.188rem;
    top: 0.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    & p {
      @include body-medium;
    }

    @include respond($break-tablet) {
      flex-direction: column;
      align-items: flex-start;
    }

    p:not(:first-child):not(:last-child) {
      @include respond($break-tablet) {
        display: none;
      }
    }
  }

  .btn-add-favorites-to-cart {
    @include respond-below($break-phone) {
      padding-left: 1.125rem;
      padding-right: 1.125rem;
    }
  }
}
