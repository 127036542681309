@import '../partials/variables';
@import '../partials/mixins';

.selected-reviews {
  width: 100%;
  background-color: $sandstone;

  &__container {
    @include grid-container;
    @include components-element-max-width;
    @include custom-breakpoint-styles(
      column-gap, 0, 0, 0
    );
    grid-template-columns: repeat(6, minmax(0, 1fr)); // modifying defaults for mobile only
    grid-template-rows: repeat(2, auto);

    @include respond($break-phone) {
      grid-template-rows: repeat(1, auto);
    }
  }

  &__reviews-wrapper {
    position: relative;
    grid-column: 2 / span 4;
    grid-row: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 21.5rem;
    margin: auto 0;
    padding: 3.75rem 0 .75rem;

    @include respond($break-phone) {
      grid-column: 1 / span 4;
      height: 50vw;
      padding: 3.75rem 1.375rem;
    }

    @include respond($break-large) {
      grid-column: 2 / span 4;
      height: 32rem;
      padding: 2.5rem 0;
    }
  }

  &__reviews {
    flex: 1 0 auto;
    position: relative;
  }
  &__review {
    position: absolute;
    width: 100%;
    height: 100%;
    min-height: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 0;
    opacity: 0;
    transition: opacity .25s ease;

    @include respond($break-phone) {
      max-width: 20rem;
    }

    &--active {
      z-index: 1;
      opacity: 1;
      transition: opacity .25s ease .2s;
    }

    &-header {
      color: $charcoal;
      letter-spacing: .7px;
    }

    &-citation {
      color: $charcoal;
      @include respond($break-phone) {
        font-size: 1.125rem;
      }
      @include respond($break-large) {
        font-size: 1.875rem;
      }

      & {
        quotes: "“" "”";
      }
      &::before {
        content: open-quote;
      }
      &::after {
        content: close-quote;
      }

    }

    &-links {
      color: $charcoal;
      a {
        @include heavy;
        color: $charcoal;
      }
    }

    &-header, &-citation, &-links {
      text-align: left;
      width: 100%;
      max-width: 25rem;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__pagination {
    flex: 0 0 auto;
    width: 100%;
    max-width: 25rem;
    margin: auto;
    display: flex;
    justify-content: center;
    padding-top: 3rem;

    @include respond($break-phone) {
      padding-top: 3.75rem;
      justify-content: flex-start;
    }

    &-dots {
      display: flex;

      @include respond($break-phone) {
        display: none;
      }
    }
    &-dot {
      width: .5rem;
      height: .5rem;
      border-radius: .25rem;
      background: $sandstone200;
      transition: background-color .3s ease;
      &:not(:first-child) {
        margin-left: .625rem;
      }
      &--active {
        background: $sandstone500;
      }
    }

    &-arrows {
      display: none;
      @include respond($break-phone) {
        display: flex;
      }
    }
    &-arrow {
      cursor: pointer;
      &:not(:first-child) {
        margin-left: 2rem;
      }
      &::before {
        color: $charcoal;
        font-size: 1rem;
      }
    }
  }

  &__slider-container {
    grid-column: 1 / span 6;
    grid-row: 2;
    display: block;
    position: relative;
    width: 100%;
    padding-bottom: 75%;

    @include respond($break-phone) {
      grid-row: 1;
      grid-column: 5 / span 4;
      padding-bottom: 100%;

    }

    @include respond($break-large) {
      grid-column: 7 / span 6;
      padding-bottom: 100%;
    }
  }

  &__slider {
    @include cover-gallery-container;
    
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
