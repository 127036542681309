@import '../partials/variables';
@import '../partials/mixins';

.countdown {
  $this: &;
  $leftAlign: --left-align;
  $rightAlign: --right-align;
  $asset: #{&}__asset;
  $content: #{&}__content;

  @include grid-container;
  @include components-element-max-width();
  @include custom-breakpoint-styles(column-gap, 0, 0, 0);
  
  #{$content} {
    @include grid-container;
    @include custom-breakpoint-styles(
      padding,
      $vertical-page-padding-phone $horizontal-page-padding-phone,
      0 3rem,
      0,
    );
    grid-row: 1;
    grid-column: 1 / -1;

    .card__body {
      text-align: center;
      grid-column: 1 / -1;
      margin: auto 0;
      max-width: unset;
      @include respond($break-phone) {
        grid-column: 1 / span 8;
      }
      @include respond($break-large) {
        grid-column: 3 / span 8;
      }
    }

    .countdown-timer {
      @include custom-breakpoint-styles(margin, 1rem 0 0, 2rem 0 1rem, 3rem 0 2.75rem);
    }

    .card__link {
      @include respond($break-large) {
        padding-left: 5rem;
        padding-right: 5rem;
      }
    }
  }

  #{$asset} {
    grid-row: 2;
    grid-column: 1 / -1;
    @include respond($break-phone) {
      grid-row: 1;
    }

    .card__asset,
    .card__assets {
      @include square-aspect-ratio;
    }
  }

  &#{$rightAlign} {
    #{$asset} {
      @include respond($break-phone) {
        grid-column: 1 / span 4;
      }
      @include respond($break-large) {
        grid-column: 1 / span 6;
      }
    }
    #{$content} {
      @include respond($break-phone) {
        grid-row: 1;
        grid-column: 5 / span 4;
      }
      @include respond($break-large) {
        grid-column: 7 / span 6;
      }

      .card__body {
        @include respond($break-large) {
          padding-right: 1rem;
        }
      }
    }
  }

  &#{$leftAlign} {
    #{$asset} {
      @include respond($break-phone) {
        grid-column: 5 / span 4;
      }
      @include respond($break-large) {
        grid-column: 7 / span 6;
      }
    }
    #{$content} {
      @include respond($break-phone) {
        grid-row: 1;
        grid-column: 1 / span 4;
      }
      @include respond($break-large) {
        grid-column: 1 / span 6;
      }

      .card__body {
        @include respond($break-large) {
          padding-left: 1rem;
        }
      }
    }
  }

}
