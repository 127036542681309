@use 'sass:math';
@import '../partials/variables';
@import '../partials/mixins';

$top-section-beckground: #514f4d;
$accordion-border-color: rgba(246, 238, 227, 0.1);
$bottom-section-text-color: $sandstone;

.footer {
  $footer: &;

  background: $charcoal;
  color: $sandstone;
  overflow: hidden;
  @include book;

  &__container {
    @include grid-container;
    @include custom-breakpoint-styles(padding, 0, 0, 0);
    @include custom-breakpoint-styles(column-gap, 0, 0, 0);
    grid-template-rows: repeat(3, auto);
  }

  &__section {
    grid-column: 1 / 5;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 1.25rem;
    row-gap: 1.25rem;
    font-size: .875rem;

    @include respond($break-phone) {
      grid-column: 1 / 13;
      grid-template-columns: repeat(4, minmax(0, 1fr));
      column-gap: 2rem;
      row-gap: 2rem;

    }

    &--top {
      grid-row: 1;
    }

    &--middle {
      grid-row: 2;
    }

    &--bottom {
      grid-row: 3;
    }
  }

  &__nav-sections {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 1.25rem;
    row-gap: 1.25rem;
    font-size: .875rem;

    @include respond($break-phone) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
      column-gap: 2rem;
      row-gap: 2rem;
    }
  }

  &__nav-section {
    display: inline-block;
  }

  &__nav-items {
    padding-left: 0 !important; // overwite accordion__item-body styles
  }

  &__nav-item {
    color: $sandstone;
    list-style: none !important; // overwite accordion__item-body styles

    &--title {
      color: $sandstone;
    }
  }

  &__nav-item-link,
  &__nav-item .sms {
    font-size: .8125rem;
    line-height: 1.43;
    letter-spacing: 0.3px;
    color: $sandstone;
    @include respond($break-phone) {
      font-size: .875rem;
    }
  }

  &__social {
    display: flex;
  }

  &__section--top {
    @include custom-breakpoint-styles(
      padding,
      2rem $horizontal-page-padding-phone,
      2.25rem $horizontal-page-padding-tablet,
      3rem $horizontal-page-padding-desktop,
    );
    background-color: $top-section-beckground;

    #{$footer}__social {
      grid-column: 1 / span 4;
      grid-row: 1;
      display: flex;
      flex-direction: column;
      align-items: center;

      &-header {
        display: none;
        margin-bottom: 0.5rem;
        opacity: 0.65;
        @include book;
        font-size: 0.875rem;
        line-height: 1.43;
        letter-spacing: 0.3px;
        color: $sandstone;
      }

      #{$footer}__nav-items {
        display: flex;
        flex-direction: row;
      }

      #{$footer}__nav-item {
        font-size: 1.375rem;
        &:not(:last-child) {
          margin-right: 1.375rem;
        }
      }

      #{$footer}__nav-item-link {
        font-size: 1.375rem;
      }

    }

    .newsletter {
      grid-column: 1 / span 4;
      grid-row: 2;
    }

    @include respond($break-phone) {
      #{$footer}__social {
        grid-column: 4 / span 1;
        grid-row: 1;
        justify-content: center;
        align-items: flex-end;
      }

      .newsletter {
        grid-column: 1 / span 3;
        grid-row: 1;
      }
    }
  }

  &__section--top-with-showroom {
    #{$footer}__social {
      grid-column: 1 / span 2;
      grid-row: 1 / span 1;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .newsletter {
      grid-column: 1 / span 2;
      grid-row: 2 / span 1;
    }

    #{$footer}__showroom {
      grid-column: 1 / span 2;
      grid-row: 3 / span 1;
    }

    @include respond($break-phone) {
      #{$footer}__social {
        grid-column: 1 / span 4;
        grid-row: 1 / span 1;
      }

      .newsletter {
        grid-column: 1 / span 4;
        grid-row: 2 / span 1;
      }

      #{$footer}__showroom {
        grid-column: 1 / span 4;
        grid-row: 3 / span 1;
      }
    }
    @include respond($break-tablet) {
      .newsletter {
        grid-column: 1 / span 2;
        grid-row: 1 / span 1;
      }

      #{$footer}__social {
        grid-column: 1 / span 2;
        grid-row: 2 / span 1;
        justify-content: flex-end;
        align-items: flex-start;
        &-header {
          display: block;
        }
      }

      #{$footer}__showroom {
        grid-column: 3 / span 2;
        grid-row: 1 / span 2;
      }
    }
  }

  &__showroom {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1.25rem;
    row-gap: 1.25rem;
    &-cover {
      grid-column: 1 / span 1;
      height: 0;
      padding-bottom: percentage(math.div(210, 287));
      position: relative;
      display: none;
      &-asset {
        position: absolute;
        width: 100%;
        height: 100%;
      }
    }
    &-info {
      grid-column: 1 / span 2;
      display: flex;
      flex-direction: column;
      &-header {
        @include book;
        font-size: .875rem;
        line-height: 1.43;
        letter-spacing: 0.3px;
        color: rgba($sandstone, 0.5);
        margin-bottom: .75rem;
      }
      &-content {
        margin-top: .25rem;
        margin-bottom: auto;
        @include book;
        font-size: 1.125rem;
        line-height: 1.44;
        color: $sandstone;
      }
      &-links {
        margin-top: 2rem;
        justify-self: flex-end;
        a {
          @include gta-burrow;
          text-transform: uppercase;
          font-size: .6875rem;
          font-weight: 500;
          line-height: 2.18;
          letter-spacing: 0.69px;
          color: $sandstone;
        }
      }
    }
    @include respond($break-phone) {
      column-gap: 2rem;
      row-gap: 2rem;
      &-cover {
        display: block;
      }
      &-info {
        grid-column: 2 / span 1;
      }
    }
  }

  &__section--middle {
    @include custom-breakpoint-styles(padding, 0, 0, 0);
    display: block;

    #{$footer}__nav-sections {
      display: none;
      @include respond($break-large) {
        display: grid;
      }
      @include custom-breakpoint-styles(
        padding,
        1rem $horizontal-page-padding-phone,
        1rem $horizontal-page-padding-tablet,
        3rem $horizontal-page-padding-desktop,
      );
    }

    #{$footer}__nav-section:nth-child(1) {
      grid-column-start: 1;
    }

    #{$footer}__nav-section:nth-child(2) {
      grid-column-start: 2;
    }

    #{$footer}__nav-section:nth-child(3) {
      grid-column-start: 3;
    }

    #{$footer}__nav-section:nth-child(4) {
      grid-column-start: 4;
    }

    #{$footer}__nav-item {
      line-height: 2.77;

      &--title {
        @include heavy;
        font-size: .875rem;
        color: $sandstone;
      }
    }
  }


  @mixin bottom-section-text-style() {
    font-size: .6875rem;
    line-height: 2.18;
    letter-spacing: 0.3px;
    color: $bottom-section-text-color;
    @include respond($break-phone) {
      font-size: .75rem;
    }
  }

  &__section--bottom {
    column-gap: 0;
    row-gap: 0;
    @include custom-breakpoint-styles(
      padding,
      2rem $horizontal-page-padding-phone 2rem,
      2rem $horizontal-page-padding-tablet 2rem,
      1rem $horizontal-page-padding-desktop 2rem,
    );

    @include respond($break-phone) {
      column-gap: 2rem;
      row-gap: 2rem;
    }

    #{$footer}__nav-sections {
      grid-column: 1 / span 2;
      @include respond($break-phone) {
        grid-column: 1 / span 2;
        grid-row: 1;
      }
    }

    #{$footer}__nav-section {
      @include bottom-section-text-style();
      grid-column: 1 / span 2;
      @include respond($break-phone) {
        grid-column: 1 / span 4;
      }
    }

    #{$footer}__nav-item {
      @include bottom-section-text-style();
      display: inline-block;

      &::after {
        content: '-';
        margin: 0 .25rem;
      }

      &:last-child::after {
        content: '';
      }

      &--title {
        @include bottom-section-text-style();
      }

      &-link {
        @include bottom-section-text-style();
      }
    }

    address {
      @include bottom-section-text-style();
      grid-column: 1 / span 2;
      @include respond($break-phone) {
        grid-column: 3 / span 2;
        grid-row: 1;
        text-align: right;
      }
    }
  }
  .accordion {
    @include respond($break-large) {
      display: none;
    }

    &__item-actions {
      & > i.icon-close {
        font-size: 0.75rem;
      }
    }

    &__item {
      @include custom-breakpoint-styles(
        padding,
        1rem $horizontal-page-padding-phone,
        1rem $horizontal-page-padding-tablet,
        1rem $horizontal-page-padding-desktop,
      );
      border: none;
      border-bottom: solid 1px $accordion-border-color;

      &:first-child {
        border-top: solid 1px $accordion-border-color;
      }

      &-title {
        button {
          font-size: 0.8125rem;
          color: $sandstone;
          opacity: 0.65;
        }
      }

      &-body {
        margin-top: .5rem;
      }
    }

    &-inner-wrapper {
      margin: 0;
    }
  }
}
