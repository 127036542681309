// Breakpoints
$gutter: 10px;
$break-xs: 375px;
$break-small: 576px;
$break-phone: 640px;
$break-medium: 768px;
$break-tablet: 920px;
$break-kit: 970px;
$break-large: 1024px;
$break-larger: 1090px;
$break-xl: 1280px;
$max-width: 1320px;
$full-width: 1440px;
$break-gigantic: 1600px;

$white: #ffffff;
$black: #2E3038;
$true-black: #000000;

//primary colors
$yellow: #fcee71;
$charcoal: #383633;
$sandstone: #F7EEE3;

//secondary colors
$oat: #D0B7A5;
$tan: #BE7F65;
$concrete: #B2B2B4;
$coolgrey: #B4B4B6;
$terracotta: #D64029;
$burgundy: #8B3B35;
$green: #839761;
$moss: #616A6A;
$persimmon: #FD5E3D;
$light-gray: #EAEAEA;
$light-grayish-blue: #E0E0E2;
$bluesteel: #474961;
$gray: #efedec;
$latte: #e5d7cc;

//tertiary colors
$sandstone500: #D0B7A5;
$sandstone200: #E7D3C4;
$sandstone75: #FAF4ED;
$sandstone50: #FCF8F4;
$sandstone10: #FEFCFA;
$sandstone5: #F4EFE9;
$charcoal75: #767472;
$charcoal50: #767472; // Darkening to make ADA compliant
$charcoal25: #D1D0D0;
$charcoal20: #E0E0E2;
$charcoal15: #F8F8F7;
$charcoal10: #f5f3f2;
$lightest-grey: #F9F7F5;
$seamless: #F4F3F1;

//SWATCH COLORS
$beige: #BCAC9A;
$brickred: #8b3b35;
$crushedgravel: #767472;
$navyblue: #3A4B66;
$charcoalswatch: #383633;
$ivory: #fff;
$chestnut: #864C37;
$slate: #484543;
$feather: #9F9F9F;
$dusk: #B6A39D;
$midnight: #1E364C;
$blush: #E9D4B9;
$jade: #305954;
$stonegray: #b2b2b4;
$heathercharcoal: #474543;
$carbon: #727272;
$all: conic-gradient(#fcee71 90deg, #c0c9b1 90deg, #c0c9b1 180deg, #505a71 180deg, #505a71 270deg, #e9bcaf 270deg);
$papyrus: #DBD6D0;

//PILLOW AND THROW COLORS
$fog: #D4D2CF;
$oat: #E5E0D8;
$smoke: #9C9792;
$aqua: #3A4B66;
$graphite: #474543;
$mossgreen: #7E8468;
$light-clay: #A76E6A;
$sand-oat: linear-gradient(-45deg, #C5AD97 0%, #C5AD97 50%, #E1D5C2 51%, #E1D5C2 100%);
$sand: #F0EEEA;
$slate-snow: linear-gradient(-45deg, #484543 0%, #484543 50%, #F0EEEA 51%, #F0EEEA 100%);
$dark-clay: #8B3B35;
$honey: #E1A100;

//CURATES SWATCH COLORS
$antiquebrass: linear-gradient(-45deg, #B18850 0%, #B18850 50%, #C3AC75 51%, #C3AC75 100%);
$copper: linear-gradient(-45deg, #B57E6A 0%, #B57E6A 50%, #CEA08A 51%, #CEA08A 100%);
$mattewhite: #fff;
$naturalwood: #DEC9AF;
$brushedsteel: #B3B2AD;
$jhhnavy: #585f7e;
$jhhatlantic: #97B1D9;
$jhhcerulean: #4C5F80;
$jhhindigo: #6E7081;
$jhhshore: #EEEBE3;
$jhhsnow: #F0EEEA;
$jhhblack: #201F20;
$jhhgoldenrod: #F9E17A;
$jhhcharcoal: #8E9091;
$jhhtaupe: #E3DDD4;
$jhhcafe: #A37D50;
$jhhpeacock: #74ADB9;
$jhhseapine: #79A2A5;
$jhhchambray: #AEBFCE;
$jhhpewter: #A09E9D;
$sjosnow: #EEF0F4;
$sjoivory: #E5E2CF;
$sjofog: #CECCC0;
$sjodove: #B0B0AC;
$sjoslate: #3B3232;
$sjoblush: #E1CFCF;
$sjosky: #869CAD;
$sjoforest: #495E28;
$talchalk:	#E4E4DE;
$talcharcoal:	#515056;
$talsage:	#656E68;
$talblossom:	#C3B6B3;
$talsapphire:	#4C617D;
$taloak:	#DDB588;
$talwalnut:	#906B4B;
$talblackoak:	#373539;
$talwhite:	#E9E9E7;

// Table, shelf, storage colors
$oak: #e5be96;
$walnut: #995e3f;
$whitewood: #f4f3f1;
$walnutblack: linear-gradient(-45deg, #000 0%, #000 50%, $walnut 51%, $walnut 100%);
$oakblack: linear-gradient(-45deg, #000 0%, #000 50%, $oak 51%, $oak 100%);
$walnutbrass: linear-gradient(-45deg, #e1cb93 0%, #efdaa3 50%, $walnut 51%, $walnut 100%);

//LEG COLORS
$lightwood: #E8C5A1;
$darkwood: #5A3E01;
$matteblack: #141414;
$blackwood: #000;

$camel: #AF8B66;
$blackmetal: linear-gradient(-45deg, #4b4a4a 0%, #4b4a4a 50%, #373636 51%, #373636 100%);
$chromemetal: linear-gradient(-45deg, #e3e6e6 0%, #e3e6e6 50%, #b2b2b4 51%, #b2b2b4 100%);
$brassmetal: linear-gradient(-45deg, #efdaa3 0%, #efdaa3 50%, #e1cb93 51%, #e1cb93 100%);
$glass: linear-gradient(135deg, #EDF3F1 0%, #EDF3F1 50%, #C6CCC9 51%, #C6CCC9 100%);
$marble: url('../../assets/marble-swatch.svg');
$papyrus: #F2F0EA;
$heathercharcoal-walnut: linear-gradient(-45deg, #474543 0%, #474543 50%, #995E3F 51%, #995E3F 100%);
$papyrus-oak: linear-gradient(-45deg, #F2F0EA 0%, #F2F0EA 50%, #E5BE96 51%, #E5BE96 100%);
$stonegray-walnut: linear-gradient(-45deg, #B2B2B4 0%, #B2B2B4 50%, #995E3F 51%, #995E3F 100%);
$slate-walnut: linear-gradient(-45deg, #484543 0%, #484543 50%, #995E3F 51%, #995E3F 100%);
$camel-oak: linear-gradient(-45deg, #AF8B66 0%, #AF8B66 50%, #E5BE96 51%, #E5BE96 100%);
$navy-blue-1: linear-gradient(135deg, #BCAC9A 0%, #BCAC9A 50%, #3A4B66 50%, #3A4B66 100%);
$navy-blue-2: linear-gradient(135deg, #E3DDD4 0%, #E3DDD4 50%, #3A4B66 50%, #3A4B66 100%);
$navy-blue-3: linear-gradient(135deg, #8F9FB6 0%, #8F9FB6 50%, #536075 50%, #536075 100%);
$cream: linear-gradient(135deg, #EDEBE2 0%, #EDEBE2 50%, #DFDACF 50%, #DFDACF 100%);
$cream-gray: linear-gradient(135deg, #F0EEEA 0%, #F0EEEA 50%, #484543 50%, #484543 100%);
$rust-palette: linear-gradient(135deg, #D89D54 0%, #D89D54 50%, #975340 50%, #975340 100%);
$dark-gray: linear-gradient(135deg, #D4D2CF 0%, #D4D2CF 50%, #A3A2A1 50%, #A3A2A1 100%);
$light-gray-2: linear-gradient(135deg, #EDEDED 0%, #EDEDED 50%, #D4D2CF 50%, #D4D2CF 100%);
$light-gray-3: linear-gradient(135deg, #F2EEEA 0%, #F2EEEA 50%, #D4CFC9 50%, #D4CFC9 100%);
$natural-blue: linear-gradient(135deg, #98846A 0%, #98846A 50%, #323A4A 50%, #323A4A 100%);
$beige-2: linear-gradient(135deg, #DDD9D1 0%, #DDD9D1 50%, #866F51 50%, #866F51 100%);
$gray-yellow: linear-gradient(135deg, #D2CFC8 0%, #D2CFC8 50%, #B38B46 50%, #B38B46 100%);
$gray-2: linear-gradient(135deg, #CDCAC6 0%, #CDCAC6 50%, #9EA1A5 50%, #9EA1A5 100%);
$gray-off-white: linear-gradient(135deg, #E4E0D6 0%, #E4E0D6 50%, #B8A389 50%, #B8A389 100%);

$inputBG: rgba(0,0,0,0.03);
//HEADER
$bx-banner-height: 2rem;
$header-height-mobile: 3.375rem;
$header-height-desktop: 4rem;
$header-utility-navigation-mobile: 2rem;
$header-utility-navigation-desktop: 2.5rem;
$header-total-mobile: $header-height-mobile + $header-utility-navigation-mobile;
$header-total-tablet: $header-height-desktop;
$header-product-desktop: $header-height-desktop;
$header-utility-desktop: $header-utility-navigation-desktop;
$header-total-desktop: $header-height-desktop + $header-utility-navigation-desktop;
$header-digioh-top-banner: 3rem;
$header-unbounce-top-banner: 2.375rem; // 38px

$product-preview: 6.25rem;
$product-preview-total: $product-preview + $header-utility-navigation-mobile;

//PAGE PADDING
$vertical-page-padding-phone: 2.25rem;
$horizontal-page-padding-phone: 1rem;
$vertical-page-padding-tablet: 2.5rem;
$horizontal-page-padding-tablet: 1.75rem;
$vertical-page-padding-desktop: 2.5rem;
$horizontal-page-padding-desktop: 2rem;

$colors:
  (CH, $charcoalswatch)
  (CG, $crushedgravel)
  (BG, $beige)
  (IV, $ivory)
  (NB, $navyblue)
  (BR, $brickred)
  (CN, $chestnut)
  (SL, $slate)
  (TN, $camel)
  (OK, $oak)
  (WN, $walnut)
  (OKBM, $oakblack)
  (WNBZ, $walnutbrass)
  (WNBM, $walnutblack)
  (OKLW, $oak)
  (WNDW, $walnut)
  (WT, $whitewood)
  (BM, $blackmetal)
  (CLGL, $glass)
  (WTMR, $marble)
  (PY, $papyrus)
  (HCWN, $heathercharcoal-walnut)
  (PYOK, $papyrus-oak)
  (SGWN, $stonegray-walnut)
  (SLWN, $slate-walnut)
  (TNOK, $camel-oak)
  (TLIV, $navy-blue-1)
  (NBIV, $navy-blue-2)
  (NBBL, $navy-blue-3)
  (IVGR, $cream)
  (WTCH, $cream-gray)
  (SNRS, $rust-palette)
  (WTBK, $dark-gray)
  (CHBK, $light-gray-2)
  (WTGR, $light-gray-3)
  (BRBL, $natural-blue)
  (GRBG, $beige-2)
  (YGBL, $gray-yellow)
  (IVBL, $gray-2)
  (BGGR, $gray-off-white)
  (CM, $chromemetal)
  (BZ, $brassmetal)
  (DW, $darkwood)
  (LW, $lightwood)
  (MB, $matteblack)
  (BW, $blackwood)
  (FT, $feather)
  (DK, $dusk)
  (MN, $midnight)
  (BH, $blush)
  (JD, $jade)
  (AB, $antiquebrass)
  (CP, $copper)
  (BS, $brushedsteel)
  (NT, $naturalwood)
  (BK, $matteblack)
  (SG, $stonegray)
  (HC, $heathercharcoal)
  (all, $all)
  (SK, $smoke)
  (FG, $fog)
  (OA, $oat)
  (BL0, $aqua)
  (SL0, $smoke)
  (BRCL, $light-clay)
  (SNOA, $sand-oat)
  (SAND, $sand)
  (SLSN, $slate-snow)
  (BR0, $dark-clay)
  (IV0, $oat)
  (GG, $graphite)
  (MG, $mossgreen)
  (JHAT, $jhhatlantic)
  (JHBK, $jhhblack)
  (JHCL, $jhhcafe)
  (JHCU, $jhhcerulean)
  (JHCB, $jhhchambray)
  (JHCH, $jhhcharcoal)
  (JHGR, $jhhgoldenrod)
  (JHIG, $jhhindigo)
  (JHNB, $jhhnavy)
  (JHPW, $jhhpewter)
  (JHPC, $jhhpeacock)
  (JHSH, $jhhshore)
  (JHSW, $jhhsnow)
  (JHTP, $jhhtaupe)
  (JHSP, $jhhseapine)
  (HN0, $honey)
  (CB, $carbon)
  (TLCK, $talchalk)
  (TLCH, $talcharcoal)
  (TLSG, $talsage)
  (TLBL, $talblossom)
  (TLSP, $talsapphire)
  (TLOK, $taloak)
  (TLWN, $talwalnut)
  (TLBO, $talblackoak)
  (TLWH, $talwhite)
  (SJSN, $sjosnow)
  (SJIV, $sjoivory)
  (SJFG, $sjofog)
  (SJDV, $sjodove)
  (SJSL, $sjoslate)
  (SJBH, $sjoblush)
  (SJSK, $sjosky)
  (SJFR, $sjoforest)
  (FRHC, $heathercharcoal)
  (FRPY, $papyrus)
  (FRSG, $stonegray)
  (UTHC, $heathercharcoal)
  (UTPY, $papyrus)
  (UTSG, $stonegray)
  (FROK, $oak)
  (FRWN, $walnut);

$aspect-ratios:
  ('8x10', 125%),
  ('1x1', 100%),
  ('4x3', 75%),
  ('3x2', 66.667%),
  ('16x10', 62.5%),
  ('2x1', 50%),
  ('7x3', 42.86%),
  ('3x1', 33.3333%),
  ('388x100', 25.7732%),
  ('5x2', 40%);

$carousel-dot-width: 0.6875rem;
$carousel-dot-width-wide: 1.75rem;
$carousel-dot-height: 0.25rem;
