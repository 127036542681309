@import '../partials/variables';
@import '../partials/mixins';

$plp-nav-height-mobile: 1.5rem;
$plp-nav-height-desktop: 2.5rem;

.plp {
  padding-bottom: 3rem;

  &.homepage {
    padding-bottom: 0;
  }

  .furniture {
    @include grid-wrapper;

    grid-template-rows: auto;
    position: relative;

    .anchor {
      display: block;
      position: absolute;
      top: -($plp-nav-height-mobile + $header-total-mobile);
      visibility: hidden;

      @include respond($break-large) {
        top: -($plp-nav-height-desktop + $header-total-desktop);
      }
    }

    &-title {
      grid-column: 1 / -1;
      position: relative;

      .badge {
        position: absolute;
        bottom: -1.25rem;

        @include respond($break-phone) {
          bottom: -1.5rem;
        }
      }
    }
  }

  &-error-boundary {
    display: flex;
    justify-content: center;
    padding-top: $plp-nav-height-mobile + 8rem;
    padding-bottom: 13rem;

    @include respond($break-large) {
      padding-top: $plp-nav-height-desktop + 8rem;
      padding-bottom: 13rem;
    }
  }
}

.collection {
  position: relative;

  .anchor {
    display: block;
    position: absolute;
    top: 0;
    visibility: hidden;
  }

  &-title {
    @include custom-breakpoint-styles(
      padding,
      1rem $horizontal-page-padding-phone,
      1rem $horizontal-page-padding-tablet,
      1rem $horizontal-page-padding-desktop
    );

    max-width: $full-width;
    margin: 0 auto;
    font-size: 0.875rem;

    @include heavy;

    padding-top: 1rem;
    padding-bottom: 1rem;

    @include respond($break-phone) {
      font-size: 1rem;
    }
  }

  &-hero {
    padding: 1.25rem 0;

    @include respond($break-phone) {
      @include page-padding();
    }

    &__wrapper {
      position: relative;
      width: 100%;
    }

    &__background {
      width: 100%;
    }

    &__details {
      position: absolute;
      top: 50%;
      left: 1.5rem;
      width: 50%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      transform: translateY(-50%);

      @include respond($break-phone) {
        left: 5.25rem;
      }

      @include respond($break-large) {
        left: 7rem;
      }
    }

    &__copy {
      margin-top: 1.5rem;
      display: none;
      font-size: 1rem;

      @include respond($break-phone) {
        line-height: 1.5rem;
        width: 80%;
      }

      @include respond($break-tablet) {
        display: inline-block;
      }
    }
  }

  &-grid-wrapper {
    position: relative;
    max-width: $full-width;
    margin: auto;
    padding: 0;
  }

  &-grid-container {
    position: relative;
    display: grid;
    margin: 0;
    -ms-grid-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    grid-template-columns: repeat(12, minmax(0, 1fr));
    grid-gap: 1rem;
    padding: 0 $horizontal-page-padding-phone;

    &:not(:last-child) {
      padding-bottom: 1rem;
    }

    @include respond($break-xs) {
      grid-gap: 1.3125rem;
      padding: 0 $horizontal-page-padding-phone;

      &:not(:last-child) {
        padding-bottom: 1.3125rem;
      }
    }

    @include respond($break-phone) {
      grid-gap: 1.6rem;
      padding: 0 $horizontal-page-padding-tablet;

      &:not(:last-child) {
        padding-bottom: 1.6rem;
      }
    }

    @include respond($break-large) {
      grid-gap: 2rem;
      padding: 0 $horizontal-page-padding-desktop;

      &:not(:last-child) {
        padding-bottom: 2rem;
      }
    }

    .product-card {
      @include custom-breakpoint-styles(grid-column, span 12, span 6, span 4);
    }

    &.single-grid {
      .product-card {
        grid-column: span 12;
      }

      &-on-mobile .product-card {
        @include respond-below($break-phone) { grid-column: span 12; }
      }

      &-on-tablet .product-card {
        @include respond-between($break-phone, $break-large) { grid-column: span 12; }
      }

      &-on-desktop .product-card {
        @include respond($break-large) { grid-column: span 12; }
      }
    }

    &.double-grid {
      .product-card {
        grid-column: span 6;
      }

      &-on-mobile {
        @include respond-below($break-phone) {
          padding: 0 1rem;
          column-gap: 0.25rem;
        }
      }

      &-on-mobile .product-card {
        @include respond-below($break-phone) { grid-column: span 6; }
      }

      &-on-mobile {
        .product-card__details {
          @include respond-below($break-phone) {
            padding: 0 0.5625rem;
          }
        }
      }

      &-on-tablet .product-card {
        @include respond-between($break-phone, $break-large) { grid-column: span 6; }
      }

      &-on-desktop .product-card {
        @include respond($break-large) { grid-column: span 6; }
      }
    }

    &.triple-grid {
      .product-card {
        grid-column: span 4;
      }

      &-on-mobile .product-card {
        @include respond-below($break-phone) { grid-column: span 4; }
      }

      &-on-tablet .product-card {
        @include respond-between($break-phone, $break-large) { grid-column: span 4; }
      }

      &-on-desktop .product-card {
        @include respond($break-large) { grid-column: span 4; }
      }
    }

    @include respond-below($break-phone) {
      .double-grid,
      .triple-grid,
      .double-grid-on-mobile,
      .triple-grid-on-mobile {
        .product-card .product-card-details {
          .name,
          .price,
          .shop-now {
            font-size: 0.75rem;
          }
        }
      }
    }
  }

  &-show-more {
    position: absolute;
    top: 0;
    bottom: -0.25rem; // fixing product card overflowing grid
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    font-size: 0.6875rem;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.91;
    letter-spacing: normal;
    cursor: pointer;
    z-index: 1;

    @include respond($break-phone) {
      font-size: 0.875rem;
    }

    &__button {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 10rem;
      height: 2.75rem;
      border-radius: 1.375rem;
      background: $latte;
    }

    &__title {
      @include h5;
      @include body-medium;

      text-transform: uppercase;
      color: $charcoal;

      @include respond($break-phone) {
        line-height: 1.25rem;
      }
    }

    &__subtitle {
      @include body-small;
      @include book;

      color: $charcoal;
    }
  }

  &-carousel {
    $card-width-phone: 16.5rem;
    $card-width-tablet: 22.125rem;
    $card-margin-phone: 0.625rem;
    $card-margin-tablet: 1rem;

    display: flex;
    flex-direction: row;
    overflow-x: auto;
    align-content: center;
    box-sizing: border-box;

    &::before,
    &::after { // using pseudo elements to maintain right padding
      content: ' ';
      min-width: $horizontal-page-padding-phone - $card-margin-phone;
    }

    .product-card {
      flex: 0 0 $card-width-phone;
      margin: 0 $card-margin-phone 1rem;
    }

    @include respond($break-phone) {
      &::before,
      &::after {
        content: ' ';
        min-width: $horizontal-page-padding-tablet - $card-margin-tablet;
      }

      .product-card {
        flex: 0 0 $card-width-tablet;
        margin: 0 $card-margin-tablet 1rem;
      }
    }

    @include respond($break-large) {
      &::before,
      &::after {
        content: ' ';
        min-width: $horizontal-page-padding-desktop - $card-margin-tablet;
      }
    }
  }
}
