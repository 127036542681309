.video-tag-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;

  video {
    width: 100%;
  }
}

.video-tag-container__controls {
  position: absolute;
  z-index: 2;
  top: calc(50% - 4rem);
  left: calc(50% - 4rem);
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;

  button {
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 3rem;
    line-height: 1;
    padding: 0.5em;
    text-shadow: 0 0 1em black;

    > i {
      display: block;
    }
  }
}

.video-tag-container:hover,
.video-tag-container:focus-within {
  .video-tag-container__controls {
    opacity: 1;
    visibility: visible;
  }
}
