@import '../partials/variables';
@import '../partials/mixins';

.product-category-card {
  @include grid-wrapper;
  @include section-padding;
  margin: 0 auto;

  &__title {
    grid-column: 1 / 13;

    @include respond($break-phone) {
      grid-column: 2 / 12;
    }
  }

  &__items {
    grid-column: 1 / 13;
    display: grid;
    grid-template-columns: repeat(10, minmax(0, 1fr));
    grid-template-rows: repeat(2, minmax(0, 1fr));
    grid-gap: 10px;

    &--right-expanded {
      direction: rtl;
    }

    @include respond($break-phone) {
      grid-column: 2 / 12;
    }
  }

  &__item {
    position: relative;
    grid-column: 1 / 13;

    @include respond($break-phone) {
      &:nth-child(1) {
        grid-column: 1 / span 7;
        grid-row: 1 / span 2;
      }

      &:nth-child(2) {
        grid-column: 8 / span 3;
        grid-row: 1 / span 1;
      }

      &:nth-child(3) {
        grid-column: 8 / span 3;
        grid-row: 2 / span 1;
      }
    }
  }

  &__item:nth-child(1) .graphcms-image-wrapper > div {
    padding-bottom: 81.3505% !important; // override inline styles

    @include respond($break-phone) {
      padding-bottom: 70% !important; // override inline styles
    }
  }

  &__item-copy {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 1.25rem;
  }

  &__item-badge {
    position: absolute;
    top: 0;
    right: 0;
    line-height: 28px;
    background: rgba($yellow, 0.7);
    padding: 0 0.75rem;
    font-weight: 900;

    &--charcoal-text {
      color: $charcoal;
    }
    &--white-text {
      color: $white;
    }
    &--bluesteel-text {
      color: $bluesteel;
    }
    &--black-text {
      color: $black;
    }
    &--persimmon-text {
      color: $persimmon;
    }
    &--terracotta-text {
      color: $terracotta;
    }
    &--sandstone-text {
      color: $sandstone;
    }
    &--oat-text {
      color: $oat;
    }
    &--yellow-text {
      color: $yellow;
    }
    &--charcoal-background {
      background: $charcoal;
    }
    &--white-background {
      background: $white;
    }
    &--bluesteel-background {
      background: $bluesteel;
    }
    &--black-background {
      background: $black;
    }
    &--persimmon-background {
      background: $persimmon;
    }
    &--terracotta-background {
      background: $terracotta;
    }
    &--sandstone-background {
      background: $sandstone;
    }
    &--oat-background {
      background: $oat;
    }
    &--yellow-background {
      background: $yellow;
    }
  }

  @include respond($break-phone) {
    &__items--top-expanded,
    &__items--bottom-expanded {
      grid-template-rows: auto;
    }

    &__items--top-expanded &__item {
      &:nth-child(1) {
        grid-column: 1 / -1;
        grid-row: 1 / span 1;
      }

      &:nth-child(2) {
        grid-column: 1 / span 5;
        grid-row: 2 / span 1;
      }

      &:nth-child(3) {
        grid-column: 6 / span 5;
        grid-row: 2 / span 1;
      }
    }

    &__items--bottom-expanded &__item {
      &:nth-child(1) {
        grid-column: 1 / 13;
        grid-row: 2 / span 1;
      }

      &:nth-child(2) {
        grid-column: 1 / span 5;
        grid-row: 1 / span 1;
      }

      &:nth-child(3) {
        grid-column: 6 / span 5;
        grid-row: 1 / span 1;
      }
    }
  }
}
