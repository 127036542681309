@import '../partials/variables';
@import '../partials/mixins';

.parallax-scroll {
  position: relative;
  @include components-element-max-width;

  &__copy {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    text-align: center;
    padding: 2rem;
    height: 100%;

    &-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      padding: 1.5rem;
      height: 90vh;
      width: 100%;

      @include respond($break-large) {
        padding: 4.125rem;
      }
    }

    &-header {
      margin-bottom: 1rem;
      width: 100%;
    }

    &-button {
      position: -webkit-sticky; /* Safari */
      position: sticky;
      top: 110vh;
      max-width: 50%;
      left: 50%;
      transform: translate(-50%, 0);
      @include transition(ease-in .5s);

      @include respond($break-large) {
        display: inline-block;
        max-width: unset;
      }
    }

    &-button--sticky {
      top: 85vh;

      @include respond($break-large) {
        display: inline-block;
        max-width: unset;
      }
    }
  }

  &__images {
    position: relative;

    &-asset {
      @include transition(ease-in .05s);
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }
}

.parallax-scroll-section {
  &__wrapper {
    position: relative;
    height: 250rem;

    .mobile {
      display: block;

      @include respond($break-phone) {
        display: none;
      }
    }

    .tablet {
      display: none;

      @include respond-between($break-phone, $break-large) {
        display: block;
      }
    }

    .desktop {
      display: none;

      @include respond($break-large) {
        display: block;
      }
    }
  }

  &__content {
    position: sticky;
    top: 0;
    height: 100vh;

    &--position {
      position: relative;
      top: 50%;
      transform: translate(0, -50%);
    }

    &-copy--position {
      position: absolute;
      padding: 2rem;
      z-index: 1;
      height: 100%;
      width: 100%;
    }
  }
}

.parallax-scroll__copy-wrapper,
.parallax-scroll-section__content-copy--position {
  display: flex;
  flex-direction: column;

  &.center {
    text-align: center;
    align-items: center;
  }

  &.right {
    align-items: flex-end;
    text-align: right;
  }

  &.left {
    align-items: flex-start;
    text-align: left;
  }

  &.top {
    justify-content: flex-start;
  }

  &.middle {
    justify-content: center;
  }

  &.bottom {
    justify-content: flex-end;
  }
}
