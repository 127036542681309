@import '../partials/variables';
@import '../partials/mixins';

$image-aspect-ratio: 0.625;

.product-card__image-wrapper {
  position: relative;
}

.product-card__image-overlay{
  display: block;
  cursor: pointer;
  position: relative;
  color: $charcoal;
  padding-bottom: calc(#{$image-aspect-ratio} * 100%); // 16:10 aspect ratio
}

.product-card__image-gallery {
  display: block;
  position: relative;

  :global(.slider__pagination) {
    display: block;
    top: 0.625rem;
    left: 0.625rem;
    line-height: 0;
    transform: none;
    width: auto;

    :global(.swiper-pagination-bullet) {
      margin: 0 0.25rem 0 0;
      padding: 0;
      width: 0.75rem;
      height: 0.1875rem;

      &:after {
        width: 0.75rem;
        height: 0.1875rem;
      }
    }
  }
}

.product-card__image {
  &:nth-child(2) {
    @include transition(ease-in-out 0.5s);

    opacity: 0;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
  }

  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;

  :global(.graphcms-image-outer-wrapper) {
    height: 100%;
  }

  :global(.graphcms-image-wrapper) {
    height: 100%;
    object-fit: cover;
  }
}

:global(.product-card__image-wrapper:hover) {
  .product-card__image:nth-child(2) {
    @include respond($break-large) {
      opacity: 1;
    }
  }
}

.product-card__badge-wrapper {
  $badge-grid-gap: 1rem;
  $badge-spacing-vertical: 3%;
  $badge-spacing-horizontal: calc(#{$badge-spacing-vertical} * #{$image-aspect-ratio});

  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;

  &-relative {
    position: relative;
    width: 100%;
    height: 100%;

    .top,
    .bottom {
      position: absolute;
      height: auto;
      width: min-content;
      z-index: 2;
    }

    .top {
      top: $badge-spacing-vertical;
    }

    .bottom {
      top: unset;
      bottom: $badge-spacing-vertical;
    }

    .left,
    .right {
      display: grid;
      grid-auto-flow: column;
      grid-gap: $badge-grid-gap;
    }

    .left {
      left: $badge-spacing-horizontal;
    }

    .right {
      right: $badge-spacing-horizontal;
    }

    .call-to-action {
      @include badge-text;

      height: min-content;
      width: min-content;
      white-space: nowrap;
      padding: 0.125rem 0.5rem;
    }
  }
}

