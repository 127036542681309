.site {
  background-color: $white;
  position: relative;
  // https://www.washington.edu/accessibility/checklist/focus/
  // Here we only add the :focus style for user's who use the keyboard to navigate the page.
  *:focus {
    outline: none;
  }

  &.accessibility {
    *:focus {
      outline: auto;
    }
  }

  &--underlay {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    cursor: pointer;
    pointer-events: none;

    &::before {
      @include transition(z 0.25s);

      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      content: '';
      opacity: 0;
      background: rgba(178, 178, 180, 0.8);
    }

    &.open-cart,
    &.open-dropdown,
    &.open-search {
      pointer-events: auto;
    }

    &.open-search::before {
      opacity: 1;

      @include respond($break-xl) {
        z-index: 2;
      }
    }

    &.open-cart::before {
      opacity: 1;

      @include respond($break-xl) {
        z-index: 2;
      }
    }

    &.open-dropdown::before {
      opacity: 1;

      @include respond($break-xl) {
        z-index: 2;
      }
    }
  }
}
