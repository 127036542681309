@import '../partials/variables';
@import '../partials/mixins';

.with-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: rgba(178, 178, 180, 0.8);
  white-space: normal;

  &--closed {
    display: none;
  }

  &__container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 22.25rem;
    max-width: 90vw;
    border-radius: 2px;
    background: $white;
    box-shadow: 0 1rem 0.75rem -0.75rem #7d7874;
  }

  &__close-button {
    cursor: pointer;
    position: absolute;
    z-index: 10;
    top: .5rem;
    right: .5rem;
    width: 2rem;
    height: 2rem;
    border-radius: 1rem;
    background: transparent;
    text-align: center;
    .icon {
      line-height: 2rem;
      color: $charcoal;
    }
  }
}
