@import '../partials/variables';
@import '../partials/mixins';

@mixin topContainerLeft {
  grid-column: col-start 1 / span 11;
  @include respond($break-kit) {
    grid-row: 1;
    grid-column: col-start 1 / span 6;
  }
}

@mixin topContainerRight {
  grid-column: col-start 2 / span 11;
  @include respond($break-kit) {
    grid-row: 1;
    grid-column: col-start 7 / span 6;
  }
}

@mixin bottomContainerLeft {
  grid-column: col-start 1 / span 11;
  @include respond($break-kit) {
    grid-row: 1;
    grid-column: col-start 1 / span 8;
  }
}

@mixin bottomContainerRight {
  grid-column: col-start 2 / span 11;
  @include respond($break-kit) {
    grid-row: 1;
    grid-column: col-start 5 / span 8;
  }
}

.story-block {
  display: grid;
  grid-template-columns: repeat(12, [col-start] minmax(0, 1fr));
  grid-template-rows: auto;
  grid-gap: 0;
  width: 100vw;
  height: 100%;
  position: relative;
  background-color: $sandstone50;
  @include respond($break-kit) {
    min-height: 31.25rem;
  }
  &.stacked {
    background-color: transparent;
    top: -3rem;
    z-index: 1;
    @include respond($break-kit) {
      top: 0;
    }
    .text-container {
      position: relative;
      display: grid;
      grid-template-columns: repeat(11, [col-start] minmax(0, 1fr));
      grid-template-rows: auto;
      padding: 1.5rem 0;
      top: -3rem;
      z-index: 2;
      @include respond($break-kit) {
        grid-row: 1;
        grid-template-columns: repeat(8, [col-start] minmax(0, 1fr));
        padding: 0;
        z-index: initial;
        top: 0;
      }
      h2 {
        grid-row: auto;
        grid-column: col-start 2 / span 9;
        align-self: end;
        @include respond($break-kit) {
          grid-column: col-start 2 / span 4;
        }
      }
      .content {
        grid-column: col-start 2 / span 9;
        grid-row: auto;
        @include respond($break-kit) {
          grid-column: col-start 2 / span 4;
        }
      }
      &-middle {
        display: none;
        @include respond($break-kit) {
          display: initial;
          position: relative;
          grid-column: col-start 1 / span 12;
          background-color: $sandstone50;
          height: 5rem;
          z-index: 2;
          &.top {
            top: -5rem;
          }
          &.bot {
            bottom: -5rem;
          }
        }
      }
    }
    .media-container {
      top: 0;
      @include respond($break-kit) {
        align-self: center;
      }
    }
    &.right {
      .text-container {
        @include bottomContainerLeft;
        background-color: $white;
      }
      .media-container {
        @include topContainerRight;
        z-index: 1;
      }
    }
    &.left {
      background-color: transparent;
      z-index: 2;
      @include respond($break-kit) {
        background-color: $white;
      }
      .text-container {
        @include bottomContainerRight;
        background-color: $sandstone50;
        h2 {
          grid-row: auto;
          grid-column: col-start 2 / span 9;
          align-self: end;
          @include respond($break-kit) {
            grid-column: col-start 4 / span 4;
          }
        }
        .content {
          grid-column: col-start 2 / span 9;
          grid-row: auto;
          @include respond($break-kit) {
            grid-column: col-start 4 / span 4;
          }
        }
      }
      .media-container {
        @include topContainerLeft;
      }
    }
  }
  &.single {
    padding: 0;
    @include respond($break-kit) {
      padding: 0 0 3rem 0 ;
    }
    &.right {
      .text-container {
        @include topContainerLeft;
      }
      .media-container {
        @include bottomContainerRight;
      }
    }
    &.left {
      .text-container {
        @include topContainerRight;
      }
      .media-container {
        @include bottomContainerLeft;
      }
    }
    .text-container {
      position: relative;
      top: -6rem;
      grid-row: 2;
      display: grid;
      grid-template-columns: repeat(11, [col-start] minmax(0, 1fr));
      grid-template-rows: auto;
      z-index: 1;
      background-color: $white;
      padding: 1.5rem 0 1rem;
      @include respond($break-kit) {
        grid-row: 1;
        top: -3rem;
        grid-template-columns: repeat(6, [col-start] minmax(0, 1fr));
        padding: 0;
      }
      h2 {
        grid-row: auto;
        grid-column: col-start 2 / span 9;
        align-self: end;
        @include respond($break-kit) {
          grid-column: col-start 2 / span 4;
        }
      }
      .content {
        grid-column: col-start 2 / span 9;
        grid-row: auto;
        @include respond($break-kit) {
          grid-column: col-start 2 / span 4;
        }
      }
    }
  }
  &.bottom-overlap {
    top: 16rem;
    padding: 0;
    background: transparent;
    @include respond($break-kit) {
      top: 3rem;
    }
  }
  .media-container {
    grid-row: 1;
    position: relative;
    padding-bottom: 67% !important;
  }

  .video-component {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &-video {
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    width: 100%;
    height: 100%;
    object-fit: cover;

    @include respond($break-kit) {
      top: 0;
      left: 0;
      transform: initial;
    }
  }
}
.story-block-padding {
  height: 3rem;
  width: 100%;
}
