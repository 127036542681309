@import '../partials/variables';
@import '../partials/mixins';

.restock-notifications {
  min-height: 24rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3rem 0;
  text-align: left;
  color: $charcoal;
  .text-image {
    @include responsive-image('../../assets/restock/OOS-Text-Capture.png');
    height: 10rem;
    width: 10rem;
  }
  .couch-image {
    @include responsive-image('../../assets/restock/OOS-Email-Capture.png');
    height: 12rem;
    width: 12rem;
  }
  .text-image, .couch-image {
    background-size: cover;
    margin: auto;
  }
  .form-fancy-wrapper {
    margin: 0 2.625rem;
  }
  .checkbox-wrapper {
    display: flex;
    align-items: center;
    margin: 1rem 2.625rem;
    line-height: 1.71;
    .checkbox {
      span {
        &:before {
          opacity: 1;
          background: $white;
        }
      }
    }
  }
  button.btn {
    height: 2rem;
    width: 8rem;
    background-color: $terracotta;
    color: $white;
    font-size: .8125rem;
    text-transform: uppercase;
    padding: 0;
    font-weight: 900;
    display: block;
    margin: 1rem auto;
    cursor: pointer;
    border-radius: 2px;
    &.decline {
      background-color: transparent;
      border: 1px solid $terracotta;
      color: $terracotta;
      margin-top: 0;
    }
  }
  .footnote {
    text-align: center;
    color: $charcoal50;
  }
  .copy, .footnote, h2 {
    margin-left: 2.625rem;
    margin-right: 2.625rem;
  }
  .copy, .footnote {
    margin-top: 1rem ;
    margin-bottom: 1rem ;
    display: inline-block;
  }
  a {
    color: $terracotta;
    text-decoration: underline;
    font-size: 0.8125rem;
    cursor: pointer;
    text-align: center;
    margin-top: 2rem;
  }
  h2 {
    @include gta-burrow;
    margin: 0 2.625rem;
    text-align: center;
    font-size: 1rem;
    line-height: 1.5;
  }
}
