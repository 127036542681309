@import '../partials/variables';
@import '../partials/mixins';

.testimonial-wrapper {
  @include section-padding;
  @include custom-breakpoint-styles(padding-bottom, 3.75rem, 5rem, 6.5rem);
  text-align: center;
  * {
    margin: 0 auto 2rem;
  }
  // FIXME: [styles]: Use grid
  h3 {
    @include respond($break-phone) {
      width: 75%;
    }
  }
  p {
    padding-top: 0;
    margin-top: 0;
  }
  // FIXME [styles]: move to mixins, also this is terribly vague css, all of these
  // classes are just called various forms of label
  .label {
    @include heavy;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 1.2px;
    border-bottom: 2px solid;
    text-transform: uppercase;
    @include respond($break-large) {
      border-bottom: 2px solid;
      font-size: 1rem;
      line-height: 1.25;
    }
  }
  span.label {
    border-bottom: 0;
    font-size: .75rem;
    display: block;
    @include respond($break-large) {
      font-size: .875rem;
    }
  }
  a.label {
    font-size: .875rem;
    @include respond($break-large) {
      font-size: .875rem;
    }
    &:hover {
      @include transition;
      letter-spacing: 1.8px;
    }
  }
}
