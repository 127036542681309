@import '../partials/variables';
@import '../partials/mixins';

.spotlight {
  @include components-element-max-width;
  max-width: $break-gigantic;
  display: flex;
  flex-direction: column;
  .asset {
    .card__asset,
    .card__assets {
      @include square-aspect-ratio;
    }
  }
  .card {
    width: 100%;
    height: auto;
    &.content {
      .card__body {
        padding: 2.5rem;
      }
    }

    &__link {
      @include respond($break-phone) {
        margin-top: 2rem;
      }
    }
  }
  &.reverse {
    @include respond($break-phone) {
      flex-direction: row-reverse;
    }
  }

  @include respond($break-phone) {
    flex-direction: row;
    .card {
      width: 50%;
    }
    .card__overlay, .card__asset {
      @include square-aspect-ratio;
    }
  }
}
