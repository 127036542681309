// FIXME: [styles] don't need two button classes
.button {
  @include button-style;

  padding: 0.875rem 0;
  min-width: 15.625rem;
  letter-spacing: 1px;

  @include respond($break-phone) {
    font-size: .875rem;
    letter-spacing: 1.2px;
    min-width: 12.5rem;
  }

  @include respond($break-large) {
    min-width: 15.625rem;
    padding: 1.1875rem 0;
  }
}

.form-fancy__submit .button {
  @include respond($break-large) {
    padding: 1.1875rem 1rem;
  }
}

.btn {
  @include button-style;

  width: 100%;

  &.heavy {
    @include heavy;
  }

  &-charcoal {
    color: $white;
    background-color: $charcoal;
  }

  &-charcoal-reverse {
    color: $charcoal;
    border: 1px solid $charcoal;
  }

  &-terracotta,
  &-persimmon {
    color: #0D0D0D;
    background-color: #DCA18A;

    &:hover {
      color: $white;
      background-color: $charcoal;
    }
  }

  &-persimmon {
    background-color: $persimmon !important;
  }

  &-sandstone200 {
    background-color: $sandstone200;
    color: $charcoal;
  }

  &-latte {
    background-color: $latte;
    color: $charcoal;
  }

  &-yellow {
    color: $charcoal !important;
    background-color: $yellow !important;
  }

  &-charcoal-reverse-thick {
    background: $sandstone75;
    color: $charcoal !important;
    border: 1px solid $charcoal !important;
  }

  &-white {
    color: $charcoal !important;
    background-color: $white !important;
  }

  &-ui {
    @include tiny-caps;

    font-size: 0.5625rem;
    width: auto;
    border-radius: 2px;
    padding: 0.625rem 0.875rem 0.5rem;
    background: $sandstone;
    color: $charcoal;

    @include respond($break-phone) {
      font-size: 0.6875rem;
      padding: 0.875rem 1.5rem 0.75rem;
    }
  }
}
