@import '../partials/variables';
@import '../partials/mixins';

.storycard {
  $this: &;
  $defaultVariation: --default-variation;
  $WiderAssetVariation: --wider-asset-variation;
  $narrowVariation: --narrow-variation;
  $leftAlign: --left-align;
  $rightAlign: --right-align;
  $asset: #{&}__asset;
  $content: #{&}__content;

  @include grid-container;
  @include components-element-max-width();
  @include custom-breakpoint-styles(column-gap, 0, 0, 0);

  #{$content} {
    @include grid-container;

    a {
      color: $charcoal;

      :hover {
        text-decoration: underline;
      }
    }

    .card__body {
      @include custom-breakpoint-styles(
        padding,
        $vertical-page-padding-phone $horizontal-page-padding-phone,
        0 $horizontal-page-padding-tablet,
        0,
      );

      text-align: left;
      grid-column: 1 / -1;
      margin: auto 0;
      max-width: unset;

      @include respond($break-phone) {
        grid-column: 1 / span 8;
      }

      @include respond($break-large) {
        grid-column: 3 / span 8;
      }
    }

    .card__point {
      &-header,
      &-subheader,
      &-copy {
        @include body-medium;
      }
    }
  }

  #{$asset} {
    .card__asset {
      @include cover-asset-container;
    }

    .card__assets {
      @include cover-gallery-container;

      grid-row: 1 / span 1;

      .slick-dots {
        li {
          width: $carousel-dot-width;

          button {
            width: $carousel-dot-width;

            &::before {
              width: $carousel-dot-width;
            }
          }
        }
      }
    }
  }

  &#{$defaultVariation} {
    @include respond($break-phone) {
      margin: 3.75rem auto 5rem;
    }

    @include respond($break-large) {
      margin: 5rem auto 7rem;
    }

    &#{$this}#{$rightAlign} {
      #{$asset} {
        grid-row: 1;
        grid-column: 1 / -1;

        @include respond($break-phone) {
          grid-column: 1 / span 4;
        }

        @include respond($break-large) {
          grid-column: 1 / span 6;
        }
      }
      #{$content} {
        grid-row: 2;
        grid-column: 1 / -1;

        @include respond($break-phone) {
          grid-row: 1;
          grid-column: 5 / span 4;
        }

        @include respond($break-large) {
          grid-column: 7 / span 6;
        }

        .card__body {
          @include respond($break-large) {
            padding-right: 1rem;
          }
        }
      }
    }

    &#{$this}#{$leftAlign} {
      #{$asset} {
        grid-row: 1;
        grid-column: 1 / -1;

        @include respond($break-phone) {
          grid-column: 5 / span 4;
        }

        @include respond($break-large) {
          grid-column: 7 / span 6;
        }
      }
      #{$content} {
        grid-row: 2;
        grid-column: 1 / -1;

        @include respond($break-phone) {
          grid-row: 1;
          grid-column: 1 / span 4;
        }

        @include respond($break-large) {
          grid-column: 1 / span 6;
        }

        .card__body {
          @include respond($break-large) {
            padding-left: 1rem;
          }
        }
      }
    }
  }

  &#{$WiderAssetVariation} {
    &#{$this}#{$rightAlign} {
      #{$asset} {
        grid-row: 1;
        grid-column: 1 / -1;

        @include respond($break-phone) {
          grid-column: 1 / span 5;
        }

        @include respond($break-large) {
          grid-column: 1 / span 8;
        }
      }
      #{$content} {
        grid-row: 2;
        grid-column: 1 / -1;

        @include respond($break-phone) {
          grid-row: 1;
          grid-column: 6 / span 3;
        }

        @include respond($break-large) {
          grid-column: 9 / span 4;
        }
      }
    }

    &#{$this}#{$leftAlign} {
      #{$asset} {
        grid-row: 1;
        grid-column: 1 / -1;

        @include respond($break-phone) {
          grid-column: 4 / span 5;
        }

        @include respond($break-large) {
          grid-column: 5 / span 8;
        }
      }
      #{$content} {
        grid-row: 2;
        grid-column: 1 / -1;

        @include respond($break-phone) {
          grid-row: 1;
          grid-column: 1 / span 3;
        }

        @include respond($break-large) {
          grid-column: 1 / span 4;
        }
      }
    }
  }

  &#{$narrowVariation} {
    @include custom-breakpoint-styles(
      margin,
      auto 1.5rem,
      3.75rem auto 5rem,
      5rem auto 7rem,
    );

    #{$asset} {
      @include custom-breakpoint-styles(
        grid-column-end,
        span 4,
        span 4,
        span 6,
      );

      grid-row: 1;
    }

    #{$content} {
      @include custom-breakpoint-styles(
        grid-column-end,
        span 4,
        span 3,
        span 4,
      );

      grid-row: 2;

      @include respond($break-phone) {
        grid-row: 1;
      }

      .card__body {
        @include custom-breakpoint-styles(padding-left, 0, 2rem, 2rem);
        @include custom-breakpoint-styles(padding-right, 0, 2rem, 2rem);

        @include respond($break-large) {
          grid-column: 1 / span 12;
        }
      }
    }

    &#{$this}#{$rightAlign} {
      #{$asset} {
        @include custom-breakpoint-styles(grid-column-start, 1, 1, 2);
      }

      #{$content} {
        @include custom-breakpoint-styles(grid-column-start, 1, 5, 8);

        .card__body {
          @include respond($break-phone) {
            padding-right: 0;
            padding-left: 1.5rem;
          }
        }
      }
    }

    &#{$this}#{$leftAlign} {
      #{$asset} {
        @include custom-breakpoint-styles(grid-column-start, 1, 5, 6);
      }

      #{$content} {
        @include custom-breakpoint-styles(grid-column-start, 1, 2, 2);

        .card__body {
          @include respond($break-phone) {
            padding-right: 1.5rem;
            padding-left: 0;
          }
        }
      }
    }
  }
}
