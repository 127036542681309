@import '../partials/variables';
@import '../partials/mixins';

.design-features {
  @include grid-container;
  @include components-element-max-width;

  max-width: $break-gigantic;

  &__slider {
    grid-column: 1 / -1;
    position: relative;

    .slider__pagination {
      height: 0;
      top: 102.5vw !important; //override inline style

      @include respond($break-phone) {
        top: unset !important; //override inline styles
        width: 50%;
        right: 0;
        bottom: 12.5% !important; //override inline styles
      }

      .swiper-pagination-bullet {
        &::after {
          width: $carousel-dot-width;
          height: $carousel-dot-height;
        }
      }
    }
  }

  &__slide {
    display: grid !important; // override inline styles
    grid-template-columns: 1fr;

    @include respond($break-phone) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

  &__image {
    @include cover-asset-container;
    @include square-aspect-ratio;

    grid-column: 1 / span 1;
    overflow: hidden;

    .graphcms-image-wrapper {
      div:first-of-type {
        padding-bottom: 100% !important // override inline styles
      }
    }

    .video-component {
      padding-bottom: 100%;

      video {
        left: 50%;
        height: 100%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &__link {
    @include blurb-link;

    z-index: 1;
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    margin: 0.75rem;
    padding: 0.625rem;
    max-width: 10.9375rem;
    border-radius: 2px;

    @include respond($break-phone) {
      margin: 1.25rem;
      padding: 0.75rem;
    }

    @include grid-container;
    @include btn-hover-translate;

    &-description {
      @include book;

      text-align: left;
      border-radius: 0;
    }

    &-copy {
      @include body-medium;

      padding-bottom: 0.25rem;
    }

    &-title--heavy {
      font-weight: 600;
    }
  }

  &__copy {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    padding: 0 2rem;
    min-height: 19.1875rem;

    @include respond($break-large) {
      padding: 0 6rem;
    }

    &-header {
      display: flex;
      flex-direction: column;
      align-self: center;
      flex-grow: 5;
      padding-top: calc(2.5vw + 2.5rem); // dots height

      &::before,
      &::after {
        display: block;
        content: '';
        flex-grow: 1;
      }

      @include respond($break-phone) {
        padding-top: 12.5%;
      }
    }
  }

  &__icon-wrapper {
    flex-grow: 3;
    padding-bottom: 2.5rem;

    @include respond($break-phone) {
      padding-bottom: 12.5%;
    }
  }

  &__icon {
    max-width: 10.125rem;
    margin: 0 auto;

    &-title {
      line-height: 1.43;

      @include respond($break-large) {
        padding-top: 1rem;
      }
    }
  }
}
