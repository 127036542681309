@import '../partials/variables';
@import '../partials/mixins';

.images {
  &.pdp {
    &-play-button {
      @include responsive-image('../../assets/pdp/video-pdp-gallery-icon.svg');
      @include object-fill-image(100%);
    }

    &-affirm {
      @include responsive-image('../../assets/pdp/affirm-logo.svg');
      @include object-fill-image(100%);
    }
  }
}

.pdp {
  &-atf {
    position: relative;

    @include grid-wrapper;

    grid-template-rows: auto auto 1fr;

    @include custom-breakpoint-styles(grid-row-gap, 0.5rem, 0.5rem, 1rem);
    @include custom-breakpoint-styles(padding-top, 0, 0, 0);
    @include custom-breakpoint-styles(
      padding-left,
      $horizontal-page-padding-tablet,
      $horizontal-page-padding-tablet,
      $horizontal-page-padding-desktop,
    );
    @include custom-breakpoint-styles(
      padding-right,
      $horizontal-page-padding-tablet,
      $horizontal-page-padding-tablet,
      $horizontal-page-padding-desktop,
    );
  }

  &-atf-title {
    grid-row: 1;
    grid-column: 1 / -1;

    @include respond($break-phone) {
      grid-row: 2;
    }

    @include respond($break-tablet) {
      grid-gap: 1.25rem;
      grid-row: 2;
      grid-column: 6 / -1;
    }

    @include respond($break-large) {
      grid-column: 10 / -1;
    }

    .ruk_rating_snippet {
      i[class^='ruk-icon-percentage-star--'] {
        font-size: 0.675rem;
        color: $charcoal !important;

        @include respond($break-large) {
          font-size: 1.125rem;
        }
      }

      span[class='ruk-rating-snippet-count'] {
        cursor: pointer;
        font-size: 0.75rem;
        line-height: 1rem;
        letter-spacing: 0;
        padding-left: 0.25rem;
        text-decoration: underline;
        text-decoration-skip-ink: none;
      }

      & span[class="ruk-rating-snippet-count"]:hover {
        text-decoration: none;
      }
    }
  }

  &-breadcrumbs {
    @include respond-below($break-phone) {
      display: none;
    }

    display: initial;
    grid-column: 1 / -1;

    @include respond($break-tablet) {
      grid-row: 1;
    }

    a {
      color: $charcoal;
    }

    i {
      font-size: 0.875rem;
      line-height: 1;
    }
  }

  .pdp-images-container, .notFound {
    position: relative;
    grid-column: 1 / -1;
    grid-row: 2;
    width: 100vw;
    scroll-snap-type: mandatory;
    scroll-snap-align: start;

    @include custom-breakpoint-styles(
      left,
      -$horizontal-page-padding-tablet,
      -$horizontal-page-padding-tablet,
      -$horizontal-page-padding-desktop,
    );

    @include respond($break-phone) {
      grid-row: 3;
    }

    @include respond($break-tablet) {
      padding-bottom: 0;
      grid-row: 2 / span 2;
      width: 100%;
      left: 0;
      grid-column: 1 / 6;
    }

    @include respond($break-large) {
      grid-column: 1 / 10;
    }

    .slick-dots {
      li {
        width: unset;
        height: unset;

        button::before {
          font-size: 0.5rem;
        }
      }
    }

    .pdp-video {
      &-container {
        position: relative;
        padding-top: 56.25%;
        overflow: hidden;

        .video-container {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }

      left: 50%;
      width: 100%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .pdp-images-carousel {
      @include respond($break-tablet) {
        padding-bottom: 1.25rem;
        position: sticky;
        transition: top 0.25s ease;
      }

      &.sticky-with-nav-pinned {
        @include respond($break-tablet) {
          top: $header-total-desktop;
        }
      }

      &.sticky-without-nav-pinned {
        @include respond($break-tablet) {
          top: $header-height-desktop;
        }
      }

      .portrait {
        .graphcms-image-wrapper img {
          object-position: 50% 25% !important; // override inline styles
        }
      }

      .graphcms-image-wrapper > div {
        padding-bottom: 56.25% !important; // override inline styles
      }

      .slider {
        position: relative;
        display: block;
      }

      .swiper-container {
        &:not(.swiper-container-initialized) {
          .swiper-slide-duplicate {
            display: none;
          }
        }
      }
    }
  }

  &__gallery-thumbs {
    display: flex;
    flex-wrap: wrap;
    margin: 0.25rem auto 0.5rem;
    justify-content: center;

    @include respond($break-tablet) {
      margin: 0.5rem 0;
      justify-content: flex-start;
    }
  }

  &__gallery-thumb {
    $galleryThumb: &;

    display: inline-block;
    width: $carousel-dot-width;
    height: $carousel-dot-height;
    margin: 0 0.125rem;
    border: solid 1px $charcoal25;
    background-color: $charcoal25;
    cursor: pointer;

    @include respond($break-tablet) {
      width: 5rem;
      height: 2.875rem;
      margin: 0 0.5rem 0 0;
      border: none;
      background-color: $charcoal25;

      .graphcms-image-wrapper > div {
        padding-bottom: 56.25% !important; // override inline styles
      }

      img {
        height: auto;
      }
    }

    &--video {
      position: relative;
      border: unset;
      background: $charcoal25;

      @include respond($break-tablet) {
        background-color: $seamless;
      }

      &::after {
        width: 0.5rem;
        height: 0.5rem;

        @include respond($break-tablet) {
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          z-index: 1;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          border-top: 0.25rem solid transparent;
          border-bottom: 0.25rem solid transparent;
          border-left: 0.5rem solid $charcoal25;
        }
      }
    }

    &--active {
      border: 0;
      background-color: $charcoal;

      &#{$galleryThumb}--video {
        &::after {
          border-left: 0.5625rem solid $charcoal25;
        }
      }

      @include respond($break-tablet) {
        border-bottom: 1px solid $charcoal;

        &#{$galleryThumb}--video {
          background-color: $seamless;
        }
      }
    }

    &-overlay {
      @include respond($break-tablet) {
        position: absolute;
        z-index: 1;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 100%;
        background-color: $white;

        &::before {
          left: 0.5625rem;
          top: 0.5625rem;
        }

        &::after {
          left: 0.25rem;
          top: 0.25rem;
        }
      }
    }

    @include respond-below($break-tablet) {
      .graphcms-image-outer-wrapper {
        display: none;
      }
    }
  }

  .preview {
    cursor: zoom-in;
    position: absolute;
    top: 5%;
    right: 3%;
    z-index: 2;
    font-size: 1.5rem;
  }

  &-section-quote {
    min-height: 10vh;
    padding: 13% 13% 18%;
    background-color: $slate;
    text-align: center;
    z-index: 1;
    position: relative;
    letter-spacing: 0;

    @include respond($break-kit) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 30vh;
      padding: 6.5% 13%;
    }

    h3,
    p {
      max-width: 45rem;
      margin: auto;
    }

    h3 {
      line-height: 1.875rem;

      @include respond($break-kit) {
        padding-bottom: 2rem;
      }
    }
  }
}

.pdp-btf {
  @include custom-breakpoint-styles(padding-top, 0, 2.5rem, 2.5rem);

  &-section {
    position: relative;

    @include grid-wrapper;
    @include custom-breakpoint-styles(padding, 0 1.25rem, 0.25rem 1.5rem, 0.25rem 2rem);

    &-title {
      @include flex-row;

      justify-content: space-between;
      align-items: center;
      grid-column: 1 / -1;
      border-bottom: 0.0625rem solid $charcoal20;
      min-height: 1.5rem;

      &.active {
        transition: all 0.25s;
        border-bottom: 0.0625rem solid $white;
      }

      @include respond($break-phone) {
        grid-column: 2 / -2;
        padding-bottom: 1.15rem;
      }

      @include respond($break-large) {
        padding-bottom: 1.35rem;
      }

      @include respond($break-phone) {
        .icon-container {
          opacity: 0.85;
          height: 3rem;
          width: 3rem;
          border-radius: 50%;
          background: white;
          backdrop-filter: blur(8px);
          margin-bottom: 1rem;
          border: 1px solid #E0E0E2;

          &:hover,
          &:focus,
          &:active {
            border: 1px solid #383633;
          }

          &:active {
            background: #f4f3f1;
          }
        }
      }

      .icon-nav-arrow {
        font-size: 0.5rem;
        transform: rotate(0);
        transition: all 0.25s;

        &.active {
          transform: rotate(180deg);
          transition: all 0.25s;
        }

        @include respond($break-phone) {
          font-size: 0.75rem;
          line-height: 1.75rem;
          opacity: 0.75;
          -webkit-font-smoothing: antialiased;
          font-weight: 900;
          border: solid black;
          border-width: 0 0.1rem 0.1rem 0;
          display: inline-block;
          padding: 6px;
          transform: rotate(45deg);
          margin-top: 0.875rem;
          margin-left: 1rem;

          &.active {
            transform: rotate(-135deg);
            transition: all 0.25s;
            margin-top: 1.125rem;
            margin-left: 1rem;
          }

          &::before {
            content: "";
          }
        }
      }
    }

    &-wrapper {
      grid-column: 1 / -1;

      @include grid-wrapper;
      @include padding-clear;

      margin: 0;
      overflow: hidden;
      max-height: 0;
      transition: max-height 0.3s ease-out;

      &.active {
        padding-bottom: 1.25rem;
        max-height: 60rem;
        transition: max-height 0.3s ease-out;
      }
    }

    &.key-features {
      .pdp-btf-section-wrapper {
        grid-column: 1 / -1;

        @include respond($break-phone) {
          grid-column: 2 / -2;
        }

        .images {
          grid-column: 1 / -1;
        }

        .key-features-item {
          &-wrapper {
            grid-column: 1 / -1;
            grid-auto-flow: row;

            @include grid-wrapper;
            @include custom-breakpoint-styles(grid-template-columns, repeat(4, [col-start] minmax(0, 1fr)), repeat(6, [col-start] minmax(0, 1fr)), repeat(10, [col-start] minmax(0, 1fr)));
            @include custom-breakpoint-styles(padding, 0, 0, 0);

            @include respond($break-tablet) {
              @include flex-row;

              justify-content: space-between;

              .key-features-item {
                width: 14%;

                &.wide {
                  width: 31%;
                }
              }
            }
          }

          grid-column: span 2;

          @include respond($break-phone) {
            grid-column: span 3;
          }

          i {
            font-size: 4rem;

            @include respond($break-phone) {
              font-size: 6rem;
            }
          }

          &-images {
            height: 100%;

            img,
            source {
              width: 6rem;
              max-width: 100%;
              object-fit: cover;
              object-position: center;
            }
          }

          &-placeholder {
            width: 6rem;
            max-width: 100%;

            &::before {
              content: '';
              display: block;
              width: 100%;
              padding-bottom: 100%;
            }
          }

          &-title {
            margin: 0.5rem 0;
          }

          &-copy {
            color: $charcoal75;
          }
        }
      }
    }
  }
}

.pdp-btf {
  section,
  .reviews {
    position: relative;
  }

  .reviews div[class^="subheader__group subheader__group--tabs"] {
    display: none;
  }

  .reviews div[class^="item__review"] div[class^="R-TextHeading"],
  .reviews div[class^="item__review"] div[class^="R-TextBody"],
  .reviews div[class^="item__meta"] div[class^="cssVar-authorName"] {
    @include book;
    @include body-large;
    @include body-extra-large;
  }

  .anchor-nav {
    @include respond-below($break-phone) {
      display: none;
    }
  }
}

@import '../components/dimensions';
@import '../components/details';
