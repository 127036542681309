@import '../partials/variables';
@import '../partials/mixins';

.search-products-grid-container {
  display: grid;
  position: relative;
  margin-bottom: 3rem;
  grid-gap: 1.3125rem;

  &.SingleGrid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  &.DoubleGrid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  & > .product-card {
    grid-column: span 1;
  }

  @include respond($break-phone) {
    grid-gap: 1.6rem;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @include respond($break-tablet) {
    grid-gap: 1.6rem;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @include respond($break-large) {
    grid-gap: 1.6rem;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @include respond($break-larger) {
    grid-gap: 1.6rem;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @include respond($break-xl) {
    grid-gap: 1.6rem;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.search-term-input-container {
  display: flex;
  align-items: center;
  padding: $vertical-page-padding-desktop 0 0 0;

  @include respond($break-xs) {
    padding: $vertical-page-padding-phone 0 0 0;
  }

  @include respond($break-phone) {
    padding: $vertical-page-padding-tablet 0 0 0;
  }

  & > .visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  & > .search-term-input {
    width: 100%;
    padding: 0;
    font-size: 1.125rem;
    color: $charcoal;

    @include gta-burrow;
  }

  & > .icon-search {
    margin-right: 1rem;
    font-size: 1.5rem;
  }

  & > .icon-search::before {
    color: $charcoal;
    content: "\e90e";
  }

  & > .mobile-close-button {
    background: transparent;

    .icon-close {
      margin-right: 1rem;
      font-size: 0.75rem;
      color: $charcoal;
      content: "\e90f";

      @include respond($break-large) {
        display: none;
      }
    }
  }
}

.search-total-results {
  font-size: 0.813rem;
  margin: 2rem 0;
}

.search-divider {
  height: 1px;
  width: 100%;
  margin: 1rem 0;
  background-color: #E0E0E2;
}

.search-cta {
  background: transparent;
  color: inherit;
  cursor: pointer;
  font: inherit;
  margin: 0;
  display: flex;
  align-items: center;

  @include respond($break-phone) {
    margin: 1rem;
  }

  @include respond($break-tablet) {
    margin: 0;
  }

  @include respond($break-larger) {
    margin: 0 1rem;
  }

  .icon-search {
    &:hover {
      color: $burgundy;
    }

    @include respond($break-tablet) {
      margin: 0 1rem;
    }

    margin-right: 0;
    font-size: 1.5rem;
  }

  .icon-search::before {
    color: $charcoal;
    content: "\e90e";
    display: inline-block;
  }

  @media (hover: hover) {
    &:hover {
      color: $burgundy;
      cursor: pointer;
    }
  }

  @media (hover: none) {
    &:hover {
      color: inherit;
    }
  }

  .mobile-link-span {
    @include respond($break-tablet) {
      display: inline;
    }

    @include book;

    display: none;
    font-size: 0.9em;
  }
}

.change-layout {
  width: 100%;
  display: flex;
  justify-content: space-between;

  div {
    margin-right: 0;

    @include respond($break-phone) {
      display: none;
    }
  }
}
