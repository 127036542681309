@import '../partials/variables';
@import '../partials/mixins';

.checkout {
  $this: &;

  @include grid-container;
  @include page-padding;

  grid-gap: 1rem;

  &__subheader {
    grid-column: span 4;
    margin-bottom: 0.5rem;
  }

  &__loader {
    height: 15rem;
    grid-column: span 4;
  }

  .cart__wrapper {
    @include custom-breakpoint-styles(grid-column, span 5, span 8 / -1, span 4 / -1);
    @include custom-breakpoint-styles(grid-row, 3, 3, 1);

    @include respond-between($break-large, $break-xl) {
      grid-column: span 5 / -1;
    }

    padding: 0;
    padding-top: 6rem;

    @include respond($break-large) {
      padding-top: 0;
    }

    .cart-item__price {
      @include body-medium;

      grid-area: 1/3/1/3;
    }

    .cart-item__image {
      grid-area: 1 / 1 / span 3 / 1;
    }

    .cart-item__button {
      @include book;

      text-decoration: underline;
      text-transform: uppercase;
      background: none;
      padding: 0;
      color: $charcoal;
    }

    .cart-item__edit {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      grid-area: 2/3;
      text-align: right;
    }

    .cart-item__remove {
      grid-area: 3/3;
    }

    .cart-item__shipping {
      grid-area: 3 / 2/ 4 /2;
    }
  }

  &__address-form {
    &-layout {
      display: grid;

      @include custom-breakpoint-styles(grid-column, span 5, 1 / span 8, 1 / span 6);
    }
  }

  &__adress-preview {
    .button {
      width: auto;
    }
  }

  &__address-lines {
    margin-bottom: 0.75rem;
  }

  &__address-line {
    display: block;
  }

  .form-fancy-wrapper {
    @include custom-breakpoint-styles(grid-column, 1 / span 4, 1 / span 8, 1/ span 8);
  }

  &-dropdowns__wrapper {
    display: grid;
    grid-template-columns: repeat(4, [col-start] minmax(0, 1fr));
    grid-gap: 1.25rem;

    @include respond($break-phone) {
      grid-gap: 2rem;
    }

    .checkout__subheader {
      @include respond($break-phone) {
        grid-column: span 2;
        grid-row: 1;
      }
    }

    .dropdown-container__selection {
      grid-column: span 4;
      padding-bottom: 1.75rem;

      @include respond($break-phone) {
        grid-column: 1 / -1;
        padding-bottom: 0;
      }
    }

    .checkbox {
      grid-column: 1 / -1;
      margin: 0;
    }

    .sales-channel {
      @include respond($break-phone) {
        grid-column: 1 / span 2;
      }
    }

    .terminal {
      @include respond($break-phone) {
        grid-column: 3 / span 2;
      }
    }
  }

  &__terminal-select {
    @include custom-breakpoint-styles(grid-column, span 5, 1 / span 8, 1 / span 6);
  }
}
