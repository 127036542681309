@import '../partials/variables';
@import '../partials/mixins';

.images {
  $this: &;

  &__inner-wrapper {
    @include components-element-max-width;
  }

  &__header-wrapper {
    @include grid-container;
    @include custom-breakpoint-styles(
      padding,
      0 1.25rem 1.25rem,
      0 1.25rem 2.5rem,
      0 1.25rem 3.5rem,
    );
  }

  &__header {
    @include custom-breakpoint-styles(
      grid-column,
      1 / span 4,
      2 / span 6,
      2 / span 10,
    );

    text-align: center;
    white-space: pre-line;

    &--narrow {
      @include custom-breakpoint-styles(
        grid-column,
        1 / span 4,
        2 / span 6,
        3 / span 8,
      );
    }
  }

  &__points-wrapper {
    @include grid-container;

    max-width: 100%;
    overflow-y: auto;
  }

  $points: &__points;

  &__points {
    @include custom-breakpoint-styles(
      padding,
      0 1.5rem,
      0 1.5rem,
      0 1.5rem,
    );
    @include custom-breakpoint-styles(
      grid-column,
      1 / span 4,
      1 / span 8,
      1 / span 12,
    );

    &--narrow {
      @include custom-breakpoint-styles(
        grid-column,
        1 / span 4,
        2 / span 6,
        3 / span 8,
      );
    }

    display: grid;

    @include custom-breakpoint-styles(column-gap, 1.5rem, 1rem, 1rem);

    row-gap: 2rem;
  }

  @for $i from 1 through 4 {
    @include generate-responsive-styles(#{&}__points--#{$i}-col-grid) {
      grid-template-columns: repeat($i, minmax(0, 1fr));
    }
  }

  @include generate-responsive-styles(#{&}__points--auto-fit-grid) {
    grid-auto-flow: column;
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  }

  &__point {
    grid-column: span 1;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    color: $charcoal;
  }

  &__point-asset {
    padding-bottom: 125%;

    @include cover-asset-container;
    @include aspect-ratio-classes;

    position: relative;
    width: 100%;

    &.top {
      margin-top: 0;
    }

    &.middle,
    &.bottom {
      margin-top: auto;
    }
  }

  &__point-description {
    margin-top: 0.75rem;
    margin-bottom: auto;

    &--center {
      text-align: center;
    }

    &--right {
      text-align: right;
    }

    &.top,
    &.middle {
      margin-bottom: auto;
    }

    &.bottom {
      margin-bottom: 0;
    }
  }

  &__point-header {
    @include heading-classes;
  }

  &__point-title {
    margin-bottom: 0.75rem;
  }

  &__point-copy {
    &:not(:last-child) {
      padding-right: 0.25rem;
    }

    &--s p { @include body-small; }
    &--m p { @include body-medium; }
    &--l p { @include body-large; }
    &--xl p { @include body-extra-large; }

    p {
      display: inline-block;
      white-space: pre-line;

      &:not(:last-child) {
        padding-right: 0.25rem;
      }
    }
  }

  strong {
    @include body-large;
    @include heavy;
  }

  &__point-link {
    @include blurb-link;

    color: $charcoal;
    text-decoration: underline;

    @include heading-classes;

    &:not(:first-child) {
      margin-top: 0.375rem;
    }

    &.call-to-action-link {
      text-decoration: none;
    }
  }
}
