@import '../partials/variables';
@import '../partials/mixins';

/* stylelint-disable selector-pseudo-element-colon-notation */
@mixin icons {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "ui-icons" !important;
}

.icon {
  @include icons;

  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-cart:before {
  content: "\e900";
}

.icon-account-logged-in:before {
  content: "\e901";
}

.icon-account:before {
  content: "\e902";
}

.icon-nav-arrow:before {
  content: "\e904";
}

.icon-expand:before {
  content: "\e905";
}

.icon-arrow-left:before,
.icon-arrow-right:before,
.icon-arrow-up:before,
.icon-arrow-down:before {
  content: "\e908";
}

.icon-play:before {
  content: "\e914";
}

.icon-close:before {
  content: "\e91f";
}

.icon-close-product-attribute-sets:before {
  content: "\e91f";

  @include respond($break-large) {
    font-size: 0.75rem;
  }
}

.icon-pause:before {
  content: "\e920";
}

.icon-bars:before {
  content: "\e909";
}

.icon-burrow:before {
  content: "\e92a";
}

.icon-instagram:before {
  content: "\e92b";
}

.icon-twitter:before {
  content: "\e92c";
}

.icon-facebook:before {
  content: "\e92d";
}

.icon-edit:before {
  content: "\e93d";
}

.icon-sms:before {
  content: "\e907";
}

.icon-next-arrow:before {
  content: "\e903";
}

.icon-crafting:before {
  content: "\e906";
}

.icon-plus:before {
  content: "\e90a";
}

.icon-counter-modifier-plus:before {
  content: "\e90c";
}

.icon-counter-modifier-big-plus:before {
  content: "\e90d";
}

.icon-counter-modifier-minus:before {
  content: "\e90b";
}

.icon-free-shipping::before {
  content: "\e94d";
}

.icon-affirm-logo::before {
  content: "\e913";
}

.icon-contained-close:before {
  content: "\e915";
}

.icon-order-lookup::before {
  content: "\e916";
}

.icon-arrow-left {
  transform: rotate(-180deg) translateX(0%);
}

.icon-arrow-up {
  transform: rotate(-90deg) translateX(0%);
}

.icon-nav-arrow {
  margin-left: 0.3rem;
  display: inherit;
  font-size: 5px;
  align-items: center;
  transform: rotate(0);
  transition: all 0.25s;

  &.active {
    transform: rotate(180deg);
    transition: all 0.25s;
  }
}
