@import '../partials/variables';
@import '../partials/mixins';

@keyframes bounce {
  0%,
  25%,
  50%,
  75%,
  100% {transform: translate(-50%, 0%);}
  40% {transform: translate(-50%, -30%);}
  60% {transform: translate(-50%, -40%);}
}

@mixin background-container() {
  padding-bottom: 25rem !important; // override inline styles

  @include respond($break-phone) {
    padding-bottom: 31.25rem !important; // override inline styles
  }

  @include respond($break-large) {
    padding-bottom: 43.125rem !important; // override inline styles
  }
}

@mixin home-hero-common {
  @include page-padding;
  position: relative;
  display: grid;
  grid-template-columns: 100%;

  &__asset {
    grid-row: 1;
    grid-column: 1;
  }

  &__body,
  &__copy {
    z-index: 1;
  }

  &__body {
    @include page-padding;
    grid-row: 2;
    grid-column: 1;
    width: calc(100% + 2.5rem);
    left: -1.25rem;
    position: relative;
    margin: auto;

    @include respond($break-phone) {
      @include custom-breakpoint-styles(padding, 0, 0, 0);
      left: 0;
      z-index: 2;
      margin: 2rem auto;
      background-color: transparent;
      max-height: 44.5rem;
      max-width: 75%;
      grid-row: 1;
    }

    @include respond($break-large) {
      width: 50%;
    }

    &.left {
      @include respond($break-phone) {
        margin-left: 3rem;
      }
    }

    &.center {
      text-align: center;
    }

    &.right {
      @include respond($break-phone) {
        margin-right: 3rem;
        text-align: right;
      }
    }

    &.top {
      @include respond($break-phone) {
        margin-top: 3rem;
      }
    }

    &.middle {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &.bottom {
      margin-bottom: 0;
      @include flex-column;
      justify-content: flex-end;
      @include respond($break-phone) {
        margin-bottom: 3rem;
      }
    }

      @include respond($break-phone) {
    }
  }

  &__button {
    @include custom-breakpoint-styles(margin-top, 1rem, 1.25rem, 1.5rem);
    @include respond-below($break-phone) {
      text-align: center;
    }
    a.button {
      width: auto;
    }
  }

  &--mid-top {
    .middle {
      @include respond($break-phone) {
        position: relative;
        top: -15%;
        left: 0;
      }
    }
  }

  &__copy {
    display: flex;
    flex-direction: column;
  }
}

.home-hero-divided,
.home-hero-single,
.home-hero-banner,
.home-hero-ts,
.home-hero-full {
  @include home-hero-common();
}

.home-hero-divided {
  .graphcms-image-wrapper {
    > div:first-child {
      padding-bottom: 100% !important; // override inline styles
      @include respond($break-phone) {
        padding-bottom: 42.86% !important; // override inline styles
      }
    }
  }

  &__body {
    color: $charcoal;

    @include respond-below($break-phone) {
      background-color: $sandstone;
    }
  }

  &__copy {
    margin: 0 2rem 1rem;

    @include respond-below($break-phone) {
      color: $charcoal;
    }

    @include respond($break-phone) {
      margin: 0 0 1rem;
    }

    h2 {
      line-height: 1.4;
    }

    h4 {
      @include book;
      @include respond-below($break-phone) {
        color: $charcoal;
      }
      margin: 1rem auto;
    }
  }

  &__copy-highlight {
    @include flex-row;
    background-color: rgba(224, 208, 160, .7);
    margin: 0 auto;
    width: 100%;

    @include respond($break-large) {
      width: 60%;
    }

    p {
      @include book;
      font-weight: 900;
      font-size: 1rem;
      @include respond($break-phone) {
        width: 30%;
        font-size: 1.5rem;
      }

      span {
        display: block;
        font-size: .875rem;
        @include respond($break-phone) {
          font-size: 1.25rem;
        }
      }
    }
  }
}

.home-hero-banner {
  position: relative;
  overflow: hidden;

  &__body {
    max-width: 100%;
    width: 80%;
    grid-row: 1;
    left: 0;
    padding: 0;
  }

  &__copy {
    h2 {
      order: 2;

      @include respond-below($break-phone) {
        font-size: 1.25rem;
        line-height: 1.5;
        margin-bottom: .75rem;
      }
      @include respond-below($break-large) {
        font-size: 1.35rem;
      }
      margin-bottom: 1rem;
    }

    h4 {
      order: 1;
    }
  }

  &__bg-video {
    position: relative;
    padding-bottom: 40%;
    @include respond($break-phone) {
      padding-bottom: 12.5%;
    }

    .video {
      left: 50%;
      height: 100%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      @include respond($break-phone) {
        min-width: 100%;
        height: auto;
      }

      .source {
        max-height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
}

.home-hero-single {
  .graphcms-image-wrapper {
    > div:first-child {
      padding-bottom: 125% !important; // override inline styles
      @include respond($break-phone) {
        padding-bottom: 72% !important; // override inline styles
      }
      @include respond($break-large) {
        padding-bottom: 56% !important; // override inline styles
      }
    }
  }

  &__body {
    grid-row: 1;
  }

  &__button {
    margin-top: 1rem;
  }
}

.home-hero-ts {
  max-width: $full-width;
  width: 100%;
  margin: auto;
  padding: 0;

  &__body {
    position: relative;
    left: 0;
    width: 100%;
    min-width: 100%;
    grid-row: 1;
  }
}

.home-hero-full {
  width: 100%;
  min-width: 100%;
  padding: 0;

  &__body {
    position: relative;
    left: 0;
    width: 100%;
    max-width: $full-width;
    grid-row: 1;
    margin: auto;
  }

  &__sales-tiers .sales-tiers__container {
    max-width: $full-width;
    margin: auto;
  }
}

.home-hero-ts,
.home-hero-full {
  &__sales-tiers {
    order: 3;
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    &::before {
      top: 0;
      left: 0;
      width: 75px;
    }
    &::after {
      top: 0;
      right: 0;
      width: calc(100% - 300px);
    }
  }

  &__bg-video {
    .video-component {
      position: relative;
      @include background-container();
    }

    .video {
      position: absolute;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__bg-image {
    .graphcms-image-wrapper {
      > div:first-child {
        @include background-container();
      }
    }
  }
}

.home-hero-full {
  @include respond($break-gigantic) {
    &__bg-image .graphcms-image-wrapper > div:first-child,
    &__bg-video .video {
      padding-bottom: 52.8125rem !important;
    }
  }
}

.groupByClassStacked {
  @include page-padding;
  @include flex-column;
  position: relative;
  > div {
    margin: 0 0 .5rem 0;
    padding: 0;
  }
}

.bouncing-arrow {
  display: none;
  @include respond($break-phone) {
    display: initial;
    position: absolute;
    z-index: 2;
    cursor: pointer;
    left: 50%;
    bottom: 5%;
    transform: translate(-50%, -50%);
    animation: bounce 2s infinite linear;
    font-size: 2rem;
  }
}
