@import '../partials/variables';
@import '../partials/mixins';

.mulberry-container {
  &:empty {
    display: none;
  }

  iframe {
    width: 100%;
  }
}

.pdp {
  &-add {
    @include flex-row;

    color: white;
    grid-column: 1 / -1;
    align-items: stretch;
    margin-bottom: 1.375rem;

    &-select {
      $select: &;

      min-width: 4.5rem;
      max-width: 4.5rem;
      height: 2rem;
      margin-right: 0.5625rem;
      color: $charcoal;

      @include respond($break-phone) {
        height: 2.8125rem;
      }

      @include book;

      font-size: 0.8125rem;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.54;
      letter-spacing: normal;
      text-align: center;
      #{$select} &__control {
        height: 100%;
        border: none;
        border-radius: 0.125rem;
        padding: 0 0.375rem 0.125rem;
        background-color: #f4f3f1;
        box-shadow: none;
        min-height: 2rem;
      }
      #{$select} &__value-container {
        padding: 0;
        margin-left: 0.3125rem;
      }
      #{$select} &__single-value {
        width: 100%;
        text-align: center;
      }
      #{$select} &__indicator-separator {
        display: none;
      }
      #{$select} &__dropdown-indicator {
        padding: 0;
        margin: 0 0.4375rem 0 0.3125rem;

        &::before {
          content: '';
          display: block;
          width: 0.375rem;
          height: 0.375rem;
          border-right: solid 0.0625rem $concrete;
          border-bottom: solid 0.0625rem $concrete;
          transform: rotate(45deg);
        }

        svg {
          display: none;
        }
      }
      #{$select} &__menu {
        z-index: 8;
      }
      #{$select} &__option {
        &:focus,
        &--is-focused {
          background-color: $seamless;
        }

        &:active,
        &--is-selected {
          color: $white;
          background-color: $charcoal;
        }
      }
    }

    &-button {
      @include heavy;
      @include flex-row;

      text-transform: uppercase;
      justify-content: space-around;
      letter-spacing: 0.0375rem;
      padding: 0;
      line-height: 2rem;

      @include respond($break-phone) {
        line-height: 2.8125rem;
      }

      &.disabled {
        cursor: not-allowed;
      }
    }
  }

  &-details {
    display: grid;
    position: initial;
    align-content: start;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 1rem;
    grid-column: 1 / -1;

    @include respond($break-tablet) {
      grid-gap: 1.25rem;
      grid-row: 3;
      grid-column: 6 / -1;
    }

    @include respond($break-large) {
      grid-column: 10 / -1;
    }

    & > * {
      grid-column: 1 / -1;
    }

    &-sales-modal {
      flex: 100%;
      margin-bottom: 0;
      line-height: 1;

      .modal-text {
        font-size: 0.75rem;
        color: $burgundy;
        text-decoration: underline;
      }
    }

    &-title {
      @include custom-breakpoint-styles(font-size, 1rem, 1.25rem, 1.65rem);

      .pdp-details-sales-modal {
        font-size: 0.875rem;
      }
    }

    .star-rating-reviews-stars {
      grid-column-end: span 7;
      padding: 0;
    }

    &-savings {
      background: $terracotta;
      color: $white;
      height: 1rem;
      font-size: 0.75rem;

      @include heavy;

      text-align: center;
      width: 8.875rem;
      border-radius: 1rem;
      font-weight: 900;
      text-transform: uppercase;
      line-height: 1rem;
    }

    &-reviews {
      &-score {
        padding-left: 6rem;
        font-size: 0.875rem;
      }

      &-link {
        font-size: 0.875rem;
        color: $charcoal75;
        cursor: pointer;
        padding-left: 0.5rem;
      }
    }

    &-pricing {
      .price-wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .original-price {
          color: $charcoal75;
          margin-right: 0.5rem;

          + .price {
            color: $burgundy;
          }
        }
      }

      h5 {
        display: inline-block;
      }
    }

    &-bundle-savings {
      @include gta-burrow;
      border-top: 1px solid $gray;
      padding-top: 1.2rem;

      .bundle-savings {
        &-subtotal,
        &-discount,
        &-total {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-bottom: 0.4em;
          font-size: 0.8125rem;
        }

        &-discount {
          color: $burgundy;
        }
      }
    }

    &-description {
      padding: 0;
      margin: 0;

      @include respond-below($break-large) {
        display: none;
      }
    }

    &-info {
      color: $charcoal;
      text-transform: none;
      padding: 0;
      margin: 0;
      margin-bottom: 0.6875rem;
      text-align: left;

      @include body-medium;

      p {
        display: contents;
        font-size: 0.75rem;
      }

      .icon-arrow-right {
        margin-right: 1rem;
      }

      &.restock {
        margin-top: 0.825rem;
      }
    }

    &-info-link,
    a.affirm-modal-trigger {
      color: $charcoal;
    }

    &-info-link,
    a.affirm-modal-trigger,
    .modal-text {
      @include book;

      border-bottom: 0.0625rem solid;

      &:hover {
        color: $charcoal;
        border-bottom: none;
      }
    }

    &.sleeper {
      .pdp-options-group.upgrades,
      .pdp-options.tufted,
      .pdp-options-sleep-kit {
        display: none;
      }
    }

    &-copy {
      @include flex-column;

      .copy-title {
        border-top: 0.0625rem solid $charcoal;
        padding: 0.5rem 0;
        font-size: 0.9rem;
        cursor: pointer;

        @include flex-row;

        justify-content: space-between;

        .icon {
          font-size: 0.5rem;

          @include transform(rotate(135deg) translate(45%, 45%));
          @include transition(all 0.5s);

          &.active {
            @include transform(rotate(0) translate(-50%, 30%));
          }
        }
      }

      .copy-section {
        display: none;
        font-size: 0.9rem;
        line-height: 1.57;
        letter-spacing: normal;
        border-top: 0.0625rem solid $charcoal;
        padding-bottom: 0.5rem;

        @include transform(translateY(-50%));
        @include transition(ease-in 1s);

        &.active {
          display: initial;

          @include transform(translateY(0%));
        }

        ul {
          list-style-type: initial;
          margin: 0.5rem 1rem;

          li {
            list-style: initial;
          }
        }
      }
    }

    .tooltip-container {
      position: absolute;
      top: 25%;
      left: 0;
      background-color: $white;
      display: grid;
      grid-template-columns: repeat(10, [col-start] minmax(0, 1fr));
      grid-template-rows: auto;
      z-index: 2;
      line-height: 1.2rem;

      @include respond($break-phone) {
        max-width: 20rem;
        min-width: 15rem;
        left: initial;
        left: 10%;
        top: 15%;
        z-index: 2;
      }

      &-image {
        @include object-fill-image(100%);

        grid-row: 1;
        grid-column: col-start 1 / span 10;
        padding-bottom: 56%;
      }

      .icon-close {
        grid-row: 1;
        grid-column: col-start 10 / span 1;
        z-index: 1;
        font-size: 1rem;
        position: relative;
        top: 1rem;
        right: 0.5rem;
        cursor: pointer;
      }

      h4 {
        grid-row: 2;
        grid-column: col-start 2 / span 8;
        font-size: 1rem;
        padding: 0.5rem 0;
      }

      &-copy {
        grid-row: 3;
        grid-column: col-start 2 / span 8;
        font-size: 0.875rem;
        line-height: 1.2rem;
        padding-bottom: 0.75rem;

        span {
          font-size: 0.875rem;
          line-height: 1.2rem;
        }

        .bundle {
          display: flex;
          justify-content: space-between;
        }
      }
    }

    &-add-to-cart {
      .pdp-details-info {
        display: flex;
        border-bottom: 1px solid $charcoal20;
        margin-top: 1.75rem;
        padding-bottom: 0.5rem;
        width: 100%;
      }

      .icon-free-shipping {
        margin-right: 1rem;
        display: inline-block;

        &::before {
          font-size: 0.9375rem;
        }
      }

      .affirm-line {
        display: flex;
        border-bottom: 1px solid $charcoal20;
        padding-bottom: 0.6875rem;

        .icon-affirm-logo {
          margin-right: 0.9375rem;

          &::before {
            font-size: 0.875rem;
          }
        }
      }
    }

    &-shipping-message {
      align-items: center;
      color: $charcoal;
      padding-bottom: 0.25rem;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
        cursor: pointer;
      }
    }
  }

  .pdp-image {
    padding-bottom: 100%;
    pointer-events: none;
    background-size: cover;
    background-position-x: 50%;

    @include respond($break-phone) {
      pointer-events: auto;
      padding-bottom: 66%;
    }

    &-position {
      position: relative;
      grid-column-start: auto;
      grid-column-end: span 3;

      &:nth-child(3) {
        @include respond($break-phone) {
          grid-column-end: span 1;

          .pdp-image {
            height: 100%;
            padding-bottom: 133%;
          }
        }
      }

      &:nth-child(4) {
        @include respond($break-phone) {
          grid-column-end: span 2;
        }
      }

      p {
        padding: 0;
        margin: 0;
        text-align: center;
        bottom: 10%;
        left: 0;
        right: 0;
        position: absolute;
      }

      .badge-container {
        @include custom-breakpoint-styles(left, 1rem, 1.5rem, 2rem);
        @include custom-breakpoint-styles(top, 1rem, 1.5rem, 2rem);

        position: absolute;
        z-index: 1;

        .call-to-action {
          @include body-large;
          @include book;
          @include heavy;

          color: $burgundy;
          padding: 0.125rem 0.5rem;
          background: $seamless;
          border-radius: 0.625rem;
        }
      }
    }

    &-actions {
      @include flex-row;

      position: absolute;
      top: 1rem;
      left: 1rem;
      z-index: 3;

      .image-action {
        cursor: pointer;
        margin-left: 2.5rem;

        &:hover {
          border-bottom: 0.0625rem solid $charcoal;
        }
      }
    }
  }
}
