@import '../partials/variables';
@import '../partials/mixins';

.product-carousel-slider {
  &__wrapper {
    overflow: hidden;
    margin: 1.25rem 0;

    @include respond($break-phone) {
      margin: 1.5rem 0;
    }

    @include respond($break-large) {
      margin: 2rem 0;
    }
  }

  transform: translateX(100%);

  @include transition(ease-out 1s);

  &--transition {
    transform: translateX(0%);
  }

  &__scroll {
    position: relative;
    width: 100%;

    .product-card {
      display: inline-block;
      width: 100%;
    }

    .product-card,
    .lifestyle-card,
    .content-card {
      @include custom-breakpoint-styles(
        padding,
        0 0 0 $horizontal-page-padding-phone,
        0 0 0 $horizontal-page-padding-tablet,
        0 0 0 $horizontal-page-padding-desktop
      );

      @include respond($full-width + rem2px($horizontal-page-padding-desktop)) {
        padding: 0 2rem 0 0;
      }
    }

    &.narrow {
      .slick-list {
        @include respond($break-large) {
          width: calc(8 / 12 * 100%);
          margin-left: auto;
          margin-right: auto;
        }
      }

      .product-carousel-slider__arrow {
        &--right {
          @include respond($break-large) {
            right: 6rem;
          }

          @include respond($full-width + rem2px($horizontal-page-padding-desktop)) {
            right: 12rem;
          }
        }

        &--left {
          @include respond($break-large) {
            left: 8rem;
          }

          @include respond($full-width + rem2px($horizontal-page-padding-desktop)) {
            left: 10rem;
          }
        }
      }
    }
  }
}
