@use 'sass:math';

@import './legacy-mixins';

@mixin gta-burrow {
  font-family: 'GT America Burrow';
}

@mixin book {
  font-family: 'Neuzeit S LT W01 Book';
}

@mixin heavy {
  font-family: 'NeuzeitSLTW01-BookHeavy';
}

@mixin h1 {
  @include gta-burrow;

  font-size: 1.375rem;
  line-height: 2rem;
  letter-spacing: -0.3px;

  @include respond($break-phone) {
    font-size: 1.5rem;
    line-height: 2.125rem;
    letter-spacing: -0.5px;
  }

  @include respond($break-large) {
    font-size: 2.125rem;
    line-height: 2.75rem;
    letter-spacing: -0.7px;
  }
}

@mixin h2 {
  @include gta-burrow;

  font-size: 1.125rem;
  line-height: 1.6875rem;
  letter-spacing: 0.2px;

  @include respond($break-phone) {
    font-size: 1.25rem;
    line-height: 1.75rem;
    letter-spacing: -0.3px;
  }

  @include respond($break-large) {
    font-size: 1.875rem;
    line-height: 2.5rem;
    letter-spacing: -0.5px;
  }
}

@mixin h3 {
  @include gta-burrow;

  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0;

  @include respond($break-phone) {
    font-size: 1.125rem;
    line-height: 1.625rem;
    letter-spacing: -0.3px;
  }

  @include respond($break-large) {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

@mixin h4 {
  @include gta-burrow;

  font-size: 0.8125rem;
  line-height: 1.3125rem;
  letter-spacing: 0;

  @include respond($break-phone) {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  @include respond($break-large) {
    font-size: 1.125rem;
    line-height: 1.6875rem;
    letter-spacing: 0.3px;
  }
}

@mixin h5 {
  @include gta-burrow;

  font-size: 0.6875rem;
  line-height: 1.125rem;
  letter-spacing: 0;

  @include respond($break-phone) {
    font-size: 0.8125rem;
    line-height: 1.3125rem;
  }

  @include respond($break-large) {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

@mixin h6 {
  @include gta-burrow;

  font-size: 0.875rem;
  line-height: 2rem;
  letter-spacing: 0;
}

@mixin heading-classes {
  &.h1 { @include h1; }
  &.h2 { @include h2; }
  &.h3 { @include h3; }
  &.h4 { @include h4; }
  &.h5 { @include h5; }
  &.h6 { @include h6; }
}

@mixin small-uppercase-header {
  @include gta-burrow;

  font-size: 0.5625rem;
  font-weight: 500;
  line-height: 1.56;
  letter-spacing: 0.75px;
  text-transform: uppercase;

  @include respond($break-phone) {
    font-size: 0.75rem;
    line-height: 1.63;
    letter-spacing: 0.81px;
  }

  @include respond($break-large) {
    font-size: 0.875rem;
    line-height: 1.71;
    letter-spacing: 0.88px;
  }
}

@mixin body-extra-large {
  @include respond($break-phone) {
    font-size: 1.125rem;
    line-height: 1.625rem;
    letter-spacing: 0;
  }
}

@mixin body-large {
  font-size: 0.8125rem;
  line-height: 1.125rem;
  letter-spacing: 0;

  @include respond($break-phone) {
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.3px;
  }
}

@mixin body-medium {
  font-size: 0.6875rem;
  line-height: 1rem;
  letter-spacing: 0;

  @include respond($break-phone) {
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 0;
  }
}

@mixin button-font-style {
  font-size: 0.6875rem;
  line-height: 1rem;

  @include respond($break-phone) {
    letter-spacing: 0.5px;
    line-height: 1rem;
  }

  @include respond($break-large) {
    font-size: 0.875rem;
    letter-spacing: 0.7px;
    line-height: 1.5rem;
  }
}

@mixin body-small {
  font-size: 0.5625rem;
  line-height: 0.875rem;
}

@mixin label-small {
  font-size: 0.5625rem;

  @include respond($break-phone) {
    font-size: 0.75rem;
  }
}

@mixin tiny-caps {
  @include gta-burrow;

  font-size: 0.5rem;
  line-height: 0.75rem;
  text-transform: uppercase;
}

@mixin badge-text {
  @include tiny-caps;

  margin: 0 auto;
  border-radius: 0.625rem;
}

@mixin blurb-link {
  @include gta-burrow;
  @include transition(all 0.25s);

  cursor: pointer;
  font-size: 0.5625rem;
  line-height: 1.33;
  letter-spacing: 0.5px;
  text-align: center;

  @include respond($break-phone) {
    font-size: 0.6875rem;
  }
}

@mixin p {
  @include book;
  @include body-large;
}

@mixin button-style {
  @include transition(all 0.25s);
  @include button-font-style;
  @include gta-burrow;
  @include custom-breakpoint-styles(
    padding,
    0.75em 2rem,
    0.75rem 2rem,
    0.8125rem 2rem
  );

  color: $white;
  background: $charcoal;
  border-radius: 0.125rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  cursor: pointer;
  letter-spacing: 0.5px;
  width: 100%;

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;

    &:hover {
      opacity: 0.65;
    }
  }
}

@mixin animation($animation: all 0.2s ease-in-out) {
  -webkit-animation: $animation;
  -moz-animation: $animation;
  -o-animation: $animation;
  animation: $animation;
}

@mixin transition($transition: all 0.2s ease-in-out) {
  -webkit-transition: $transition;
  -moz-transition: $transition;
  -o-transition: $transition;
  transition: $transition;
}

// SPACING & ALIGNMENT
@mixin grid-container() {
  display: grid;
  column-gap: 1.5rem;
  grid-template-columns: repeat(4, minmax(0, 1fr));

  @include respond($break-phone) {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  @include respond($break-large) {
    column-gap: 1.25rem;
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

@mixin flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

@mixin full-screen-background-ratio() {
  padding-bottom: 125% !important; // override inline styles

  @include respond($break-phone) {
    padding-bottom: 50% !important; // override inline styles
  }
}

@mixin square-aspect-ratio() {
  padding-bottom: 100% !important;
}

@mixin page-padding() {
  padding: $vertical-page-padding-phone $horizontal-page-padding-phone;
  max-width: $full-width;
  margin: 0 auto;

  @include respond($break-phone) {
    padding: $vertical-page-padding-tablet $horizontal-page-padding-tablet;
  }

  @include respond($break-large) {
    padding: $vertical-page-padding-desktop $horizontal-page-padding-desktop;
  }
}

@mixin components-element-max-width() {
  max-width: $full-width;
  margin: 0 auto;
}

@mixin components-element-side-padding() {
  @include custom-breakpoint-styles(
    padding,
    0 $horizontal-page-padding-phone,
    0 $horizontal-page-padding-tablet,
    0 $horizontal-page-padding-desktop
  );
}

@mixin cover-asset-container() {
  position: relative;

  .video-component {
    position: absolute;
    width: 100%;
    height: 100%;

    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .graphcms-image-outer-wrapper {
    position: absolute !important;
    width: 100%;
    height: 100%;
  }

  .graphcms-image-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &.asset__icon {
    display: block;

    img {
      position: absolute;
      width: 100%;
      object-fit: cover;
    }
  }
}

@mixin cover-gallery-container {
  position: relative;
  width: 100%;
  height: 0;

  .swiper-container {
    position: absolute;
    width: 100%;
    height: 100%;

    .swiper-slide {
      @include cover-asset-container;
    }
  }
}

@mixin aspect-ratio-classes-for-dimension($dimension) {
  @each $aspect-ratio, $padding-bottom in $aspect-ratios {
    &.#{$dimension}_#{$aspect-ratio} {
      padding-bottom: $padding-bottom !important;
    }
  }
}

@mixin aspect-ratio-classes {
  @include aspect-ratio-classes-for-dimension('all');

  @include respond($break-phone) {
    @include aspect-ratio-classes-for-dimension('tablet');
  }

  @include respond($break-large) {
    @include aspect-ratio-classes-for-dimension('desktop');
  }
}

// MEDIA QUERIES
@mixin respond($width) {
  @media (min-width: $width) {
    @content;
  }
}

@mixin respond-below($width) {
  @media (max-width: $width - 1) {
    @content;
  }
}
//For edgecase use
@mixin respond-between($start, $end) {
  @media (min-width: $start) and (max-width: $end - 1) {
    @content;
  }
}

@mixin respond-vertical-below($height) {
  @media (max-height: $height - 1) {
    @content;
  }
}

@mixin custom-breakpoint-styles($attribute, $mobile, $tablet, $large) {
  #{$attribute}: $mobile;

  @include respond($break-phone) {
    #{$attribute}: $tablet;
  }

  @include respond($break-large) {
    #{$attribute}: $large;
  }
}

@function rem2px($rem) {
  @return (math.div($rem, 1rem)) * 16px;
}

@mixin grid-align($columns) {
  @if (math.floor(math.div(12 - $columns + 2, 2)) > 0) {
    @include respond($break-large) {
      grid-column: floor(math.div(12 - $columns + 2, 2)) / span $columns;
    }
  } @else {
    @include respond($break-large) {
      grid-column: 1 / span $columns;
    }
  }

  @if (math.floor(math.div(8 - $columns + 2, 2)) > 0) {
    @include respond-between($break-phone, $break-large) {
      grid-column: floor(math.div(8 - $columns + 2, 2)) / span $columns;
    }
  } @else {
    @include respond-between($break-phone, $break-large) {
      grid-column: 1 / span $columns;
    }
  }

  @if (math.floor(math.div(4 - $columns + 2, 2)) > 0) {
    @include respond-below($break-phone) {
      grid-column: floor(math.div(4 - $columns + 2, 2)) / span $columns;
    }
  } @else {
    @include respond-below($break-phone) {
      grid-column: 1 / span $columns;
    }
  }
}

@mixin generate-responsive-styles($className) {
  #{$className} {
    @content;
  }

  #{$className}-tablet {
    @include respond($break-phone) {
      @content;
    }
  }

  #{$className}-desktop {
    @include respond($break-large) {
      @content;
    }
  }
}

@mixin btn-hover-translate {
  &:hover {
    transform: translateY(-0.225rem);
  }
}

@mixin square($size) {
  width: $size;
  height: $size;
}
