@import '../partials/variables';
@import '../partials/mixins';

.cart {
  @include transform(translateX(100%));
  @include transition(all 0.25s);
  @include transparent-scrollbar;

  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: fixed;
  overflow-y: scroll;
  background: $white;
  z-index: 100;
  top: 0;
  right: 0;

  &__recommended-item {
    height: 10rem;
    width: 16rem;
  }

  &.is-open {
    @include transform(translateX(0));
  }

  @include respond($break-small) {
    max-width: 25rem;
  }

  &-empty {
    padding: 1rem 0;

    @include book;

    p {
      color: $charcoal;
    }
  }

  &__save-button {
    cursor: pointer;
    text-align: center;
    white-space: nowrap;

    &__text {
      border-bottom: 1px solid $charcoal;
      padding-bottom: 3px;
      text-decoration: none;
    }

    &__text:hover {
      border-bottom: none;
    }

    &__popout {
      margin-bottom: 1rem;
      width: 100%;
      background: none;
      color: $charcoal;
      font-size: 0.6875rem;
      line-height: 1rem;
      letter-spacing: 1px;

      @include respond($break-phone) {
        font-size: 0.875rem;
        letter-spacing: 1.2px;
      }

      @include respond($break-phone) {
        margin-bottom: 2.5rem;
      }
    }
  }

  &__wrapper {
    min-height: 25rem;
    position: relative;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 1fr auto;
    height: 100%;

    /* mobile viewport bug fix */
    padding-top: 0;

    @include respond-vertical-below(500px) {
      height: auto;
    }

    &-three-rows {
      grid-template-rows: auto 1fr auto;
    }

    &-spread {
      justify-content: space-between;
    }

    &-top {
      justify-content: start;
    }
  }

  &__header {
    @include flex-column;

    position: relative;
    justify-content: space-between;
    align-items: center;
    margin: 1.5625rem 1.5625rem 0 1.5625rem;
    padding-bottom: 0.25rem;
    border-bottom: 1px solid $charcoal25;

    .icon.icon-close {
      cursor: pointer;
      background: none;
      padding: 0;
    }

    &__inner-wrapper {
      @include flex-row;

      align-items: center;
      justify-content: space-between;
      width: 100%;

      &__cart-items {
        display: flex;
        justify-content: left;

        &__number {
          margin-left: 0.5rem;
          margin-top: 0.375rem;

          @include respond($break-phone) {
            margin-top: 0.4375rem;
          }

          @include respond($break-large) {
            margin-top: 0.5625rem;
          }

          @include body-medium;
        }
      }
    }
  }

  &__sale-thermometer {
    width: 100%;
    padding: 1rem 0 0.75rem;

    &-spacing {
      padding: 0.75rem 1.5rem 1.25rem 1.5rem;
    }
  }

  &__items {
    @include transparent-scrollbar;

    height: auto;
    overflow-y: scroll;

    &-spacing {
      margin: 0 1.5rem;
    }
  }

  &-recommendation__title {
    .cart-recommendation__title {
      border-bottom: 1px solid $charcoal75;
    }
  }

  &-footer {
    &-shadow {
      box-shadow: 0 -8px 24px 0 rgba($concrete, 0.25);
    }

    &--spacing {
      padding-right: 1.5rem;
      padding-left: 1.5rem;
    }

    &--tight-leading {
      line-height: 1.35;
    }

    &-inner-wrapper {
      padding-top: 1.25rem;
      position: relative;
      bottom: 0;
      width: 100%;
      background-color: $white;
      height: auto;

      .cart-footer__item {
        @include flex-row;

        justify-content: space-between;

        &.bundle-savings,
        &.sale-savings {
          color: $burgundy;
        }
      }

      .cart-footer__item-bold {
        @include heavy;
      }

      .cart-footer__item-padded {
        padding: 0.75rem 0;

        @include respond($break-phone) {
          padding: 1rem 0;
        }

        @include respond($break-large) {
          padding: 1.25rem 0;
        }
      }

      .cart-footer__item-promo-code {
        color: $burgundy;

        .code {
          @include body-medium;
          @include heavy;

          color: $burgundy;
          background: none;
          padding: 0;

          &-clickable {
            text-decoration: underline;
            cursor: pointer;
          }
        }

        .remove-code {
          @include body-medium;

          background: none;
          padding: 0;
          cursor: pointer;
          margin-left: 0.5rem;
          color: $charcoal50;
          text-decoration: underline;

          &.disabled {
            cursor: not-allowed;
          }
        }
      }
    }

    &__buttons-row {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-column-gap: 2rem;

      button {
        padding-left: 0;
        padding-right: 0;
      }
    }

    &-button {
      margin-bottom: 1rem;

      .button {
        @include btn-hover-translate;

        width: 100%;
      }
    }
  }
}

.promo {
  position: relative;

  &__form {
    width: 100%;
    padding: 0.25rem 0;

    .form-fancy {
      display: flex;
      border: 1px solid $charcoal25;
      border-radius: 2px;

      input {
        padding: 0.625rem;
      }
    }

    &-click {
      @include heavy;

      cursor: pointer;
      text-transform: uppercase;
      padding: 0 2rem;
      background: $white;
      border-left: 1px solid $charcoal25;
      color: $heathercharcoal;

      &.disabled {
        cursor: not-allowed;
      }
    }
  }

  &__form-hide {
    position: absolute;
    bottom: 0;
    z-index: -1;
  }

  &__form-show {
    position: relative;
  }

  &__title {
    cursor: pointer;
    background: none;
    padding: 0;
    padding-top: 0.5rem;

    &--heathercharcoal {
      color: $heathercharcoal;
    }

    &--terracotta {
      color: $terracotta;
    }

    &--burgundy {
      color: $burgundy;
    }
  }

  &__input {
    border-radius: 100%;
  }
}
