@font-face {
  font-family: "GT America Light";
  src:
    url("../../fonts/gt-america-light/GT-America-Extended-Light.woff2") format("woff2"),
    url("../../fonts/gt-america-light/GT-America-Extended-Light.woff") format("woff"),
    url("../../fonts/gt-america-light/GT-America-Extended-Light.ttf") format("truetype");
  font-display: swap;
}
.about-this-piece {
  &-wrapper {
    grid-column: 1 / -1;
    @include grid-wrapper;
    @include padding-clear;
    margin: 0;
    overflow: hidden;
    max-height: 0;
    transition: max-height .5s ease-out;
    @include respond-below($break-phone) {
      grid-gap: 0;
      &.active {
        max-height: 100rem;
        transition: max-height .3s ease-out;
      }
    }
    @include respond($break-phone) {
      max-height: none;
      overflow: initial;
    }
    &-description {
      font-family: 'GT America Light';
      grid-row: 2;
      grid-column: 1 / -1;
      color: $charcoal75;
      @include respond($break-phone) {
        grid-row: 1;
        grid-column: 2 / -2;
      }
      @include respond($break-large) {
        grid-column: 2 / span 10;
      }
      h4 {
        margin-top: .75rem;
      }
    }
    &-section {
      margin: 1rem 0;
      @include respond($break-large) {
        margin-top: 0;
        width: 20rem;
      }
      .header {
        padding-bottom: 0.5rem;
      }
      .description {
        color: $charcoal75;
      }
      &-image {
        padding-bottom: 1rem;
      }
    }
    &-sections {
      grid-row: 3;
      grid-column: 1 / -1;
      flex-wrap: wrap;
      @include respond($break-phone) {
        grid-row: 2;
        grid-column: 2 / -2;
      }
      @include respond($break-large) {
        grid-column: 2 / span 10;
      }
      display: flex;
      justify-content: space-between;
    }
  }
}
