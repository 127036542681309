@import '../partials/variables';
@import '../partials/mixins';

.align {
  &-left {
    text-align: left;
  }

  &-center {
    text-align: center;
  }

  &-right {
    text-align: right;
  }
}

.call-to-action {
  @include badge-text;
}

:global(.product-card__badge-wrapper-relative) {
  .call-to-action {
    @include badge-text;

    height: min-content;
    width: min-content;
    white-space: nowrap;
    padding: 0.125rem 0.5rem;
  }
}

:global(.header-menu-mobile) {
  .call-to-action {
    margin-left: 1rem;
  }
}

:global(.badge-container) {
  .call-to-action {
    @include body-large;
    @include book;
    @include heavy;

    color: $burgundy;
    padding: 0.125rem 0.5rem;
    background: $seamless;
    border-radius: 0.625rem;
  }
}
