@import '../partials/variables';
@import '../partials/mixins';

.shop-by-spaces {
  width: 100%;
  margin: 3.75rem 0 3.25rem;
  @include respond($break-phone) {
    margin: 3.75rem 0 7rem;
  }

  &__header {
    @include grid-container;
    @include components-element-max-width;
    @include components-element-side-padding;

    padding-bottom: 2rem;
    @include respond($break-phone) {
      padding-bottom: 3.125rem;
    }

    h2 {
      grid-column: 1 / span 4;
      grid-row: 1;
      text-align: center;
      white-space: pre-line;
      color: $charcoal;

      @include respond($break-phone) {
        grid-column: 1 / span 8;
      }

      @include respond($break-large) {
        grid-column: 3 / span 8;
      }
    }
  }

  .slick-slider {
    .slick-dots {
      bottom: 5%;
      transform: translateY(50%);
      pointer-events: none;
      z-index: 2;

      li {
        width: $carousel-dot-width-wide;
        height: $carousel-dot-height;
        margin: 0 0.125rem;
        pointer-events: all;

        button {
          width: $carousel-dot-width-wide;
          height: $carousel-dot-height;
          margin: 0;
          padding: 0;

          &::before {
            opacity: 0.5;
            content: '';
            background: $white;
            width: $carousel-dot-width-wide;
            height: $carousel-dot-height;
          }

          &:hover {
            &::before {
              opacity: 1;
            }
          }
        }

        &.slick-active {
          button {
            &::before {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  &__spaces-container {
    position: relative;
  }

  &__space-wrapper {
    &:focus {
      outline: none;
    }
  }
}
