@import '../partials/variables';
@import '../partials/mixins';

.hero-general {
  position: relative;
  text-align: center;
  grid-column: col-start 1 / span 16;
  grid-row: 1;

  @include object-fill-image(100%);

  a {
    text-decoration: underline;
  }

  &-video {
    padding-bottom: 100%;

    @include respond($break-medium) {
      padding-bottom: 56%;
    }

    &-container {
      width: 200%;
      position: relative;
      left: -50%;

      @include respond($break-medium) {
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }
  }
  &-headers {
    h4 {
      font-weight: 600;
      padding: 1rem;
    }

    &__subheader {
      display: block;
      padding: 1rem;

      @include respond($break-medium) {
        padding: 2.5rem;
      }
    }

    &__copy {
      display: block;
      padding-bottom: 1.5rem;

      @include heavy;

      @include respond($break-phone) {
        padding-bottom: 2rem;
      }
    }

    .button {
      text-decoration: none;
      max-width: 15.625rem;
    }

    .outline {
      text-align: center;
    }
  }
}
