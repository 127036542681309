@mixin gta-burrow {
  font-family: 'GT America Burrow';
}

@mixin book {
  font-family: 'Neuzeit S LT W01 Book';
}

@mixin heavy {
  font-family: 'NeuzeitSLTW01-BookHeavy';
}

@mixin h1 {
  @include gta-burrow;

  font-size: 1.75rem;
  letter-spacing: -0.4px;
  line-height: 1.71;

  @include respond($break-large) {
    font-size: 3.375rem;
    line-height: 1.11;
    letter-spacing: -0.8px;
  }

  &.shadow {
    text-shadow: 0 0 100px rgba(0, 10, 0, 0.75);
  }
}

@mixin h2 {
  @include gta-burrow;

  font-size: 1.75rem;
  letter-spacing: -0.4px;
  line-height: 1.71;

  @include respond($break-large) {
    font-size: 2.25rem;
    line-height: 1.11;
    letter-spacing: -0.6px;
  }
}

@mixin h3 {
  @include gta-burrow;

  font-size: 1.25rem;
  line-height: 1.5;
  letter-spacing: normal;

  @include respond($break-phone) {
    font-size: 1.625rem;
    letter-spacing: -0.4px;
    line-height: 1.71;
  }
}

@mixin h4 {
  @include gta-burrow;

  font-size: 1rem;
  line-height: 1.6;
  letter-spacing: normal;

  @include respond($break-phone) {
    font-size: 1.25rem;
    line-height: 1.6;
  }
}

@mixin h5 {
  @include gta-burrow;

  @include respond($break-phone) {
    font-size: 1rem;
    line-height: 1.6;
    letter-spacing: normal;
  }
}

@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

@mixin responsive-image($url) {
  // $url: str-replace($url, '.jpg', '.webp' );
  // $url: str-replace($url, '.png', '.webp' );
  background-image: url('#{$url}?size=576');

  @include respond($break-small) {
    background-image: url('#{$url}?size=768');
  }

  @include respond($break-medium) {
    background-image: url('#{$url}?size=992');
  }

  @include respond($break-large) {
    background-image: url('#{$url}?size=1200');
  }

  @media
    only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min--moz-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2/1),
    only screen and (min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
    background-image: url('#{$url}?size=992');

    @include respond($break-small) {
      background-image: url('#{$url}?size=1200');
    }

    @include respond($break-medium) {
      background-image: url('#{$url}?size=1800');
    }

    @include respond($break-large) {
      background-image: url('#{$url}?size=2400');
    }
  }
}

@mixin transform($transform) {
  -ms-transform: $transform; /* IE 9 */
  -webkit-transform: $transform; /* Chrome, Safari, Opera */
  transform: $transform;
}

@mixin transition($transition: all 0.2s ease-in-out) {
  -webkit-transition: $transition;
  -moz-transition: $transition;
  -o-transition: $transition;
  transition: $transition;
}

@mixin transparent-scrollbar() {
  -ms-overflow-style: none;

  ::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
  }
}

@mixin page-padding() {
  max-width: $full-width;
  margin: 0 auto;
  padding: $vertical-page-padding-phone $horizontal-page-padding-phone;

  @include respond($break-phone) {
    padding: $vertical-page-padding-tablet $horizontal-page-padding-tablet;
  }

  @include respond($break-large) {
    padding: $vertical-page-padding-desktop $horizontal-page-padding-desktop;
  }
}

@mixin grid-container() {
  // Our old grid container has issues: https://css-tricks.com/css-grid-in-ie-css-grid-and-the-new-autoprefixer/#article-header-id-3
  display: grid;
  column-gap: 1.5rem;
  grid-template-columns: repeat(4, minmax(0, 1fr));

  @include respond($break-phone) {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  @include respond($break-large) {
    column-gap: 1.25rem;
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
}

@mixin grid-wrapper() {
  @include page-padding();

  max-width: $full-width;
  display: grid;
  -ms-grid-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  grid-template-columns: repeat(4, [col-start] minmax(0, 1fr));
  grid-gap: 1.6rem;
  margin: 0 auto;

  @include respond($break-phone) {
    -ms-grid-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    grid-template-columns: repeat(8, [col-start] minmax(0, 1fr));
    grid-template-rows: 1fr;
  }

  @include respond($break-large) {
    -ms-grid-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    grid-template-columns: repeat(12, [col-start] minmax(0, 1fr));
    grid-gap: 2rem;
  }
}

@mixin section-padding() {
  padding: 1.875rem 1.5rem;

  @include respond($break-phone) {
    padding: 2.5rem 1.5rem;
  }

  @include respond($break-large) {
    padding: 3.75rem 2rem;
  }
}

@mixin custom-breakpoint-styles($attribute, $mobile, $tablet, $large) {
  #{$attribute}: $mobile;

  @include respond($break-phone) {
    #{$attribute}: $tablet;
  }

  @include respond($break-large) {
    #{$attribute}: $large;
  }
}

@mixin section-margin() {
  margin: 2.75rem auto;

  @include respond($break-large) {
    margin: 3.75rem auto;
  }
}

@mixin padding-clear() {
  padding: 0;

  @include respond($break-phone) {
    padding: 0;
  }

  @include respond($break-large) {
    padding: 0;
  }
}

@mixin selector-drop() {
  box-shadow: 0 4px 10px -10px transparentize($charcoal, 0.15);
}

@mixin animation($animation: all 0.2s ease-in-out) {
  -webkit-animation: $animation;
  -moz-animation: $animation;
  -o-animation: $animation;
  animation: $animation;
}

@mixin keyframes($name, $from, $to) {
  @-webkit-keyframes #{$name} {
    from { -webkit-transform: $from; }
    to { -webkit-transform: $to; }
  }

  @-moz-keyframes #{$name} {
    from { -moz-transform: $from; }
    to { -moz-transform: $to; }
  }

  @-o-keyframes #{$name} {
    from { -o-transform: $from; }
    to { -o-transform: $to; }
  }

  @keyframes #{$name} {
    from { transform: $from; }
    to { transform: $to; }
  }
}

@mixin linear-gradient($top, $bottom) {
  background: $top; /* Old browsers */
  background: -moz-linear-gradient(top, $top 0%, $bottom 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, $top), color-stop(100%, $bottom)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, $top 0%, $bottom 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, $top 0%, $bottom 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, $top 0%, $bottom 100%); /* IE10+ */
  background: linear-gradient($top 0%, $bottom 100%); /* W3C */
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#ffffff', endColorstr='#000000', GradientType=0); /* IE6-9 */
}

@mixin fullcolor() {
  // -webkit-filter: grayscale(0%);
  -webkit-filter: none;
  -moz-filter: grayscale(0%);
  -ms-filter: grayscale(0%);
  -o-filter: grayscale(0%);
  filter: grayscale(0%);
  // filter: url(grayscale.svg); /* Firefox 4+ */
  filter: none; /* IE 6-9 */
}

@mixin respond-retina() {
  @media (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
    @content;
  }
}

//// Media Query Mixins ////
@mixin respond($width) {
  @media (min-width: $width) {
    @content;
  }
}

@mixin respond-below($width) {
  @media (max-width: $width - 1) {
    @content;
  }
}

@mixin respond-between($start, $end) {
  @media (min-width: $start) and (max-width: $end - 1) {
    @content;
  }
}

@mixin object-fill-image($height) {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  width: 100%;
  height: $height;
}

@mixin div-image($image, $width, $height, $size: cover, $repeat: no-repeat, $position: 50% 50%) {
  padding-bottom: ($height / $width) * 100%;
  background-size: $size;
  background-image: url($image);
  background-repeat: $repeat;
  background-position: $position;
}

@mixin respond-width-height($start, $end) {
  @media (min-width: $start) and (min-height: $end) {
    @content;
  }
}

@mixin vertical-respond-below($height) {
  @media (max-height: $height - 1) {
    @content;
  }
}

@mixin vertical-respond($height) {
  @media (min-height: $height + 1) {
    @content;
  }
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

@mixin flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
