@import '../partials/variables';
@import '../partials/mixins';

.color-picker {
  background-color: rgba(255, 255, 255, 0.75);
  margin-top: 0.625rem;
  color: $charcoal;
  display: flex;
  align-items: center;

  .link-more-options {
    font-size: 0.75rem;
    color: $charcoal50;
  }

  &-container {
    @include flex-row;

    align-items: center;
    justify-content: flex-start;
    color: $charcoal;
    padding: 0 0.313rem;
    margin-right: 0.5rem;

    > * {
      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      margin: 0.25rem 0.3125rem;
    }
  }

  &-closed-copy {
    margin-right: 0.675rem;
  }

  &-color {
    $activeBorderOffset: -0.3125rem;

    border-radius: 50%;
    display: inline-block;
    position: relative;
    cursor: pointer;
    width: 1.125rem;
    height: 1.125rem;
    margin: 0.375rem 1rem 0.438rem 0;

    &::after {
      position: absolute;
      content: "";
      border-radius: 50%;
      left: -0.125rem;
      top: -0.125rem;
      width: 1.25rem;
      height: 1.25rem;
    }

    &.active,
    &:hover {
      width: 1.125rem;
      height: 1.125rem;

      @include transition(all 0.25s);

      &::after {
        content: "";
        position: absolute;
        left: $activeBorderOffset;
        top: $activeBorderOffset;
        width: 1.625rem;
        height: 1.625rem;
        border: 0.0625rem solid $charcoal;
        border-radius: 50%;
        box-shadow: 0 0.25rem 0.3125rem -0.125rem rgba(71, 69, 67, 0.3);

        @include transition(all 0.25s);
      }
    }

    &.has-border,
    &.IV,
    &.WT {
      border: 0.0625rem solid $charcoal25;

      &:hover,
      &.active {
        &::after {
          top: $activeBorderOffset - 0.0625rem;
          left: $activeBorderOffset - 0.0625rem;
        }
      }
    }

    @each $pdpcolor, $color in $colors {
      &.#{$pdpcolor} {
        background: $color;
      }
    }
  }
}
