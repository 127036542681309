@import '~swiper/swiper';
@import '~swiper/components/effect-fade/effect-fade';
@import '../partials/variables';
@import '../partials/mixins';

.card .non-slider-card .swiper-container {
  position: absolute;
  width: 100%;
  height: 100%;
}

.card .non-slider-card .card__slider .swiper-pagination-bullet {
  padding-left: 0.25rem;
  padding-right: 0.25rem;

  &::after {
    height: 0.125rem;
    width: 3rem;
  }
}

.shop-by-spaces,
.design-features {
  .slider__arrow {
    bottom: 50%;
  }
}

.shop-theme,
.product-carousel {
  .slider__arrow {
    transform: translateY(-50%);

    &--left {
      background: url('../../assets/ui/left-arrow.svg');
    }

    &--right {
      background: url('../../assets/ui/left-arrow.svg');
      transform: rotate(180deg) translateY(50%);
    }
  }
}

.shop-theme,
.product-carousel,
.shop-by-spaces,
.design-features {
  .slider__arrow {
    position: absolute;
    z-index: 2;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    font-size: 2.5rem;
    font-weight: 900;
    color: var(--theme-color, $heathercharcoal);
    cursor: pointer;

    @include respond-below($break-phone) {
      display: none;
    }

    i.icon {
      display: block;
      cursor: pointer;
    }

    &::after {
      display: block;
      content: "";
      position: absolute;
      width: 0;
      height: 100%;
    }
  }
}

.storycard,
.pdp-images-carousel {
  .slider__arrow {
    position: absolute;
    z-index: 2;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    font-size: 2.5rem;
    font-weight: 900;
    color: $heathercharcoal;
    cursor: pointer;

    @include respond-below($break-phone) {
      display: none;
    }

    i.icon {
      display: block;
      cursor: pointer;
    }

    &::after {
      display: block;
      content: '';
      position: absolute;
      width: 0;
      height: 100%;
    }
  }
}

.design-features {
  .slider__arrow {
    display: none !important; // override inline-styles

    @include respond($break-phone) {
      display: block !important; // override inline-styles
    }

    bottom: 50%;
    z-index: 1;
    margin: 0 1.25rem;
    padding: 0;

    &--left {
      left: 0;
    }

    &--right {
      right: 50%;
    }
  }
}

.shop-theme,
.product-carousel,
.shop-by-spaces {
  .slider__arrow {
    height: 2.5rem;
    width: 2.5rem;

    @include respond($break-large) {
      width: 3rem;
      height: 3rem;
    }

    &--left {
      @include transition(left 0.25s);

      left: 1.5rem;

      &:hover {
        left: 1.25rem;

        &::after {
          width: 0.25rem;
          left: 100%;
        }
      }

      @include respond($break-large) {
        left: 2rem;

        &:hover {
          left: 1.5rem;

          &::after {
            width: 0.5rem;
          }
        }
      }
    }

    &--right {
      @include transition(right 0.25s);

      right: 1.5rem;

      &:hover {
        right: 1.25rem;

        &::after {
          width: 0.25rem;
          right: 100%;
        }
      }

      @include respond($break-large) {
        right: 2rem;

        &:hover {
          right: 1.5rem;

          &::after {
            width: 0.5rem;
            right: 100%;
          }
        }
      }
    }
  }
}

.cart__recommended-container {
  .slider__arrows-container {
    display: flex;
    margin-left: 1.5rem;
    margin-top: 1rem;
  }

  .slider__arrow {
    @include respond-below($break-phone) {
      display: none;
    }

    opacity: 0.85;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    border: 1px solid #D1D0D0;
    background: white;
    backdrop-filter: blur(8px);
    margin-bottom: 1rem;

    .icon {
      font-size: 0.75rem;
      line-height: 1.75rem;
      opacity: 0.75;
      -webkit-font-smoothing: antialiased;
      font-weight: 900;
      border: solid black;
      border-width: 0 0.15rem 0.15rem 0;
      display: inline-block;
      padding: 3px;

      &-arrow-left {
        margin-left: 0.7rem;
        margin-top: 0.7rem;
      }

      &-arrow-right {
        margin-right: 0.125rem;
        margin-bottom: 0.125rem;
        margin-left: 0.6rem;
        margin-top: 0.6rem;
      }
    }

    /* stylelint-disable-next-line no-descending-specificity */
    &--right {
      bottom: 0;
      right: 0;
      margin-right: 0;
      margin-left: 0.5rem;
      transition: background-color 0.3s ease;
      -webkit-transform: rotate(-45deg);
    }

    &--right:hover {
      opacity: 1;
    }

    &--right:active {
      background-color: #f4f3f1;
    }

    &--right:focus {
      outline: 1px solid black;
    }

    /* stylelint-disable-next-line no-descending-specificity */
    &--left {
      bottom: 0;
      margin-left: 0;
      margin-right: 0;
      transition: background-color 0.3s ease;
      -webkit-transform: rotate(315deg);

      .icon {
        transform: rotate(-180deg);
      }
    }

    &--left:hover {
      opacity: 1;
    }

    &--left:active {
      background-color: #f4f3f1;
    }

    &--left:focus {
      outline: 1px solid black;
    }
  }
}

.pdp-images-carousel {
  .slider__arrows-container {
    display: flex;
    justify-content: flex-end;
  }

  .slider__arrow {
    opacity: 0.85;
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    background: white;
    backdrop-filter: blur(8px);
    margin-bottom: 1rem;

    .icon {
      font-size: 0.75rem;
      line-height: 1.75rem;
      opacity: 0.75;
      -webkit-font-smoothing: antialiased;
      font-weight: 900;
      border: solid black;
      border-width: 0 0.1rem 0.1rem 0;
      display: inline-block;
      padding: 6px;

      &-arrow-left {
        margin-left: 0.125rem;
        margin-top: 0.125rem;
      }

      &-arrow-right {
        margin-right: 0.125rem;
        margin-bottom: 0.125rem;
      }
    }

    /* stylelint-disable-next-line no-descending-specificity */
    &--right {
      bottom: 0;
      right: 0;
      margin-right: 1rem;
      transition: background-color 0.3s ease;
      -webkit-transform: rotate(-45deg);
    }

    &--right:hover {
      opacity: 1;
    }

    &--right:active {
      background-color: #f4f3f1;
    }

    &--right:focus {
      outline: 1px solid black;
    }

    /* stylelint-disable-next-line no-descending-specificity */
    &--left {
      bottom: 0;
      margin-left: 80%;
      margin-right: 4.5rem;
      transition: background-color 0.3s ease;
      -webkit-transform: rotate(315deg);

      .icon {
        transform: rotate(-180deg);
      }
    }

    &--left:hover {
      opacity: 1;
    }

    &--left:active {
      background-color: #f4f3f1;
    }

    &--left:focus {
      outline: 1px solid black;
    }
  }
}

.card .non-slider-card .slider {
  .slider__arrows-container {
    display: flex;
    justify-content: flex-end;
  }

  .slider__arrow {
    opacity: 85%;
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    background: white;
    margin-bottom: 1rem;
    position: absolute;
    z-index: 2;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    font-size: 2.5rem;
    color: $charcoal;
    cursor: pointer;

    .icon {
      font-size: 0.75rem;
      line-height: 1.75rem;
      opacity: 0.75;
      font-weight: 900;
      -webkit-font-smoothing: antialiased;
      border: solid black;
      border-width: 0 0.1rem 0.1rem 0;
      display: inline-block;
      padding: 6px;

      &-arrow-left {
        margin-left: 0.125rem;
        margin-top: 0.125rem;
      }

      &-arrow-right {
        margin-right: 0.125rem;
        margin-bottom: 0.125rem;
      }
    }

    &--right {
      bottom: 0;
      right: 0;
      margin-right: 2rem;
      margin-bottom: 2rem;
      transition: background-color 0.3s ease;
      -webkit-transform: rotate(-45deg);
    }

    &--left {
      bottom: 0;
      margin-left: 0.25rem;
      -webkit-transform: rotate(315deg);
      margin-right: 5.5rem;
      margin-bottom: 2rem;
      transition: background-color 0.3s ease;
    }

    &--right,
    &--left {
      &:hover {
        opacity: 1;
      }

      &:active {
        background-color: #f4f3f1;
      }

      &:focus {
        outline: 1px solid black;
      }
    }
  }
}

.non-slider-card .icon-arrow-left::before,
.non-slider-card .icon-arrow-right::before,
.cart__recommended-container .icon-arrow-left::before,
.cart__recommended-container .icon-arrow-right::before,
.pdp-images-carousel .icon-arrow-left::before,
.pdp-images-carousel .icon-arrow-right::before {
  content: "";
}

.storycard .card .non-slider-card .slider {
  .slider__arrows-container {
    display: flex;
    justify-content: flex-end;
  }

  .slider__arrow {
    opacity: 0.85;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    background: white;
    backdrop-filter: blur(6px);
    margin-bottom: 1rem;

    .icon {
      font-size: 0.75rem;
      line-height: 1.75rem;
      opacity: 0.75;
      -webkit-font-smoothing: antialiased;
      font-weight: 900;
      padding: 4px;
    }

    &--right {
      bottom: 0;
      right: 0;
      margin-right: 1rem;
    }

    &--left {
      bottom: 0;
      margin-left: 80%;
      margin-right: 3.5rem;

      .icon {
        transform: rotate(-180deg);
      }
    }

    &--right:hover {
      opacity: 1;
    }

    &--left:hover {
      opacity: 1;
    }
  }
}
