.display {
  opacity: 1;
  z-index: 10;
  @include transition(ease-in .55s);
}

.hidden {
  opacity: 0;
  height: 0;
  overflow: hidden;
  @include transition(all .25s);
}

.active-div {
  opacity: .5;
  pointer-events: none;
  cursor: not-allowed;
}

.auth {
  //height: calc(100vh - 3rem);
  min-height: 37.5rem;
  display: flex;
  align-items: flex-start;
  padding: 1.5rem;
  justify-content: center;
  .auth-form .form-fancy-wrapper{
    .form-fancy {
      margin: 1.1875rem 0;
    }
  }
  &-box {
    max-width: 26.25rem;
    width: 100%;
    min-height: 21.875rem;
    margin: auto;
    @include respond-below($break-medium) {
      min-height: 100%;
      height: 100%;
      overflow: hidden;
    }
    &.history {
      max-width: 63.75rem;
    }
  }
  p {
    text-align: center;
  }
  a {
    border-bottom: 1px solid $charcoal;
    padding-bottom: 2px;
  }

  &-header-wrapper {
    width: 100%;
  }

  &-form {
    &-wrapper-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: .625rem 0;
      margin-bottom: 1.25rem;
    }
    .form-fancy-wrapper {
      display: block;
    }
    &-submit {
      text-align: center;
      .btn {
        max-width: 18.75rem;
        width: 100%;
        margin: auto;
        @include respond-below($break-large) {
          margin: .625rem auto;
          width: 100%;
        }
      }
    }
  }
  .btn {
    width: 100%;
  }
  & a {
    letter-spacing: 1px;
  }
}
