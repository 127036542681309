@import "./mixins";
@import "./variables";

.actual-site {
  height: 100%;

  &.bx-active {
    @include respond($break-phone) {
      padding-top: 2rem;
    }
  }
}

.overflow-hidden {
  overflow: hidden;
}

.disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.center {
  text-align: center;
}

.centerOnPhone {
  text-align: center;

  @include respond($break-phone) {
    text-align: left;
  }
}

.nowrap {
  white-space: nowrap;
}

@for $i from 1 through 12 {
  .col-centered-mobile-#{$i} {
    @include respond-below($break-phone) {
      @include grid-align($i);
    }
  }
}

@for $i from 1 through 12 {
  .col-centered-tablet-#{$i} {
    @include respond-between($break-phone, $break-large) {
      @include grid-align($i);
    }
  }
}

@for $i from 1 through 12 {
  .col-centered-desktop-#{$i} {
    @include respond($break-large) {
      @include grid-align($i);
    }
  }
}

@for $i from 1 through 12 {
  .col-span-mobile-#{$i} {
    @include respond-below($break-phone) {
      grid-column: span $i;
    }
  }
}

@for $i from 1 through 12 {
  .col-span-tablet-#{$i} {
    @include respond-between($break-phone, $break-large) {
      grid-column: span $i;
    }
  }
}

@for $i from 1 through 12 {
  .col-span-desktop-#{$i} {
    @include respond($break-large) {
      grid-column: span $i;
    }
  }
}

.hidden {
  display: none !important;
}

.hidden-mobile {
  @include respond-below($break-phone) {
    display: none !important;
  }
}

.hidden-tablet {
  @include respond-between($break-phone, $break-xl) {
    display: none !important;
  }
}

.hidden-desktop {
  @include respond($break-xl) {
    display: none !important;
  }
}

.pointer {
  cursor: pointer;
}

#kustomer-ui-sdk-iframe {
  left: 25px;
  right: initial;
}

.strikethrough {
  text-decoration: line-through;
}

.book {
  @include book;
}

.up {
  text-transform: uppercase;
}

.short-description {
  @include book;

  font-size: 0.875rem;
  line-height: 1.71;

  p {
    font-size: inherit;
  }
}

.caption {
  @include book;

  font-size: 0.75rem;
  line-height: 2;
  margin: 0;
  padding: 0;
}

.call-to-action-link {
  @include heavy;

  display: inline-block;
  font-size: 0.875rem;
  line-height: 1.43;
  border-bottom: 2px solid;
  text-transform: uppercase;

  @include respond($break-large) {
    border-bottom: 2px solid;
    font-size: 1rem;
    line-height: 1.25;
  }
}

span.call-to-action-link,
a.call-to-action-link {
  margin: auto;
  margin-left: 0;
  font-size: 0.875rem;

  @include respond($break-large) {
    font-size: 0.875rem;
  }

  &:hover {
    @include transition;
  }
}

.button-as-link {
  font-family: inherit;
  text-decoration: underline;
  background: none;
  cursor: pointer;
  padding: 0;
  font-size: 0.875em;
}
