@import '../partials/variables';
@import '../partials/mixins';

.promotion-copy {
  &__code {
    @include heavy;
    padding: 0 0.125rem;

    @include respond($break-phone) {
      padding: 0 0.5rem;
    }
  }
}
