@import '../partials/variables';
@import '../partials/mixins';

.product-carousel {
  overflow: hidden;
  width: 100%;
  padding: 3.75rem 0 5.125rem;

  @include respond($break-phone) {
    padding: 3.75rem 0 5.125rem;
  }

  @include respond($break-large) {
    padding: 5rem 0 5rem;
  }

  &__header {
    position: relative;
  }

  &__header.left {
    text-align: left;

    @include custom-breakpoint-styles(
      padding,
      0 0 0 $horizontal-page-padding-phone,
      0 0 0 $horizontal-page-padding-tablet,
      0 0 0 $horizontal-page-padding-desktop
    );
  }

  &__header.center {
    text-align: center;
  }

  &__header.right {
    text-align: right;
  }

  &-slider {
    &__arrow {
      border-radius: 50%;
      box-shadow: 0 1px 4px 0 rgba(178, 178, 180, 0.75);
    }
  }

  &__inner-wrapper {
    @include components-element-max-width;
  }
}
