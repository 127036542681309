@import '../partials/variables';
@import '../partials/mixins';

.showrooms {
  .hero-new {
    @include respond-below($break-phone) {
      text-align: center;
      h2 {
        text-align: center;
      }
    }
  }
  .full-bleed-background {
    background-color: $lightest-grey;
  }
  .button {
    width: 15.625rem;
  }
  &-partners {
    @include grid-wrapper;
    @include section-padding;
    @include section-margin;
    grid-template-rows: auto;
    text-align: center;
    &-header, &-request {
      grid-column: 1 / -1;
      padding: 0;
    }
    &-copy {
      @include respond($break-phone) {
        margin: 0 auto;
        margin-bottom: 1rem;
        max-width: 31.25rem;
      }
    }
    .partner-group {
      grid-column: 1 / -1;
      text-align: left;
      @include respond($break-phone) {
        grid-column: span 4;
      }
      @include respond($break-large) {
        grid-column: span 3;
      }

      &__title {
        border-bottom: 1px solid $charcoal50;
        @include respond($break-phone) {
          border: none;
        }
      }
    }
    .partner-single {
      cursor: pointer;
      margin-top: 1rem;
      span {
        display: block;
      }
      .title {
        @include heavy;
      }
      &:hover {
        .title {
          color: $terracotta;
        }
      }
    }
    &-request {
      border-top: 1px solid $charcoal50;
      text-align: center;
      p {
        margin: 0 auto;
        padding: 1.875rem;
        line-height: 1.5;
        max-width: 46.25rem;
        @include respond($break-large) {
          padding: 4rem 0 2rem;
        }
      }
    }
  }
  &-no-partner {
    @include grid-wrapper;
    @include section-padding;
    h2 {
      grid-column: 1 / -1;
      text-align: center;
      @include respond-below($break-phone) {
        margin-bottom: 0.625rem;
      }
    }
    .no-partner-highlight {
      grid-column: 1 / -1;
      text-align: center;
      padding: 1rem;
      .images {
        padding-bottom: 25%;
        margin-bottom: 1rem;
        @include respond($break-phone) {
          margin-bottom: 2rem;
        }
      }
      p {
        margin: 0.625rem auto 1.5rem;
      }
    }

  }
  &-card {
    &-overlay {
      position: fixed;
      top: 0;
      background: rgba(0,0,0,.2);
      z-index: 10;
      height: 100%;
      width: 100%;
      overflow: auto;
      @include respond($break-large) {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 100vh;
        overflow: unset;
      }
    }
    &-wrapper {
      max-width: 90vw;
      margin: 5vw auto;
      @include respond($break-phone) {
        display: flex;
        max-width: 100%;
        max-height: 95vh;
      }
    }
    position: relative;
    background-color: $white;
    min-width: 18.75rem;
    max-width: 56.625rem;
    display: grid;
    grid-template-columns: 44% 56%;
    .icon-close {
      position: absolute;
      top: .25rem;
      right: .25rem;
      cursor: pointer;
      font-size: 1rem;
      @include respond($break-phone) {
        top: 2rem;
        right: 2rem;
        font-size: 1.5rem;
      }
    }
    &-header {
      grid-column: 1 / -1;
      text-align: center;
      border-bottom: 1px solid $charcoal25;
      padding: 1rem;
      @include respond($break-phone) {
        padding: 3rem 0 2rem;
      }
      @include respond($break-large) {
        padding: 6rem 0 2rem;
      }
      .outline {
        text-align: center;
        text-transform: uppercase;
      }
    }
    &-items {
      overflow: unset;
      -webkit-overflow-scrolling: touch;
      display: grid;
      grid-template-columns: 100%;
      grid-row-gap: 1rem;
      @include respond($break-phone) {
        overflow: auto;
        grid-row-gap: 2rem;
        max-height: 37.5rem;
      }
      p {
        padding: 0;
        margin: 0;
      }
    }
    &-items, &-details {
      grid-column: 1 / -1;
      padding: 1rem;
      @include respond($break-large) {
        grid-column: span 1;
        padding: 2rem;
      }
    }
    &-details {
      overflow: auto;
      @include respond($break-phone) {
        border-right: 1px solid $charcoal25;
      }
      .address {
        span {
          display: block;
        }
        .directions {
          color: $terracotta;
          cursor: pointer;
        }
      }
      ul {
        @include respond($break-large) {
          margin-top: 1.75rem;
        }
      }
      li {
        display: flex;
        justify-content: space-between;
        line-height: 1.5;
      }
    }
  }
}
