@import "../partials/variables";
@import "../partials/mixins";

.cart_recommended-wrapper {
  background: $white;
  z-index: 2;

  @include respond($break-phone) {
    margin-top: -2rem;
  }
}

.cart__recommended-header {
  margin-left: 1.5rem;
  padding-top: 1rem;

  @include gta-burrow;

  font-size: 0.6875rem;
  line-height: 1.125rem;
  letter-spacing: 0;

  @include respond($break-phone) {
    font-size: 0.8125rem;
    line-height: 1.3125rem;
  }

  @include respond($break-large) {
    font-size: 0.6875rem;
    line-height: 1.125rem;
  }
}
