@import '../partials/variables';
@import '../partials/mixins';

.max-width-card {
  @include page-padding;
  @include section-padding;
  position: relative;
  width: 100%;
  &-container {
    overflow: hidden;
    position: relative;
    padding-bottom: 100%;
    width: 100%;
    height: 0px;
    @include respond($break-phone) {
      padding-bottom: 42.8571%;
    }
    .video-component {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      video {
        width: 100%;
      }
    }
    .graphcms-image-wrapper {
      > div:first-child {
        @include custom-breakpoint-styles(padding-bottom, 100% !important, 42.8571% !important, 42.8571% !important) // override inline styles
      }
    }
  }
  &-copy {
    @include flex-column;
    position: absolute;
    margin: auto;
    width: 90%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    text-align: center;
    @include respond($break-phone) {
      width: 50%;
    }
    h3, h4 {
      margin-bottom: 2rem;
    }
    h4 {
      @include respond-below($break-phone) {
        display: none;
      }
    }
    .label {
      @include heavy;
      font-size: 0.875rem;
      line-height: 1.43;
      letter-spacing: 1.2px;
      border-bottom: 2px solid;
      text-transform: uppercase;
      @include respond($break-large) {
        border-bottom: 2px solid;
        font-size: 1rem;
        line-height: 1.25;
      }
    }
    a {
      width: fit-content;
      margin: 0 auto;
      text-align: center;
      &.terracotta {
        border-bottom-color: $terracotta;
      }
      &:hover {
        @include transition;
        letter-spacing: 1.8px;
      }
    }
    &.tight {
      justify-content: center;
    }
    &.wide {
      justify-content: space-between;
      @include respond($break-large) {
        justify-content: center;
        height: 50%;
        margin-top: 0;
      }
    }
    &.white {
      color: $white;
      a {
        color: $white;
        border-bottom-color: $white;
      }
    }
    &.charcoal {
      color: $charcoal;
    }
  }
}
