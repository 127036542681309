@import '../partials/variables';
@import '../partials/mixins';

.sms-icon {
  position: fixed;
  bottom: 25px;
  left: 25px;
  height: 50px;
  width: 50px;
  z-index: 13;
  background-color: $white;
  border-radius: 50%;
  filter: drop-shadow(rgba(0, 0, 0, 0.33) 1px 1px 4px);
  color: $charcoal;

  i {
    font-size: 1.75rem;
    position: absolute;
    top: 52.5%;
    left: 49.5%;
    transform: translate(-50%, -50%);
  }

  @include respond($break-phone) {
    display: none;
  }

  &.favorites-bucket-sms {
    bottom: 7rem;
  }
}
