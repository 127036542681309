@import '../partials/variables';
@import '../partials/mixins';

.info-highlights {
  @include grid-wrapper;
  @include section-padding;

  &__header {
    grid-column: 1 / -1;
    text-align: center;
  }
}

.info-highlight {
  grid-column: 1 / -1;
  text-align: center;
  padding: 1rem;

  @include respond($break-phone) {
    padding: 0;
    grid-column: span 4;
    margin: 0 auto;

    &:nth-of-type(odd):last-of-type {
      grid-column: 3 / 7;
    }
  }

  @include respond($break-large) {
    grid-column: span 5;
    width: 75%;

    &:first-of-type {
      grid-column: col-start 2 / span 5;
    }

    &:nth-of-type(odd):last-of-type {
      grid-column: 5 / 9;
    }
  }

  &__image-container {
    position: relative;
    margin-bottom: 1rem;

    @include respond($break-phone) {
      margin-bottom: 2rem;
    }

    & .graphcms-image-wrapper > div {
      padding-bottom: 25% !important; // override inline styles
    }
    & .graphcms-image-wrapper > img {
      width: 100%;
      object-fit: contain !important; // override inline styles
    }
  }

  &__image {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  &__copy {
    margin: 0.625rem auto 1.5rem;
  }

  &__button {
    min-width: unset;
    width: 13.875rem;
    max-width: 100%;
    margin-top: 1.25rem;

    @include respond($break-large) {
      width: 15.625rem;
      margin-top: 1.6875rem;
    }
  }
}
