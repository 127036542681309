@import '../partials/variables';
@import '../partials/mixins';

@mixin three-point-grid() {
  display: grid;
  grid-template-columns: repeat(3, [col-start] minmax(0, 1fr));
  grid-gap: 1.25rem;
}

.three-points {
  @include grid-wrapper;
  @include section-padding;
  grid-template-rows: auto minmax(0, 1fr);

  &-title {
    grid-column: 1 / 13;
    grid-row: 1;
    @include respond($break-phone) {
      text-align: center;
    }
  }

  &-info {
    grid-row: 2;
    grid-column: 1 / 13;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;

    @include respond($break-phone) {
      flex-direction: row;
      justify-content: space-between;
      width: 75%;
      margin: auto;
    }

    .info-point {
      @include flex-column;
      align-content: center;
      justify-content: flex-start;
      text-align: center;

      @include respond($break-phone) {
        padding: 0;
        width: 17%;
      }

      .graphcms-image-wrapper {
        > div:first-child {
          @include respond-below($break-phone) {
            padding-top: 15%;
          }
        }
      }

      .point-span-header {
        @include h3;
        display: block;
      }

      &-copy {
        align-self: center;
        grid-column: span 2;
        max-width: 100%;

        @include respond($break-phone) {
          justify-self: center;
          margin: 0 auto;
        }

        @include respond($break-large) {
          padding: 0 .5rem;
        }
      }

      i {
        padding: 2rem 0;
        font-size: 2rem;
        display: inline-block;

        &.large-icon {
          font-size: 3.5rem;
        }

        @include respond($break-phone) {
          &.large-icon {
            font-size: 4rem;
          }
        }
      }

      .images {
        margin: 0 auto;
        background-size: contain;
        height: 5rem;
        transform: translateY(5rem);
        animation: bounce-in 0.9s ease forwards;

        @include respond($break-phone) {
          margin-bottom: 0.75rem;
          width: 100%;
          height: 18rem;
        }
      }

      i {
        transform: translateY(5rem);
        animation: bounce-in 0.9s ease forwards;
      }

      &:nth-child(odd) {
        i, .images {
          animation-duration: 0.6s;
        }
      }
    }
  }

  &__slider {
    position: relative;
    padding-bottom: 2rem;

    .info-point .info-image {
      width: 40%;
      margin: 0 auto;

      @include respond($break-phone) {
        width: 75%;
        padding-bottom: 0;
      }
    }

    .info-point-copy {
      text-align: center;
      margin: 0 auto;
      width: 40%;
    }
  }
}

@keyframes bounce-in {
  to { transform: translateY(0); }
}
