@import '../partials/mixins';

.account {
  &-nav {
    margin-top: 1rem;
    padding: 8px 10px 0;
    left: 0;
    width: 100%;
    &-wrapper {
      min-width: 520px;
      margin: 0 auto;
      display: flex;
      justify-content: center;
    }
  }
}
.account {
  .btn {
    width: 15.625rem;
  }
  .response {
    text-align: center;
  }
  &-box {
    max-width: 37.5rem;
    width: 100%;
    margin: 0 auto;
    padding-top: 5rem;
    &.history.order {
      padding-top: 5.875rem;
    }
    &.history {
      max-width: 1020px;
      @include respond($break-medium) {
        min-height: calc(100vh - 140px);
      }
    }
  }

  &-header-wrapper {
    width: 100%;
    margin-bottom: 2.5rem;
  }

  &-form {
    padding: 0 2.5rem 2.5rem;
    padding-top: 0;
    .form-fancy-wrapper {
      grid-row-gap: 1.25rem;
    }
    button {
      margin-top: 1.25rem !important;
    }
  }
  h3 {
    text-align: center;
    margin-bottom: 30px;
    margin-top: 40px;
  }
  &-nav {
    text-transform: uppercase;
    border-bottom: 1px solid $sandstone;
    overflow: hidden;
    overflow-x: scroll;
    a,
    .account-nav-link {
      font-size: .85rem;
      position: relative;
      padding: 16px 0 14px;
      margin: 0 20px;
      cursor: pointer;
      &.active,
      &:hover {
        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 1px;
          background: $charcoal;
        }
      }
    }
  }
  &-history {
    &-block {
      margin-bottom: 10px;
    }
    &-empty {
      align-self: center;
      .couch-order-empty {
        background-size: 50%;
      }
      &-button {
        text-align: center;
      }
    }
    &-header {
      margin-bottom: 4px;
      &-status {
        padding: 8px 0;
        display: flex;
        justify-content: space-between;
      }
    }
    &-content {
      border: 1px solid $charcoal;
      padding: 30px 24px 0;
      width: 100%;

      img {
        width: 100%;
        height: auto;
      }
      @include respond($break-medium) {
        padding: 50px 40px 0;
      }
      @include respond($break-large) {
        display: flex;
        justify-content: space-between;
      }
    }
    &-info {
      // margin: 10px 0;
      padding: 10px 0;
      .bump {
        margin-top: 34px;
      }
      .link {
        font-weight: 600;
        text-decoration: underline;
        cursor: pointer;
      }
      &-wrapper {
        @include respond($break-medium) {
          display: flex;
          justify-content: space-between;
          >div {
            width: calc(50% - 10px);
          }
        }
        @include respond($break-large) {
          width: 60%;
        }
      }
      @include respond($break-large) {
        padding: 0 0 30px;
        p {
          &:first-child {
            margin-top: 0;
            padding-top: 0;
          }
        }
      }
      span {
        display: block;
      }
    }
    &-couch {
      @include respond($break-large) {
        width: 400px;
      }
      &-wrapper {
        display: flex;
      }
      &-image {
        width: 35%;
        margin-right: 14px;
      }
      &-data {
        .lower {
          font-size: 1rem;
        }
        &-info {
          display: flex;
          flex-wrap: wrap;
          >p {
            margin: 6px 0;
            width: 50%;
            text-align: left;
          }
        }
      }
    }
  }
}
.over-grey {
  .form-fancy {
    &-wrapper.block {
      display: block;
      p {
        opacity: .4;
      }
      .checkbox {
        margin-top: 10px;
        label {
          opacity: 1;
        }
        span {
          &:before {
            opacity: 1;
            border: none;
            background: #fff;
          }
        }
      }
    }
    input[type=text],
    input[type=email],
    input[type=tel],
    input[type=checkbox],
    input[type=number],
    select {
      background: #fff;
      border: none;
    }
  }
}
