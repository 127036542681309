@import '../partials/variables';
@import '../partials/mixins';

.modal-text {
  line-height: 1.4rem;
  font-size: 0.875rem;
}

.details-sales-modal,
.pdp-details-sales-modal {
  display: block;

  .modal-text {
    color: $terracotta;
  }

  .sales-tiers {
    &__tier-wrapper {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: unset;
      grid-auto-flow: row;
      row-gap: 0.625rem;
      column-gap: 1rem;
      margin: unset;
    }

    &__copy-header {
      font-size: 1.125rem;
      line-height: 1.688rem;
      letter-spacing: -0.2px;
    }

    &__tier {
      p {
        font-size: 0.8125rem;
        line-height: 1.125rem;
        letter-spacing: 0;
      }
    }

    &__terms-copy {
      font-size: 0.6875rem;
      line-height: 1rem;
      letter-spacing: 0;
    }
  }
}

.pdp-options-wrapper {
  .modal-text {
    color: $crushedgravel;
    font-size: 0.8125rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.85;
    letter-spacing: normal;
  }
}

.modal {
  &-text {
    line-height: 1.4rem;
    font-size: 0.875rem;
    border-bottom: 1px solid;
    cursor: pointer;

    &--sale-link {
      border-bottom: none !important;
      white-space: nowrap;
    }
  }

  &-icon {
    display: inline-block;
    width: 0.8125rem;
    height: 0.8125rem;
    margin-left: 0.3125rem;
    background-position: center;
    background-size: contain;
    vertical-align: center;
    cursor: pointer;
  }

  &-video {
    width: 100%;
    padding-bottom: 62.5%;
    position: relative;

    .video-component {
      position: absolute;
      width: 100%;
      height: 100%;

      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &-msg {
    background: $white;
    color: $charcoal;
    text-align: left;
    padding: 1rem 1.875rem;
    word-wrap: break-word;

    h3 {
      color: $charcoal;
    }

    .copy {
      padding: 0.5rem 0;
    }

    .footnote {
      font-size: 0.625rem;
      color: $charcoal75;
    }
  }

  &-details {
    .sales-tiers {
      h5.highlight {
        @include heavy;

        line-height: 2.25rem;
        text-transform: uppercase;
        font-weight: 900;
        font-size: 0.75rem;
      }

      &__tier-wrapper {
        grid-template-columns: repeat(1, 1fr);
      }

      p {
        line-height: 1rem;
      }

      &__terms {
        min-width: unset;
      }
    }
  }
}
