@import '../partials/variables';
@import '../partials/mixins';

.hero-new {
  @include page-padding;
  @include flex-column;

  justify-content: flex-end;

  &-breadcrumbs {
    a {
      font-size: inherit;
      color: inherit;
    }
  }

  &-header {
    &-margin {
      @include respond($break-phone) {
        margin-top: 2rem;
      }
    }
  }

  .wrap {
    @include respond($break-large) {
      max-width: 50%;
    }
  }
}

.hero-plp {
  @include page-padding;
  @include flex-column;

  justify-content: flex-end;

  &-breadcrumbs {
    a {
      font-size: inherit;
      color: inherit;
    }
  }

  &-header-wrapper {
    display: flex;
  }

  &-header {
    flex: 1 1 auto;

    &-margin {
      @include respond($break-phone) {
        margin-top: 2rem;
      }
    }
  }

  &-layout-buttons {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 1.25rem;
  }

  &-layout-button {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:not(:last-child) {
      margin-right: 0.5rem;
    }

    &-label {
      padding-bottom: 0.25rem;
    }
  }

  .wrap {
    margin-top: 1rem;

    @include respond($break-phone) {
      max-width: 50%;
    }

    @include respond($break-large) {
      max-width: 33.333%;
    }
  }
}

.layout-icons {
  width: 2rem;
  height: 2rem;

  &.icon-grid {
    background: url('../../assets/plp/icon-grid.svg') no-repeat 50% 50%;

    &.active {
      background: url('../../assets/plp/icon-grid-active.svg') no-repeat 50% 50%;
    }
  }

  &.icon-swipe {
    background: url('../../assets/plp/icon-swipe.svg') no-repeat 50% 50%;

    &.active {
      background: url('../../assets/plp/icon-swipe-active.svg') no-repeat 50% 50%;
    }
  }
}
