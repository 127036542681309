@import '../partials/variables';
@import '../partials/mixins';

.shop-theme {
  @include components-element-max-width();

  margin-bottom: 3.125rem;

  @include respond($break-phone) {
    margin: 5rem auto;
  }

  &__cards {
    @include grid-container;
  }

  &__asset {
    grid-row: 1;
    grid-column: 1 / -1;

    @include respond($break-phone) {
      grid-column: 1 / span 5;
    }

    @include respond($break-large) {
      grid-column: 1 / span 8;
    }

    .card__asset {
      padding-bottom: 100% !important; //override inline styles

      @include respond($break-phone) {
        padding-bottom: 50% !important; //override inline styles
      }
    }
  }

  &__content {
    grid-row: 2;
    grid-column: 1 / -1;
    padding: $vertical-page-padding-phone $horizontal-page-padding-phone;

    @include respond($break-phone) {
      grid-row: 1;
      grid-column: span 3;
      padding: 0 $horizontal-page-padding-tablet 0 0;
    }

    @include respond($break-large) {
      grid-column: span 4;
      padding: 0;
    }

    .card__body {
      text-align: left;
      grid-column: 1 / -1;
      margin: auto 0;
      max-width: unset;

      @include respond($break-phone) {
        grid-column: 1 / span 8;
      }

      @include respond($break-large) {
        grid-column: 3 / span 8;
      }
    }

    .card__copy {
      p {
        @include body-large;
      }
    }
  }
}
