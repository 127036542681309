@import "../partials/variables";
@import "../partials/mixins";

.cart__recommended-container {
  overflow-x: scroll;
  margin: 0 1rem;

  @include respond-below($break-phone) {
    margin-right: 0;
    margin-left: 1rem;
  }
}
