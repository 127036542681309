@import '../partials/variables';
@import '../partials/mixins';

.featured-products {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-top: 3.75rem;
  padding-bottom: 5.125rem;

  @include respond($break-large) {
    padding-top: 5rem;
    padding-bottom: 2rem;
  }

  &--no-gap-above {
    padding-top: 0;
    @include respond($break-large) {
      padding-top: 0;
    }
  }

  &__header-container, &__grid, &__carousel {
    @include components-element-max-width;
    width: 100%;
    &:not(:last-child) {
      margin-bottom: 2rem;
      @include respond($break-large) {
        margin-bottom: 3.125rem;
      }
    }
  }

  &__header-container {
    @include grid-container;
    @include components-element-side-padding;

    h2 {
      @include custom-breakpoint-styles(
        grid-column,
        1 / span 4,
        1 / span 8,
        3 / span 8
      );
      grid-row: 1;
      text-align: center;
      white-space: pre-line;
      color: $charcoal;
    }
  }

  &__carousel {
    .slick-slider {
      margin: 0; // overwrite ProductCarouselSlider styles
    }
  }

  &__grid {
    @include components-element-side-padding;
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-template-rows: repeat(3, minmax(0, 1fr));
    column-gap: 2rem;
    row-gap: 1.25rem;

    &-item {
      &:nth-child(1) {
        grid-row: 1 / span 2;
        grid-column: 1 / span 2;
      }
      &:nth-child(2) {
        grid-row: 3 / span 1;
        grid-column: 1 / span 1;
      }
      &:nth-child(3) {
        grid-row: 3 / span 1;
        grid-column: 2 / span 1;
      }
      &:nth-child(4) {
        grid-row: 1 / span 1;
        grid-column: 3 / span 1;
      }
      &:nth-child(5) {
        grid-row: 1 / span 1;
        grid-column: 4 / span 1;
      }
      &:nth-child(6) {
        grid-row: 2 / span 2;
        grid-column: 3 / span 2;
      }
    }
  }
}
