@import '../partials/variables';
@import '../partials/mixins';

.recommended__cart-item {
  position: relative;
  border-bottom: 1px solid $charcoal25;
  padding: 0.5rem;
  min-width: 19.5rem;
  min-height: 6rem;
  display: grid;
  grid-template-columns: 5fr 12fr 3fr;
  grid-row-gap: 0.375rem;
  grid-column-gap: 0.75rem;
  border: 1px solid $charcoal25;
  margin: 0.5rem;

  @include respond-below($break-phone) {
    margin-right: -1rem;
  }

  &__image {
    grid-area: 1 / 1 / span 3 / 1;
    display: grid;
    grid-gap: 0.5rem;
    grid-template-rows: repeat(2, min-content);
  }

  &__shipping {
    grid-area: 3 / 2/ 3 /3;
    color: $charcoal50;
  }

  &__description {
    grid-area: 2/2;
  }

  &__remove {
    @include book;

    grid-area: 3/3;
    justify-self: end;
    align-self: center;
    color: $charcoal50;
    text-decoration: underline;
    background: none;
    padding: 0;
  }

  &__price {
    @include body-medium;

    grid-area: 1/3;
    justify-self: end;
    text-align: right;
    display: flex;
    flex-direction: column;
  }

  .grey {
    color: $charcoal50;
    font-size: .5rem;
  }

  .clickable {
    cursor: pointer;
  }

  &__info-title {
    @include body-medium;
    @include heavy;
  }

  &__info-copy {
    line-height: 1.5;
    color: $charcoal;
  }

  &__info-rich {
    p {
      font-size: inherit;
      line-height: inherit;
    }
  }

  &__info-bold {
    @include heavy;
  }

  &__info-terracotta {
    color: $terracotta;
  }

  &__quantity-wrapper {
    @include body-medium;

    text-align: center;
  }
}

.cart-item {
  position: relative;
  border-bottom: 1px solid $charcoal25;
  padding: 1rem 0;
  min-width: auto;
  display: grid;
  grid-template-columns: 5fr 12fr 3fr;
  grid-template-rows: auto auto auto;
  grid-row-gap: 0.375rem;
  grid-column-gap: .75rem;

  &__image {
    grid-area: 1 / 1 / span 3 / 1;
    display: grid;
    grid-gap: 0.5rem;
    grid-template-rows: repeat(2, min-content);
  }

  &__shipping {
    grid-area: 3 / 2/ 3 /3;
    color: $charcoal50;
  }

  &__description {
    grid-area: 2/2;
  }

  &__remove {
    @include book;

    grid-area: 3/3;
    justify-self: end;
    align-self: center;
    color: $charcoal50;
    text-decoration: underline;
    background: none;
    padding: 0;
  }

  &__price {
    @include body-medium;

    grid-area: 1/3;
    justify-self: end;
    align-self: center;
    text-align: right;
    display: flex;
    flex-direction: column;
  }

  .grey {
    color: $charcoal50;
    font-size: .5rem;
  }

  .clickable {
    cursor: pointer;
  }

  &__info-title {
    @include body-medium;
    @include heavy;
  }

  &__info-copy {
    line-height: 1.5;
    color: $charcoal;
  }

  &__info-rich {
    p {
      font-size: inherit;
      line-height: inherit;
    }
  }

  &__info-bold {
    @include heavy;
  }

  &__info-terracotta {
    color: $terracotta;
  }

  &__quantity-wrapper {
    @include body-medium;

    text-align: center;
  }
}

.cart-item-recommendation {
  border-bottom: 0px;
}

.cart-item-quantity {
  @include flex-row;
  align-items: center;
  justify-content: center;

  &__circle {
    @include transition(all .25s);
    width: 1rem;
    height: 1rem;
    cursor: pointer;
    position: relative;
    border: 1px solid $charcoal25;
    border-radius: 50%;

    &:after {
      @include transition(all .25s);
      @include transform(translate(-50%, -50%));
      content: '';
      position: absolute;
      width: .25rem;
      height: 1px;
      background: $concrete;
      left: 50%;
      top: 50%;
    }

    &.disabled {
      cursor: not-allowed;
    }
  }

  &__circle-increase {
    &:before {
      @include transition(all .25s);
      @include transform(translate(-50%, -50%));
      content: '';
      position: absolute;
      width: 1px;
      height: .25rem;
      background: $concrete;
      left: 50%;
      top: 50%;
    }
  }

  &__number {
    margin: 0 0.625rem;
  }
}

.promo-input-wrapper {
  .icon-close {
    z-index: 4;
    cursor: pointer;
    @include transition(all .25s);
    transform: rotate(45deg);

    &.active {
      transform: initial;
    }
  }
}
