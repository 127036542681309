@import '../partials/variables';
@import '../partials/mixins';

.sale-thermometer {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.5rem;
  align-items: center;
  width: 100%;

  &__label {
    font-size: 0.8rem;
    text-align: center;
  }

  &__progress-bar-box {
    align-self: stretch;
    height: 10px;
    border-radius: 10px;
    border: 2px solid $burgundy;
  }

  &__progress-bar {
    height: 100%;
    background-color: $burgundy;
  }
}
