@import '../partials/variables';
@import '../partials/mixins';

.our-approach {
  &-image {
    grid-row: 4;
    grid-column: 1 / span 1;
    margin: 0 auto;

    @include respond($break-phone) {
      grid-row: 2;
      grid-column: 2 / span 2;
    }

    .image,
    .image-placeholder {
      width: 4rem;
      height: 4rem;
      border-radius: 50%;

      @include respond($break-phone) {
        width: 12.5rem;
        height: 12.5rem;
        border-radius: 50%;
      }
    }
  }

  &-person {
    grid-row: 4;
    grid-column: 2 / span 2;

    @include respond($break-phone) {
      text-align: center;
      grid-row: 3;
      grid-column: 2 / span 2;
    }
  }

  &-quote-mark {
    grid-row: 2;
    grid-column: 1;

    @include gta-burrow;

    width: 1rem;
    height: 3rem;
    font-size: 36px;
    line-height: 1.33;
    letter-spacing: -1px;
    color: $charcoal;

    @include respond($break-phone) {
      grid-row: 1;
      grid-column: 5 / span 1;

      @include gta-burrow;

      width: 2.5rem;
      height: 3rem;
      -webkit-text-stroke: 1px $charcoal;
      background-color: $white;
      font-size: 5.6rem;
      line-height: 0.53;
      letter-spacing: -2.5px;
      color: $white;
      padding-top: 0.5625rem;
    }
  }

  &-quote {
    grid-row: 3;
    grid-column: 1 / -1;

    @include respond($break-phone) {
      grid-row: 2;
      grid-column: 5 / span 3;
    }

    @include respond($break-large) {
      grid-row: 2;
      grid-column: 5 / span 7;
    }
  }

  &-learn-more {
    grid-row: 5;
    grid-column: 1 / span 2;

    @include respond($break-phone) {
      grid-row: 3;
      grid-column: 5 / span 3;
    }

    @include respond($break-large) {
      grid-row: 3;
      grid-column: 5 / span 7;
    }

    .link {
      @include heavy;

      font-size: 0.875rem;
      line-height: 1.43;
      letter-spacing: 1.2px;
      border-bottom: 2px solid;
      text-transform: uppercase;

      @include respond($break-large) {
        border-bottom: 2px solid;
        font-size: 1rem;
        line-height: 1.25;
      }
    }
  }
}
