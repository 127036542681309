@import '../partials/variables';
@import '../partials/mixins';

$vertical-padding-sales-tiers-modal: 3.125rem;

.sales-tiers {
  width: 100%;

  &__container {
    @include grid-container;
    @include components-element-max-width;

    padding: $vertical-padding-sales-tiers-modal $horizontal-page-padding-phone;
    row-gap: 1.5rem;

    @include respond($break-phone) {
      padding: $vertical-padding-sales-tiers-modal $horizontal-page-padding-tablet;
      row-gap: 1.25rem;
    }

    @include respond($break-large) {
      padding: $vertical-padding-sales-tiers-modal $horizontal-page-padding-desktop;
    }
  }

  &__copy,
  &__terms {
    @include custom-breakpoint-styles(grid-column, span 4, span 8, span 12);
  }

  &__copy {
    margin: auto;
    width: 100%;
    text-align: center;

    & > *:not(:first-child) {
      margin-top: 0.5rem;

      @include respond($break-large) {
        margin-top: 0.75rem;
      }
    }

    p {
      @include body-large;

      @include respond($break-large) {
        @include body-extra-large;
      }
    }
  }

  &__copy-header {
    text-align: center;
  }

  &__tier-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-flow: row;
    text-align: center;
    row-gap: 0.625rem;
    column-gap: 1rem;

    @include respond($break-large) {
      grid-template-columns: unset;
      grid-template-rows: repeat(2, 1fr);
      grid-auto-flow: column;
      row-gap: 1.5rem;
      column-gap: 3.5rem;
      margin: 0 auto 1.25rem;
    }

    @include custom-breakpoint-styles(grid-column, span 4, span 8, span 12);
  }

  &__tier {
    p {
      @include body-extra-large;

      white-space: nowrap;
    }
  }

  &__terms {
    p {
      @include body-medium;
    }

    grid-column: 1 / -1;
    text-align: center;
  }
}
