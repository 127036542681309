@import '../partials/variables';
@import '../partials/mixins';

$container-padding: 0.25rem;
$container-padding-tablet: 0.5rem;
$button-expand-position: calc(0.5rem + $container-padding);
$button-expand-position-tablet: calc(0.5rem + $container-padding-tablet);

.favorite-card__wrapper {
  display: flex;
  grid-column: span 6;
  gap: 0.75rem;
  position: relative;

  @include respond($break-tablet) {
    gap: 1rem;
    grid-column: span 3;
  }

  @include respond($break-large) {
    grid-column: span 3;
  }
}

.favorite-card__details-button {
  position: absolute;
  z-index: 2;
  border-radius: 100%;
  cursor: pointer;
  width: 1.75rem;
  height: 1.75rem;
  top: $button-expand-position;
  right: $button-expand-position;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(255 255 255 / 85%) none;
  transition: background-color 0.2s ease-in-out;

  @include respond($break-tablet) {
    top: $button-expand-position-tablet;
    right: $button-expand-position-tablet;
  }

  &:hover {
    background-color: rgb(255 255 255 / 100%);
  }

  .icon-expand {
    width: 1.25rem;
  }

  .icon-expand::before {
    content: "\e905";
    color: #383633;
  }
}

.favorite-card-container {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.25rem;
  gap: 0.75rem;

  @include respond($break-tablet) {
    gap: 1rem;
    padding: 0.5rem;
  }

  &__title {
    @include h5;

    text-align: center;
  }

  &__image-container {
    display: inherit;
    position: relative;

    div {
      width: 100%;
      height: 100%;
    }
  }

  &__description {
    display: inherit;
    justify-content: center;

    p {
      @include book;
      @include body-medium;

      font-size: 0.563rem;
      background: none;
    }
  }

  &.selected {
    outline: 1px solid $true-black;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 25%);

    &:hover {
      outline: 1px solid $true-black;
    }
  }

  &.selected &__description {
    outline: none;
  }

  &.selected &__description p {
    text-decoration: underline;
  }

  &:hover {
    outline: 1px solid $charcoal20;
  }
}

.favorite-card-details-modal-container {
  display: flex;
  background-color: white;
  flex-direction: column;
  padding: 2rem;
}

.favorite-card-details-modal-title {
  @include gta-burrow;

  font-size: 0.5625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  text-transform: uppercase;
}

.favorite-card-details-modal-subtitle {
  @include h4;

  margin-bottom: 1rem;
}

.favorite-card-details-modal-slider-pagination {
  bottom: -0.5rem !important;

  > span {
    &::after {
      width: 1rem !important;
    }
  }
}

.favorite-card-details-modal-copy {
  @include body-medium;

  color: $charcoal;
  margin-top: 2rem;
  white-space: pre-wrap;

  & > span:first-child {
    @include gta-burrow;
    @include label-small;
  }
}

.favorite-card-details-modal-footnote {
  @include body-medium;

  color: $charcoal;
  margin-top: 2rem;
  white-space: pre-wrap;

  & > span:first-child {
    @include gta-burrow;
    @include label-small;
  }
}

.favorite-card-details-modal-slider-container {
  position: relative;
}
