@import "./mixins";
@import "./variables";

@mixin address-grid {
  display: grid;
  grid-template-columns: repeat(4, [col-start] minmax(0, 1fr));

  @include respond($break-phone) {
    grid-template-columns: repeat(8, [col-start] minmax(0, 1fr));
  }

  .form-fancy-wrapper {
    grid-column: 1 / -1;
  }
}

@mixin address-grid-gap {
  grid-gap: 1rem;

  @include respond($break-large) {
    grid-gap: 1.875rem;
  }
}

@mixin address-grid-spacing {
  padding-bottom: 2rem;

  @include respond($break-large) {
    padding-bottom: 3rem;
  }
}

.address-form {
  @include address-grid;

  grid-gap: 1.5rem;
  padding-bottom: 2.125rem;

  @include respond($break-phone) {
    padding-bottom: 4rem;
  }

  .form-fancy-wrapper {
    grid-gap: 1.5rem;
  }

  .auth-form {
    &-submit {
      margin-top: 2rem;
    }
  }

  &__checkboxes {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
    grid-column: 1/-1;

    .checkbox {
      margin: 0;
    }
  }
}
