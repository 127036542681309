@import '../partials/variables';
@import '../partials/mixins';

.color-picker-radio {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;

  span {
    @include h5;
    @include book;

    padding-left: 0.4375rem;
  }

  input {
    position: absolute;
    width: 0;
    height: 0;
    padding: 0;
  }

  input:checked ~ .color-picker-color,
  input:hover ~ .color-picker-color {
    border: 0.0625rem solid $charcoal;
    box-shadow: 0 0.25rem 0.3125rem -0.125rem rgb(71 69 67 / 30%);
  }
}
