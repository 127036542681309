@import '../partials/variables';
@import '../partials/mixins';

.product-card {
  $image-aspect-ratio: 0.625;

  grid-column: 1 / -1;

  @include respond($break-phone) {
    grid-column: span 4;
  }

  :global {
    .ruk_rating_snippet {
      i[class^='ruk-icon-percentage-star--'] {
        font-size: 0.675rem;
        color: #767472 !important;

        @include respond($break-large) {
          font-size: 0.8125rem;
        }
      }

      span[class='ruk-rating-snippet-count'] {
        @include body-medium;

        padding-left: 0.25rem;
        text-decoration: underline;
        text-decoration-skip-ink: none;
        color: #767472 !important;
      }
    }
  }

  &__image-wrapper {
    position: relative;
  }

  &__details {
    @include body-medium;

    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0.125rem;
    justify-content: space-between;
    margin-top: 0.5rem;

    &-copy {
      color: $charcoal;
      letter-spacing: 0.3px;
      display: inline-block;

      &--hover:hover {
        cursor: pointer;
        color: $burgundy;
      }

      &--underline {
        text-decoration: underline;
      }

      &.price {
        @include label-small;

        color: $charcoal75;
      }

      .affirm-as-low-as {
        display: contents;

        @include label-small;
      }
    }

    &-copy-sale {
      color: $burgundy;

      .affirm-as-low-as {
        a {
          color: $burgundy;
        }
      }
    }

    .strikethrough {
      margin-right: 0.4rem;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  &__badge-wrapper {
    $badge-grid-gap: 1rem;
    $badge-spacing-vertical: 3%;
    $badge-spacing-horizontal: calc(#{$badge-spacing-vertical} * #{$image-aspect-ratio});

    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;

    &-relative {
      position: relative;
      width: 100%;
      height: 100%;

      .top,
      .bottom {
        position: absolute;
        height: auto;
        width: min-content;
        z-index: 2;
      }

      .top {
        top: $badge-spacing-vertical;
      }

      .bottom {
        top: unset;
        bottom: $badge-spacing-vertical;
      }

      .left,
      .right {
        display: grid;
        grid-auto-flow: column;
        grid-gap: $badge-grid-gap;
      }

      .left {
        left: $badge-spacing-horizontal;
      }

      .right {
        right: $badge-spacing-horizontal;
      }

      .call-to-action {
        @include badge-text;

        height: min-content;
        width: min-content;
        white-space: nowrap;
        padding: 0.125rem 0.5rem;
      }
    }
  }

  &__sales-copy {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0.75rem 1rem;
    color: $charcoal;

    &--small {
      @include heavy;
      @include body-medium;
    }
  }
}

.product-card__showroom--two-col {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  column-gap: 1rem;

  @include respond($break-phone) {
    column-gap: 2rem;
  }

  .product-card__details {
    margin: 0;

    .price {
      .product-card__details-copy-sale {
        display: block;

        .affirm-as-low-as {
          display: contents;

          @include label-small;
        }
      }
    }
  }
}

.site-wide-sale-text {
  color: $burgundy;
  display: block;
  font-weight: bold;
  margin-top: 0.25rem;
}

:global(.shopping-space__pin-content) {
  @include respond($break-phone) {
    .product-card__details-copy {
      font-size: 0.75rem;
    }
  }
}

:global(.shopping-space) {
  .product-card__details-copy {
    &:not(:last-child) {
      margin-bottom: 0.125rem;
    }

    &--heavy {
      @include heavy;
    }

    &-sale {
      display: block;
    }
  }
}
