// FIXME: [styles] these styles are very redundant
@import '../partials/variables';

.bg-white {
  background: $white;
}
.bg-sandstone {
  background: $sandstone50;
}

.bg-slate,
.bg-color-slate {
  background: $slate;
}
.bg-transparent {
  background: transparent;
}
.bg-tan {
  background: $tan;
}
.bg-moss {
  background: $moss;
}
.bg-burgundy {
  background: $burgundy;
}
.bg-charcoal {
  background: $charcoal;
}

.bg-color {
  &-sandstone {
    background-color: $sandstone;
  }
  &-bluesteel {
    background-color: $bluesteel;
  }
  &-terracotta {
    background-color: $terracotta;
  }
  &-persimmon {
    background-color: $persimmon;
  }
  &-oat {
    background-color: $oat;
  }
  &-white {
    background-color: $white;
  }
  &-tan {
    background-color: $tan;
  }
  &-charcoal {
    background-color: $charcoal;
  }
  &-yellow {
    background-color: $yellow;
  }
}
.text-color {
  &-sandstone {
    color: $sandstone;
  }
  &-bluesteel {
    color: $bluesteel;
  }
  &-terracotta {
    color: $terracotta;
  }
  &-persimmon {
    color: $persimmon;
  }
  &-white {
    color: $white;
  }
  &-charcoal {
    color: $charcoal;
  }
  &-yellow {
    color: $yellow;
  }
}

.white {
  color: $white;
}

.terracotta {
  color: $terracotta;
}

.persimmon {
  color: $persimmon;
}

.sandstone {
  color: $sandstone;
}

.sandstone50 {
  color: $sandstone50;
}

.concrete {
  color: $concrete;
}

.coolgrey {
  color: $coolgrey;
}

.charcoal {
  color: $charcoal;
}

.charcoal75 {
  color: $charcoal75;
}

.burgundy {
  color: $burgundy;
}
