@import '../partials/variables';
@import '../partials/mixins';

.filter-section__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  button {
    @include h5;
    @include book;

    text-decoration: underline;
    background: none;
    cursor: pointer;
    padding: 0;
    color: $charcoal75;

    &.disabled {
      visibility: hidden;
    }
  }

  @include respond($break-xl) {
    h5 {
      font-size: 0.75rem;
    }

    button {
      font-size: 0.688rem;
    }
  }
}
