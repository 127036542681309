@import '../partials/variables';
@import '../partials/mixins';

.preview-images {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $charcoal25;
  overflow: hidden;
  z-index: 20;

  &__wrapper {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;

    @include respond-below($break-tablet) {
      overflow: scroll;
      -webkit-overflow-scrolling: touch;
      overflow-scrolling: touch;
    }
  }

  &__image {
    margin: auto;
    max-height: 100%;
    opacity: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    @include respond-below($break-tablet) {
      min-height: 100%;
      max-width: unset;
      max-height: unset;
      position: static;
      left: auto;
      right: auto;
    }

    @include respond($break-tablet) {
      max-width: unset;
      max-height: unset;
      cursor: zoom-out;
    }
  }

  &__video {
    position: absolute;
    width: 100%;
    height: 100%;

    video {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }

  &--loaded &__image {
    opacity: 1;
  }

  &__ui {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }

  &__icon {
    height: fit-content;
    z-index: 20;
    cursor: pointer;
    font-size: 1.625rem;
    color: $charcoal;

    &--close {
      position: fixed;
      top: 0;
      right: 0;
      padding: 1.625rem;
      font-size: 1.125rem;
      z-index: 25;

      @include respond($break-tablet) {
        top: 1rem;
        right: 1rem;
        padding: 0;
      }
    }

    &--prev,
    &--next {
      position: fixed;
      top: 0;
      bottom: 0;
      margin: auto;
      font-size: 1.625rem;
      font-weight: 900;
    }

    &--prev {
      left: 0;
      padding: 1.625rem;
      @include respond($break-tablet) {
        padding: 1rem;
      }
    }

    &--next {
      right: 0;
      padding: 1.625rem;
      @include respond($break-tablet) {
        padding: 1rem;
      }
    }
  }

  &__dots {
    position: fixed;
    bottom: 0.250rem;
    display: flex;
    left: 50%;
    transform: translateX(-50%);

    @include respond($break-tablet) {
      bottom: 0.625rem;
    }
  }

  &__dot-wrapper {
    padding: 0.750rem;
    margin: 0;

    @include respond($break-tablet) {
      padding: 0;
      margin: 0.375rem;
    }
  }

  &__dot {
    width: 1rem;
    height: 1rem;
    border: solid 1px $charcoal;
    border-radius: 50%;
    cursor: pointer;

    &:hover,
    &--active {
      background-color: $charcoal;
    }

    @include respond($break-tablet) {
      width: 0.875rem;
      height: 0.875rem;
    }
  }

  .loading {
    height: 100%;
  }
}
