@import '../partials/variables';
@import '../partials/mixins';

.values {
  width: 100%;

  &__header {
    @include grid-container;
    @include components-element-max-width;
    @include components-element-side-padding;

    @include respond-below($break-large) {
      background-color: $sandstone;
    }

    h2 {
      grid-column: 1 / span 4;
      padding: 2rem;
      grid-row: 1;
      text-align: center;
      white-space: pre-line;
      color: $charcoal;

      @include respond($break-phone) {
        grid-column: 1 / span 8;
        padding: 3rem 0 2rem;
      }

      @include respond($break-large) {
        padding: 5rem 0 3.125rem;
        grid-column: 3 / span 8;
      }
    }
  }

  &__banner {
    @include cover-asset-container;
    padding-bottom: 100% !important; // override inline styles
    @include respond($break-phone) {
      padding-bottom: 50% !important; // override inline styles
    }
  }

  &__points-wrapper {
    width: 100%;
    background: $sandstone;
  }

  &__points {
    @include components-element-max-width;
    @include custom-breakpoint-styles(
      padding,
      3rem 2rem 3.75rem,
      3rem 3rem 3.75rem,
      3.125rem 4rem 3.75rem,
    );
    display: flex;
    flex-direction: column;
    column-gap: 1.25rem;
    row-gap: 1.5rem;

    @include respond($break-phone) {
      row-gap: 2.5rem;
      flex-direction: column;
    }

    @include respond($break-large) {
      column-gap: 4rem;
      flex-direction: row;
    }
  }

  &__point {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    &-asset {
      position: relative;
      width: 4.375rem;

      @include respond($break-phone) {
        flex-shrink: 0;
      }

      @include respond($break-large) {
        width: 5.625rem;
      }

      img {
        width: 100%;
      }

      .graphcms-image-outer-wrapper .graphcms-image-wrapper {
        > div {
          @include square-aspect-ratio;
        }
      }
    }

    &-copy {
      @include custom-breakpoint-styles(margin-left, 2rem, 2.25rem, 2.5rem);
      width: 65%;
      color: $charcoal;
      @include respond($break-phone) {
        width: unset;
      }

      &--heavy {
        @include heavy;
      }
    }
  }

  &__point.small {
    .values__point {
      &-asset {
        width: 4.21875rem;
      }
      &-copy {
        @include respond($break-large) {
          margin-left: 1.5rem;
        }
      }
    }
  }
}
