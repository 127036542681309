@import '../partials/variables';
@import '../partials/mixins';

.collection-picker {
  position: relative;
  display: grid;
  align-items: center;
  flex-direction: row-reverse;
  grid-template-columns: 1fr 2fr;

  &-wrapper-inner {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 0.25rem 0.5rem;
    align-items: center;
    height: min-content;
    margin-left: 1.3125rem;

    .checkbox {
      margin: 0;
      height: min-content;
    }

    &__label {
      @include h5;
      @include book;
    }

    &__description {
      @include label-small;
      @include book;

      color: $charcoal75;
      line-height: 1.33;
      grid-column: 2 / span 1;
    }
  }

  img {
    max-width: 100%;
    max-height: 100%;
    height: 87px;
    width: 100%;
  }

  input {
    position: absolute;
    width: 0;
    height: 0;
    padding: 0;
  }
}
