@import '../partials/variables';
@import '../partials/mixins';

@keyframes fillup {
  from {
    width: 0;
  }

  to {
    width: 3rem;
  }
}

.slider {
  &--theme-dark {
    --theme-color: #{$heathercharcoal};
  }

  &--theme-light {
    --theme-color: #{$white};
  }

  &--theme-yellow {
    --theme-color: #{$yellow};
  }

  &__pagination {
    position: absolute;
    display: none;
    bottom: 5%;
    width: 100%;
    transform: translateY(50%);
    pointer-events: none;
    z-index: 5;
    text-align: center;

    @include respond($break-phone) {
      padding: 0 4rem;
    }

    &--visible {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }

    &--left-aligned {
      justify-content: flex-start;
    }

    .swiper-pagination-bullet {
      display: inline-block;
      margin: 0 0.125rem;
      border-radius: 0.5rem;
      padding: 0.5rem 0;
      pointer-events: all;
      opacity: 0.5;
      background-color: transparent;
      width: 3rem;
      height: calc($carousel-dot-height - 0.062rem);

      &::after {
        content: "";
        display: block;
        width: 3rem;
        height: $carousel-dot-height;
        background: var(--theme-color, $heathercharcoal);
      }

      @media (hover: hover) {
        &:hover {
          opacity: 1;
        }
      }

      &-active {
        opacity: 1;
      }
    }

    &--unanimated {
      .swiper-pagination-bullet {
        cursor: pointer;
        display: inline-block;
        margin: 0 0.35rem;
        border-radius: 0.5rem;
        padding: 0.5rem 0;
        pointer-events: all;
        opacity: 0.7;
        background-color: transparent;
        height: calc($carousel-dot-height - 0.062rem);

        &::after {
          content: "";
          display: block;
          width: 3rem;
          height: calc($carousel-dot-height - 0.062rem);
          background: var(--theme-color, $heathercharcoal);
        }

        @media (hover: hover) {
          &:hover {
            opacity: 1;
          }
        }

        &-active {
          opacity: 0.7;
          position: relative;
          display: inline-block;
          margin: 0 0.35rem;
          border-radius: 0;
          padding: 0.7rem 0;
          pointer-events: all;
          width: 3rem;
          height: $carousel-dot-height;
          background:
            linear-gradient(
              transparent 49%,
              var(--theme-color, $heathercharcoal),
              var(--theme-color, $heathercharcoal),
              transparent 64%
            );

          &::after {
            content: "";
            opacity: 1 !important;
            display: block;
            height: calc($carousel-dot-height - 0.062rem);
            background-color: $yellow;
          }
        }
      }
    }

    &--animated {
      .swiper-pagination-bullet {
        cursor: pointer;
        display: inline-block;
        margin: 0 0.35rem;
        border-radius: 0.5rem;
        padding: 0.5rem 0;
        pointer-events: all;
        background-color: transparent;
        opacity: 0.35;

        &::after {
          content: "";
          display: block;
          width: 3rem;
          height: calc($carousel-dot-height - 0.062rem);
          background: var(--theme-color, $heathercharcoal);
        }

        @media (hover: hover) {
          &:hover {
            opacity: 1;
          }
        }

        &-active {
          opacity: 1;
          position: relative;
          display: inline-block;
          margin: 0 0.35rem;
          border-radius: 0;
          padding: 0.7rem 0;
          pointer-events: all;
          height: $carousel-dot-height;
          width: 3rem;
          background:
            linear-gradient(
              transparent 49%,
              rgba(255, 255, 255, 35%),
              rgba(255, 255, 255, 35%),
              transparent 64%
            );

          &::after {
            content: "";
            opacity: 1 !important;
            display: block;
            height: calc($carousel-dot-height - 0.062rem);
            animation-name: fillup;
            animation-duration: 5000ms;
            background-color: $yellow;
          }
        }
      }
    }
  }

  /* Workaround for know issue in Swiper slider used in CSS grid
     https://github.com/nolimits4web/swiper/issues/2914#issuecomment-447407226 */
  .swiper-slide {
    z-index: 2;

    &-active > .pdp-image-position {
      position: relative;
      width: calc(100% + 0.125rem);
      left: -0.0625rem;
    }

    &-active {
      z-index: 3;
    }
  }
}

.recommended_items_container {
  display: flex;
  justify-content: flex-start;
  margin-top: -0.5rem;
}

.slider.isRecommendedItems .slider__pagination--visible {
  padding: 0;
  justify-content: left;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  transform: none;
  width: auto;

  .swiper-pagination-bullet {
    width: 0.5rem;
    margin: 0 0.5rem;

    &::after {
      content: "";
      display: block;
      width: 1.25rem;
      height: 0.1875rem;
      background: #D1d0d0;
    }

    &-active {
      &::after {
        background: #383633;
      }
    }
  }
}
